import React from 'react';

const Profile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g stroke="#E15741" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_1879_12829)">
        <path d="M4.719 4.251a3.281 3.281 0 1 0 6.562 0 3.281 3.281 0 0 0-6.562 0M1.906 15.032a6.094 6.094 0 0 1 12.188 0"></path>
      </g>
      <defs>
        <clipPath id="clip0_1879_12829">
          <path fill="#fff" d="M0 .001h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
export default Profile;
