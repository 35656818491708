import { Link } from 'gatsby';
import React, { useState } from 'react';
import DynamicModal from '../../../components/ui/modal/DynamicModal';
import MSText from '../../ui/typography/MSText';
import XSText from '../../ui/typography/XSText';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import CopyClipboard from '../../../images/icons/customSvg/CopyClipboard';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Telegram } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import { MUI_COLORS, REDIRECTION_SOURCE, SESSION_STORAGE_NAME, SOCIAL_SHARE_LINK } from '../../../constants/enum';
import { isBrowser } from '../../../helper/helpers';
import BaseText from '../../ui/typography/BaseText';
import PreviewImage from '../../../images/graphics/invoice.png';
import Snackbar from '../../Snackbar';
import Image from '../../Image';
import SuccessIcon from '../../../images/icons/success-icon.svg';
import { PATH_PAGE } from '../../../routes/paths';
import useIsMobile from '../../../../utils/isMobile';

function ShareDocumentByLink({ openModal, closeDynamicModal, id, documentURLQuery }) {
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState({
    title: '',
    content: ''
  });

  const closeSnackbar = () => {
    setIsSnackBarOpen(false);
  };
  const copyInvoiceLink = () => {
    setIsSnackBarOpen(true);
    setSuccessMessage({
      ...successMessage,
      title: `Success`,
      content: `Link Copied sucessfully!`
    });
    copy(`${process.env.SITE_URL}${PATH_PAGE.publicCompanyDocs}?id=${id}&${documentURLQuery}`);
  };

  const handleShare = (e) => {
    e.preventDefault();

    const title = 'View Your Document';
    const ahref = `${process.env.SITE_URL}${PATH_PAGE.publicCompanyDocs}?id=${id}&${documentURLQuery}`;
    const text = `${title} ${ahref}`;
    const encodedText = encodeURIComponent(text);
    const encodedAhref = encodeURIComponent(ahref);
    const encodedImage = encodeURIComponent(PreviewImage);
    let link;
    switch (e.currentTarget.id) {
      case 'facebook':
        link = `${SOCIAL_SHARE_LINK.FACEBOOK_URL}${ahref}`;
        open(link);
        break;
      case 'telegram':
        link = `${SOCIAL_SHARE_LINK.TELEGRAM_URL}${encodedAhref}&text=${title}`;
        open(link);
        break;
      case 'whatsapp':
        link = `${SOCIAL_SHARE_LINK.WHATSAPP_URL}${encodedText}&source&data&abid=12345&app_absent=true&thumb=${encodedImage}`;
        open(link);
        break;
      default:
        break;
    }
  };
  const open = (socialLink) => {
    isBrowser() && window.open(socialLink, '_blank');
  };
  const isMobile = useIsMobile();

  const IS_REDIRECTION_SOURCE_MOBILE_APP =
    isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP;

  return (
    <div>
      <DynamicModal openDynamicModal={openModal} closeDynamicModal={closeDynamicModal} minWidth="520px">
        <div className="flex flex-col items-center mt-8">
          <MDSubtitleText
            title={`Share your document`}
            fontWeight="text-bold"
            className="whitespace-pre-line line-height-26"
            textAlign="text-center"
          />
          {IS_REDIRECTION_SOURCE_MOBILE_APP ? null : (
            <div className="flex mt-4 mb-2 text-center">
              <div className="cursor-pointer" id="facebook" onClick={handleShare}>
                <FacebookIcon />
                <BaseText textAlign={'center'} className="social-share-title" title="by Facebook" />
              </div>
              <div className="ml-8 cursor-pointer" id="whatsapp" onClick={handleShare}>
                <WhatsAppIcon />
                <BaseText className="social-share-title" textAlign={'center'} title="by Whatsapp" />
              </div>
              <div className="ml-8 cursor-pointer" id="telegram" onClick={handleShare}>
                <Telegram />
                <BaseText textAlign={'center'} className="social-share-title" title="by Telegram" />
              </div>
            </div>
          )}
          <XSText
            title={
              <div>
                or copy a shareable{' '}
                <Link
                  className=" text-coral-500"
                  target="_blank"
                  to={`${process.env.SITE_URL}${PATH_PAGE.publicCompanyDocs}?id=${id}&${documentURLQuery}`}
                >
                  link
                </Link>
              </div>
            }
            className="leading-6 mt-8"
          />
          <div className="flex flex-col sm:flex-row items-center gap-2 mt-2">
            <div className="border-gray-200 rounded-lg border">
              <MSText
                title={`${process.env.SITE_URL}${PATH_PAGE.publicCompanyDocs}?id=${id}&${documentURLQuery}`}
                fontWeight="text-medium"
                className="leading-6 p-4 break-all"
                textColor="text-coral-500"
              />
            </div>
            <div className="cursor-pointer" onClick={copyInvoiceLink}>
              <CopyClipboard />
            </div>
          </div>
        </div>
      </DynamicModal>
      <Snackbar
        open={isSnackBarOpen}
        handleClose={closeSnackbar}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={SuccessIcon} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={successMessage?.title}></XSText>
              <MSText textColor="text-gray-500" title={successMessage?.content}></MSText>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default ShareDocumentByLink;
