import React from 'react';
import BaseText from '../ui/typography/BaseText';
import CashInHand from '../../images/graphics/girl-with-cash.png';
import XSText from '../ui/typography/XSText';
import ArrowRight from '../../images/icons/arrow-right-1.svg';
import BackButton from '../ui/buttons/BackButton';
import { CONSTANT_NUMBER, IS_SSO_ON, PAYMENT_METHOD } from '../../constants/enum';
import StatrysIcon from '../../images/statrys-symbol.png';
import CreditCardIcon from '../../images/icons/credit-card.svg';
import CloseIcon from '../../images/icons/close-icon.svg';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';

function SelectPaymentMode({ setStep, setIsBusinessAccount }) {
  const onBack = () => {
    setStep(CONSTANT_NUMBER.ZERO);
  };

  const handlePaymentMode = (value) => {
    if (value === PAYMENT_METHOD.CREDIT_CARD) {
      setIsBusinessAccount(false);
    } else {
      setStep(CONSTANT_NUMBER.TWO);
    }
  };

  const closeButtonClicked = () => {
    if (IS_SSO_ON) {
      navigate(PATH_PAGE?.companyProfile);
    } else {
      navigate(PATH_PAGE?.companiesList);
    }
  };
  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between md:w-[482px] shadow-lg">
      <div className="flex justify-between">
        <BaseText fontWeight="text-bold" fontSize="text-3xl" title="Select your payment method" />
        <div onClick={() => closeButtonClicked()} className="cursor-pointer">
          <img src={CloseIcon} className="w-4 h-4" alt="close icon" />
        </div>
      </div>
      <div className="flex flex-col justify-between whitespace-pre-line h-[inherit]">
        <div className="flex flex-col items-center justify-between mt-8">
          <img src={CashInHand} className="w-[228px] h-[160px]" alt="Payment Mode" />
        </div>
        <div
          className="flex justify-between p-4 border border-gray-200 rounded-lg mt-6 mb-2 cursor-pointer"
          onClick={() => handlePaymentMode()}
        >
          <div className="flex gap-4 items-center">
            <div className="bg-coral-100 p-1.5 rounded-lg">
              <img src={StatrysIcon} className="w-7 h-7" alt="statrys icon" />
            </div>
            <div className="flex flex-col gap-1">
              <XSText fontWeight="text-bold" title="Statrys Business Account" />
              <XSText title="Debit directly from your Statrys account" />
            </div>
          </div>
          <img src={ArrowRight} alt="Arrow Right" />
        </div>
        <div
          className="flex justify-between p-4 border border-gray-200 rounded-lg mb-6 cursor-pointer"
          onClick={() => handlePaymentMode(PAYMENT_METHOD.CREDIT_CARD)}
        >
          <div className="flex gap-4 items-center">
            <div className="bg-green-200 p-2 rounded-lg">
              <img src={CreditCardIcon} className="w-6 h-6" alt="credit card icon" />
            </div>
            <div className="flex flex-col gap-1">
              <XSText fontWeight="text-bold" title="Credit Card" />
              <XSText title="Pay instantly by credit card" />
            </div>
          </div>
          <img src={ArrowRight} alt="Arrow Right" />
        </div>
      </div>
      <BackButton onClick={onBack} className="md:w-[114px] w-full" />
    </div>
  );
}

export default SelectPaymentMode;
