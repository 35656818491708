import React from 'react';
import MailRoomTable from './MailRoomTable';
import StatrysLoader from '../../ui/loaders/StatrysLoader';

function MailRoom({ documentsDetails, isDocumentsLoading, companyId }) {
  return (
    <div className="mt-8 flex justify-center sm:block px-2 sm:px-0  ">
      {isDocumentsLoading ? (
        <div className="min-h-[50vh] flex justify-center items-center">
          <StatrysLoader />
        </div>
      ) : (
        <MailRoomTable
          documentsDetails={documentsDetails}
          isDocumentsLoading={isDocumentsLoading}
          companyId={companyId}
        />
      )}
    </div>
  );
}

export default MailRoom;
