import React from 'react';
import VerificationConfirmation from '../../components/onfido/VerificationConfirmation';
import InitialWrapper from '../../components/companyIncorporation/wrappers/InitialWrapper';
import Layout from '../../components/layout';
import Page from '../../components/Page';
function VerifyAndConfirm() {
  return (
    <Layout noSideBar={true}>
      <Page title="Company List" className="h-full mt-4">
        <div className="md:px-6 px-4 flex flex-col md:flex-row items-center justify-center h-full mx-auto w-auto">
          <div className="bg-white md:w-[1080px] w-[100%] items-start box-shadow flex rounded-2xl flex-col justify-between gap-10 md:mt-10 mt-6 relative z-10 shadow-lg mb-8 mx-4">
            <InitialWrapper>
              <VerificationConfirmation />
            </InitialWrapper>
          </div>
        </div>
      </Page>
    </Layout>
  );
}

export default VerifyAndConfirm;
