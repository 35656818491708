export const LOGIN_REQUEST_START = 'LOGIN_REQUEST_START';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';

export const RESET_PASSWORD_REQUEST_START = 'RESET_PASSWORD_REQUEST_START';
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS';
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE';

export const UPDATE_SSO_TOKEN_REQUEST_START = 'UPDATE_SSO_TOKEN_REQUEST_START';
export const UPDATE_SSO_TOKEN_REQUEST_SUCCESS = 'UPDATE_SSO_TOKEN_REQUEST_SUCCESS';
export const UPDATE_SSO_TOKEN_REQUEST_FAILURE = 'UPDATE_SSO_TOKEN_REQUEST_FAILURE';
