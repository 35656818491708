import React, { useEffect } from 'react';
import SuccessImage from '../../images/icons/renewPayment/high-five-success-img.svg';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { navigate } from 'gatsby';
import { getUser } from '../authService/auth';
import { getUserCompanies } from '../../helper/helpers';
import { ENDPOINTS } from '../../service/end-points';
import { IS_SSO_ON, SESSION_STORAGE_NAME } from '../../constants/enum';
import { connect } from 'react-redux';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';

function AutoRenewalSuccess({ companyDetails, setIsRenewalSuccessPage, getIncorporationCompanies }) {
  const handleNavigation = () => {
    const companies = JSON?.parse(sessionStorage?.getItem('allCompanies'))?.companies;
    if (companies && companies?.length > 1) {
      !IS_SSO_ON ? navigate(ENDPOINTS?.COMPANY_LIST) : navigate(ENDPOINTS?.COMPANY_PROFILE);
    } else {
      navigate(ENDPOINTS?.COMPANY_PROFILE);
    }
  };

  const user = getUser();
  async function refreshCompanyDetails() {
    await getUserCompanies(
      sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN),
      user?.email || companyDetails?.data?.data?.email
    );
    setIsRenewalSuccessPage(true);
  }
  useEffect(() => {
    if (!IS_SSO_ON) {
      refreshCompanyDetails();
    } else {
      getIncorporationCompanies();
    }
  }, []);
  const companyName = companyDetails?.companyName;
  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col gap-4 items-stretch justify-between renew-payment-form-container  shadow-lg">
      <div className="flex flex-col gap-4 items-center justify-between whitespace-pre-line">
        <img src={SuccessImage} className="w-[256px] h-[166px]" alt="Success Image" />
        <h4 className="text-2xl leading-[100%] tracking-[-1px] text-black mt-10">Authorization Received!</h4>
        <div className="flex flex-col gap-4">
          <BaseText
            fontSize="text-lg leading-[120%] tracking-[-0.26px] text-black whitespace-pre-line"
            title={
              <span>
                All documents related to the renewal of the Business Registration Certificate for{' '}
                <span className="font-bold">{companyName}</span>, including the renewed certificate, will be uploaded to
                your Company Portal as soon as they are available.
                <br />
                <br />
                The standard processing time for issuing a new Business Registration Certificate is 10 working days from
                the date of authorization.
              </span>
            }
          />
        </div>
        <PrimaryButton
          id="success-page-button"
          caption="Back to dashboard"
          fontSize="text-base"
          className="my-2"
          onClick={handleNavigation}
          isBorderRequired={true}
          linkClass="w-full text-center"
          color="text-white"
          bgColor={'bg-green-500'}
        />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getIncorporationCompanies: () => dispatch(CompanyAction.getIncorporationCompanies())
  };
};

export default connect(null, mapDispatchToProps)(AutoRenewalSuccess);
