import * as actionTypes from './actionTypes';
import { getSDKToken } from '../../../../service/id-verification-service';
import { ENDPOINTS } from '../../../../service/end-points';
import axios from 'axios';
import { BASE_API_URL_FOR_ONFIDO } from '../../../../constants/enum';

export const fetchIdVerificationStart = () => ({ type: actionTypes.FETCH_ID_VERIFICATION_START });
export const fetchIdVerificationFail = (error) => ({ type: actionTypes.FETCH_ID_VERIFICATION_FAIL, error: error });
export const fetchIdVerificationSuccess = (fetchVerificationInformation) => ({
  type: actionTypes.FETCH_ID_VERIFICATION_SUCCESS,
  fetchVerificationInformation: fetchVerificationInformation
});
export const fetchVerificationInformation = (requestBody) => {
  return async (dispatch) => {
    try {
      dispatch(fetchIdVerificationStart());
      const fetchSDKTokenResponse = await getSDKToken(requestBody);
      await dispatch(fetchIdVerificationSuccess(fetchSDKTokenResponse.data));
      return fetchSDKTokenResponse;
    } catch (error) {
      dispatch(fetchIdVerificationFail(error.response));
    }
  };
};

export const identityCheckStart = () => ({ type: actionTypes.IDENTITY_CHECK_PROCESS_START });
export const identityCheckFail = (error) => ({ type: actionTypes.IDENTITY_CHECK_PROCESS_FAIL, error: error });
export const identityCheckSuccess = (identityCheckInformation) => ({
  type: actionTypes.IDENTITY_CHECK_PROCESS_SUCCESS,
  identityCheckInformation: identityCheckInformation
});
export const identityCheckProcess = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(identityCheckStart());
      const identityCheckResponse = await axios.post(
        BASE_API_URL_FOR_ONFIDO + ENDPOINTS.IDENTITY_CHECK_PROCESS,
        payload
      );
      await dispatch(identityCheckSuccess(identityCheckResponse.data));
      return identityCheckResponse;
    } catch (error) {
      dispatch(identityCheckFail(error.response));
    }
  };
};
