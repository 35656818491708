import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import { isBrowser, getUserCompanies } from '../../helper/helpers';
import {
  PAGE_TITLE,
  LOGIN_TAB,
  SESSION_STORAGE_NAME,
  IS_SSO_ON,
  SSO_SITE_LOGIN_URL,
  REDIRECTION_SOURCE,
  MOBILE_APP_EVENTS,
  COMPANY_INCORPORATION_STATUS
} from '../../constants/enum';
import { RENEWAL_STATUS } from '../../constants/enum';
import { companyDetails } from '../../helper/helpers';
import { ENDPOINTS } from '../../service/end-points';
export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : {};

const getParamCompanyId = () => {
  const location = isBrowser() && window?.location;
  const params = new URLSearchParams(location?.search);
  return params.get('identifierCode');
};

const getParamStatus = () => {
  const location = isBrowser() && window?.location;
  const params = new URLSearchParams(location?.search);
  return params.get('status');
};

export const setUserInLocalStorage = (user) => {
  typeof window !== 'undefined' && window.localStorage.setItem('user', JSON.stringify(user));
};

const setCompanyIdInSessionStorage = (companyId) => {
  window.sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, companyId);
};

const handleRedirectionSSOON = (selectedCompany) => {
  const paramCompanyId = getParamCompanyId();
  const paramStatus = getParamStatus();

  setCompanyIdInSessionStorage(paramCompanyId);
  sessionStorage.setItem(SESSION_STORAGE_NAME.IS_ANY_COMPANY_CLICKED, true);

  const statusRoutes = {
    [COMPANY_INCORPORATION_STATUS.APPROVED]: ENDPOINTS.COMPANY_PROFILE,
    [COMPANY_INCORPORATION_STATUS.UNDER_REVIEW]: `${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`,
    [COMPANY_INCORPORATION_STATUS.UNFINISHED]: `${PATH_PAGE.companyIncorporation}?continue-form=true`
  };

  if (paramStatus in statusRoutes) {
    return navigate(statusRoutes[paramStatus]);
  }

  if (paramStatus === COMPANY_INCORPORATION_STATUS.EXPIRING_SOON) {
    return navigate(PATH_PAGE.renewPayment, { state: { companyDetails: selectedCompany } });
  }

  console.warn('Unhandled company status:', paramStatus);
  navigate(ENDPOINTS.COMPANY_PROFILE);
};

const handleRedirectionSSOOFF = (companies, checkUserCompanies) => {
  const companiesWithManualRenewalStatusOn =
    companies?.filter((company) => company?.renewalStatus == RENEWAL_STATUS.MANUAL) || [];
  const isCompanyApproved = checkUserCompanies?.data?.data?.companies?.find((company) => company?.isApproved === true);

  if (companiesWithManualRenewalStatusOn?.length >= 1) {
    navigate(PATH_PAGE.renewPayment, {
      state: { companyDetails: companiesWithManualRenewalStatusOn[0], userDetails: checkUserCompanies?.data?.data }
    });
  } else if (checkUserCompanies?.data?.data?.companies.length > 1) {
    navigate(PATH_PAGE?.companiesList, { state: { userDetails: checkUserCompanies?.data?.data } });
  } else if (checkUserCompanies?.data?.data?.companies.length === 1) {
    const isCompanyFinished = checkUserCompanies?.data?.data?.companies?.find(
      (company) => company?.finishedApplication === true
    );
    if (isCompanyApproved) {
      navigate(PATH_PAGE.companyProfile);
    } else if (isCompanyFinished) {
      navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
    } else {
      navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
    }
  } else {
    navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
  }
};

export const setUser = async (user, token) => {
  const paramCompanyId = getParamCompanyId();

  if (Object.keys(user).length !== 0) {
    setUserInLocalStorage(user);
    user?.companyId && setCompanyIdInSessionStorage(user?.companyId);

    try {
      if (IS_SSO_ON) {
        const selectedCompany = await companyDetails(token, paramCompanyId);
        selectedCompany && handleRedirectionSSOON(selectedCompany?.data?.data);
        return;
      }
      const checkUserCompanies = await getUserCompanies(token, user?.email);
      const companies = checkUserCompanies?.data?.data?.companies;
      handleRedirectionSSOOFF(companies, checkUserCompanies);
    } catch (error) {
      alert(error.response?.data?.message);
    }
    isBrowser() && window.localStorage.setItem('pageName', JSON.stringify(PAGE_TITLE.DASHBOARD));
  }
};

const navigateToLogin = () => (IS_SSO_ON ? navigate(SSO_SITE_LOGIN_URL) : navigate(PATH_PAGE.login));

export const handleLogin = (token, userDetails, activeTab) => {
  if (token) {
    sessionStorage.setItem(SESSION_STORAGE_NAME.TOKEN, token);
    setUser(
      {
        id: userDetails?._id,
        isVerified: userDetails?.isVerified,
        email: userDetails?.email,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        isCompanyOwner: userDetails?.isCompanyOwner,
        isIncorporation: activeTab === LOGIN_TAB?.INVOICE ? false : userDetails?.isIncorporation,
        isApproved: userDetails?.isApproved,
        companyId: userDetails?.companyId,
        phoneNumber: userDetails?.phoneNumber
      },
      token
    );
    return true;
  }
  return false;
};

export const isLoggedIn = () => {
  const token = typeof window !== 'undefined' && sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
  return token ? true : false;
};

export const clearStorage = () => {
  sessionStorage.clear();
  isBrowser() && window.localStorage.clear();
  setUser({});
};
export const logOut = () => {
  clearStorage();
  if (sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE === REDIRECTION_SOURCE.MOBILE_APP)) {
    const data = {
      event: MOBILE_APP_EVENTS.USER_LOGOUT,
      data: null
    };
    isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
    return;
  }
  navigateToLogin();
};
