import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import MSText from '../ui/typography/MSText';
import Avatar from '../ui/Avatar';
import { stringTruncate } from '../../helper/helpers';
import { IS_SSO_ON, SESSION_STORAGE_NAME, STRING_MAX_CHAR, SVG_TYPES } from '../../constants/enum';
import { Divider } from '@mui/material';
import '../../styles/headerNavigation.scss';
import Badge from '../companyList/Badge';
import Tick from '../../images/icons/check-box-tick.svg';
import { connect } from 'react-redux';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import XMSText from '../ui/typography/XMSText';
import { getSvgIcon } from '../../helper/svgHelper';
import { getUser } from '../authService/auth';
import { RENEWAL_STATUS } from '../../constants/enum';
import checkBox from '../../images/icons/check-box-tick.svg';
import { setTokenInSessionStorage, setCompleteAuthInSessionStorage } from '../../helper/helpers';
import CompanyManagementIcon from '../../images/icons/header/company-management.svg';
import { isBrowser } from '../../helper/helpers';
import useIsMobile from '../../../utils/isMobile';
import useIsTablet from '../../../utils/useIsTablet';

function NavigationDropDown({
  menuItems,
  needSeparator,
  separtorPositions,
  children,
  companyList,
  isNavigation,
  toSwitchCompany,
  isRightDropDownOpen,
  setIsChildElementHovered,
  setIsLeftDropDownOpen
}) {
  const [onHover, setOnHover] = useState();
  const user = getUser();

  const switchCompany = async (companyDetails) => {
    if (companyDetails?.isApproved) {
      const payload = {
        email: companyDetails?.email,
        companyId: companyDetails?._id
      };
      sessionStorage.setItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED, true);
      const response = await toSwitchCompany(payload);
      if (response?.status === API_CODE.STATUS_200) {
        if (!IS_SSO_ON) {
          const completeAuthInfo = JSON.stringify(response?.data?.data);
          setCompleteAuthInSessionStorage(completeAuthInfo);
          setTokenInSessionStorage(response.data?.data?.token);
        }
        sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, response.data?.data?.companyDetails?._id);
        navigate('/');
      }
    } else if (companyDetails?.finishedApplication === true && companyDetails?.isApproved === false) {
      sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
    } else if (companyDetails?.finishedApplication === false) {
      sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, companyDetails?._id);
      navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
    }
  };

  const handleNewCompanyCreation = () => {
    navigate(`${PATH_PAGE.signUp}/start`);
    sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, '');
  };

  const isAnyOngoingCompany = companyList?.find((company) => company?.finishedApplication === false);

  const handleCompanyExpiring = (companyDetails) => {
    navigate(PATH_PAGE?.renewPayment, {
      state: {
        companyDetails: companyDetails
      }
    });
  };

  const dropdownRef = useRef(null);
  const [overlayHeight, setOverlayHeight] = useState('100vh');

  useEffect(() => {
    if (isRightDropDownOpen && IS_SSO_ON && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      // Subtract everything above the dropdown
      const heightUntilDropdown = dropdownRect.top + dropdownRect.height - 20;

      setOverlayHeight(`calc(100vh - ${heightUntilDropdown}px)`);
    }
  }, [isRightDropDownOpen, IS_SSO_ON]);

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return (
    <>
      <div
        className={`m-0 md:-ml-10 ${IS_SSO_ON ? ' w-screen md:w-[292px]' : 'w-screen sm:w-[292px]'}
        ${isNavigation ? 'dropdown-navigation-container' : 'dropdown-container'}
        `}
      >
        <div
          className="dropdown-content"
          style={
            isRightDropDownOpen && IS_SSO_ON
              ? {
                  display: 'block',
                  marginTop: '28px'
                }
              : {}
          }
          ref={dropdownRef}
        >
          <div className="tooltip-container">
            <div className="tooltip-icon"></div>
          </div>
          <div
            className={`dropdown-content-container max-h-[600px] relative md:static z-10 flex flex-col
                        ${!IS_SSO_ON && 'overflow-scroll'} 
                        ${IS_SSO_ON ? 'md:border md:border-gray-400 overflow-hidden' : ''}  
                        ${isRightDropDownOpen && IS_SSO_ON ? 'mt-2 sm:mt-0' : 'mt-0'}
            `}
            onMouseLeave={() => setOnHover()}
            style={{
              boxShadow: IS_SSO_ON && !isMobile && !isTablet ? '0px 16px 24px 0px rgba(0, 0, 0, 0.12)' : '',
              filter: IS_SSO_ON && !isMobile && !isTablet ? 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12))' : ''
            }}
          >
            <>
              {children}
              {!IS_SSO_ON && (
                <>
                  {companyList && (
                    <>
                      <Divider sx={{ marginTop: '16px' }} />
                      <div className="p-2">
                        {companyList
                          ?.filter(
                            (companyDetails) =>
                              companyDetails?.finishedApplication === true &&
                              companyDetails?.isApproved === true &&
                              companyDetails?.renewalStatus != RENEWAL_STATUS.MANUAL
                          )
                          ?.map((company) => {
                            return (
                              <div
                                className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                                onClick={() => switchCompany(company)}
                              >
                                <div className="rounded-full">
                                  <Avatar
                                    avatarStyling="w-10 h-10"
                                    avatarImage={company?.logo}
                                    avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                  />
                                </div>
                                <div className="flex flex-col w-full gap-1">
                                  <div className="flex flex-row items-start justify-between w-full gap-2">
                                    <MSText
                                      title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                      fontWeight="text-bold"
                                    />
                                    {company?._id == sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID) &&
                                      (sessionStorage.getItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED) ||
                                        companyList.length === 1) && <img src={Tick} width="16" height="16" />}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {companyList
                          ?.filter(
                            (companyDetails) =>
                              companyDetails?.finishedApplication === true && companyDetails?.isApproved !== true
                          )
                          ?.map((company) => {
                            return (
                              <div
                                className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                                onClick={() => switchCompany(company)}
                              >
                                <div className="rounded-full">
                                  <Avatar
                                    avatarStyling="w-10 h-10"
                                    avatarImage={company?.logo}
                                    avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                  />
                                </div>
                                <div className="flex flex-col w-full gap-1">
                                  <div className="flex flex-row items-start justify-between w-full gap-2">
                                    <MSText
                                      title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                      fontWeight="text-bold"
                                    />
                                  </div>
                                  {company?.finishedApplication === true && company?.isApproved === false && (
                                    <Badge color="green-200" title="Under Review" />
                                  )}
                                  {company?.finishedApplication === false && (
                                    <Badge color="salmon-500" title="Ongoing Application" />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        {companyList
                          ?.filter((companyDetails) => companyDetails?.finishedApplication === false)
                          ?.map((company) => {
                            return (
                              <div
                                className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                                onClick={() => switchCompany(company)}
                              >
                                <div className="rounded-full">
                                  <Avatar
                                    avatarStyling="w-10 h-10"
                                    avatarImage={company?.logo}
                                    avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                  />
                                </div>
                                <div className="flex flex-col w-full gap-1">
                                  <div className="flex flex-row items-start justify-between w-full gap-2">
                                    <MSText
                                      title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                      fontWeight="text-bold"
                                    />
                                  </div>
                                  {company?.finishedApplication === false && (
                                    <Badge color="salmon-500" title="Ongoing Application" />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        {companyList
                          ?.filter(
                            (companyDetails) =>
                              companyDetails?.renewalStatus === RENEWAL_STATUS?.MANUAL &&
                              companyDetails?.isApproved === true
                          )
                          ?.map((company) => {
                            return (
                              <>
                                <div
                                  className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-gray-100 rounded-lg"
                                  onClick={() => {
                                    if (!JSON?.parse(sessionStorage?.getItem('closeRenewalModalClicked'))) {
                                      handleCompanyExpiring(company);
                                    } else {
                                      switchCompany(company);
                                    }
                                  }}
                                >
                                  <div className="rounded-full">
                                    <Avatar
                                      avatarStyling="w-10 h-10"
                                      avatarImage={company?.logo}
                                      avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                    />
                                  </div>
                                  <div className="flex flex-col w-full gap-1">
                                    <div className="flex flex-row items-start justify-between w-full gap-2">
                                      <MSText
                                        title={stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                                        fontWeight="text-bold"
                                      />
                                    </div>
                                    <Badge color="yellow-500" title="License Expiring Soon" />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}
                  <div
                    className={`flex flex-row items-center rounded-lg px-4 py-2 cursor-pointer mx-2 ${
                      !onHover && 'hover:bg-gray-100'
                    } `}
                    onClick={() => handleNewCompanyCreation()}
                  >
                    <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                    <MSText
                      title="Register another company"
                      textColor="text-blue-300"
                      className="dropDown-email-text ml-2"
                    />
                  </div>
                  <div className="navigation-dropdown-bottom-line my-2"></div>
                </>
              )}
            </>
            <div className="my-2 px-2">
              {!IS_SSO_ON &&
                menuItems.map((menu, index) => {
                  return (
                    <>
                      {needSeparator && separtorPositions.includes(index) ? (
                        <div className="sm:border-b my-2"></div>
                      ) : null}
                      {menu.onMenuItemClick ? (
                        <div onClick={menu.onMenuItemClick} className="hover:no-underline cursor-pointer ">
                          <div
                            onMouseEnter={() => setOnHover(index)}
                            onMouseLeave={() => setOnHover()}
                            className={`flex flex-row items-center rounded-lg px-2 py-2 mx-2 ${
                              index === onHover ? 'bg-gray-100 ' : ''
                            }`}
                            key={index}
                          >
                            <div>{menu?.icon}</div>
                            <MSText
                              title={menu?.pageName}
                              textColor="text-blue-300"
                              className="dropDown-email-text ml-2"
                            />

                            {menu?.isNewMenuItem && (
                              <div className="bg-coral-500 rounded-lg ml-2">
                                <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <Link to={menu?.to} className="hover:no-underline cursor-pointer ">
                          <div
                            onMouseEnter={() => setOnHover(index)}
                            onMouseLeave={() => setOnHover()}
                            className={`flex flex-row items-center rounded-lg px-4 py-2 mx-2 ${
                              index === onHover ? 'bg-gray-100 ' : ''
                            }`}
                            key={index}
                          >
                            <div>{menu?.icon}</div>
                            <MSText
                              title={menu?.pageName}
                              textColor="text-blue-300"
                              className="dropDown-email-text ml-2"
                            />

                            {menu?.isNewMenuItem && (
                              <div className="bg-coral-500 rounded-lg ml-2">
                                <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                              </div>
                            )}
                          </div>
                        </Link>
                      )}
                    </>
                  );
                })}

              {IS_SSO_ON &&
                menuItems.map((menu, index) => {
                  return (
                    <>
                      <div
                        onClick={menu.onMenuItemClick}
                        className="cursor-pointer hover:bg-coral-300 ml-4 md:ml-0 px-4 flex gap-4 items-center p-4 rounded-[4px]"
                        style={{ pointerEvents: 'auto' }}
                        onMouseEnter={(e) => {
                          setIsChildElementHovered(true);
                        }}
                        onMouseLeave={() => setIsChildElementHovered(false)}
                      >
                        <div>{menu?.icon}</div>

                        <p className="text-gray-300">{menu?.pageName}</p>

                        {menu?.isNewMenuItem && (
                          <div className="bg-coral-500 rounded-lg ml-2">
                            <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                          </div>
                        )}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        <div
          className="md:hidden"
          style={
            IS_SSO_ON && isRightDropDownOpen
              ? {
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100vw',
                  height: overlayHeight,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1
                }
              : {}
          }
        ></div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    toSwitchCompany: (companyPayload) => dispatch(CompanyAction.switchCompany(companyPayload))
  };
};
export default connect(null, mapDispatchToProps)(NavigationDropDown);

NavigationDropDown.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPositions: PropTypes.array,
  children: PropTypes.any
};
