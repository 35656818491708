import React from 'react';
import SucessPage from '../../components/onfido/SucessPage';
import PublicHeaderNavigation from '../../components/Navigation/PublicHeaderNavigation';

function SucessfullyDone() {
  return (
    <div className="max-w-7xl layout-container pt-4 mx-auto">
      <PublicHeaderNavigation hideSignInButton={true} />
      <main className="flex h-[88dvh] justify-center items-center">
        <SucessPage />
      </main>
    </div>
  );
}

export default SucessfullyDone;
