import React, { useCallback, useEffect, useState } from 'react';
import '../../styles/pages/login.scss';
import LoginImgBanner from './LoginImgBanner';
import LoginForm from './LoginForm';
import BaseText from '../ui/typography/BaseText';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import XSText from '../ui/typography/XSText';
import {
  API_ERROR_CODES,
  LOGIN_TAB,
  MOBILE_APP_EVENTS,
  SESSION_STORAGE_NAME,
  SSO_SITE_LOGIN_URL,
  STATUS
} from '../../constants/enum';
import Seo from '../seo';
import Logo from '../ui/Logo';
import * as LoginActions from './reduxStore/action';
import * as ProfileActions from '../profile/reduxStore/action';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { REDIRECTION_SOURCE } from '../../constants/enum';
import { handleLogin, isLoggedIn, logOut } from '../authService/auth';
import { IS_SSO_ON } from '../../constants/enum';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { setTokenInSessionStorage, setCompleteAuthInSessionStorage } from '../../helper/helpers';

function LoginPage({ updateSSOToken, getUserProfileByCode }) {
  const location = useLocation();
  const [tokenUpdated, setTokenUpdated] = useState(false);

  const params = new URLSearchParams(location?.search);
  const tab = params.get('tab');
  const defaultTabSelector = () => {
    if (tab === 'company-management') return LOGIN_TAB?.COMPANY_MANAGEMENT;
    else if (tab === 'invoicing-software') {
      return LOGIN_TAB?.INVOICE;
    } else {
      return LOGIN_TAB?.COMPANY_MANAGEMENT;
    }
  };
  const [activeLoginForm, setActiveLoginForm] = useState(defaultTabSelector());

  const handleActiveTab = (activeTab) => {
    setActiveLoginForm(activeTab);
    if (activeTab === LOGIN_TAB?.INVOICE) {
      navigate(`${process.env.INVOICE_APP_URL}/login`);
    }
    if (activeTab === LOGIN_TAB?.COMPANY_MANAGEMENT) {
      navigate('?tab=company-management');
    }
  };
  /*
    ------------------------- UPDATE SSOToken -------------------------
    This section handles the extraction of the token and login process 
    for requests originating from the following sources:
    - Marketing
    - Invoice
    - Payment app
    - Mobile app
    - Business account
*/

  const paramToken = params.get('token');
  const paramCompanyId = params.get('identifierCode');
  const source = params.get('source');
  const status = params.get('status');

  if (status) {
    sessionStorage.setItem(SESSION_STORAGE_NAME.REDIRECTED_COMPANY_STATUS, status);
  }

  if (source === REDIRECTION_SOURCE.MOBILE_APP) {
    sessionStorage.setItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE, source);
  }

  const updateTokenInSession = async (paramCompanyId, paramToken) => {
    const response = await updateSSOToken({ companyId: paramCompanyId }, paramToken);
    if (!response?.data?.data) {
      navigate(SSO_SITE_LOGIN_URL);
      return null;
    }
    const updatedToken = response?.data?.data?.token;
    const userCode = response?.data?.data?.userCode;
    const completeAuthInfo = response?.data?.data;
    setTokenInSessionStorage(updatedToken);
    setCompleteAuthInSessionStorage(JSON.stringify(completeAuthInfo));

    /* Update token for Mobile Application */
    if (sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) == REDIRECTION_SOURCE.MOBILE_APP) {
      const mobileTokenUpdateData = {
        event: MOBILE_APP_EVENTS.TOKEN_UPDATE,
        data: completeAuthInfo
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(mobileTokenUpdateData));
    }
    sessionStorage.setItem(SESSION_STORAGE_NAME.USER_CODE, userCode);
    return updatedToken;
  };

  const fetchUserProfile = async () => {
    const userProfileResponse = await getUserProfileByCode();
    return userProfileResponse?.data?.data;
  };

  const refreshSSOTokenAndLogin = useCallback(async () => {
    try {
      // Step 1: Update token
      const updatedToken = await updateTokenInSession(paramCompanyId, paramToken);

      // Step 2: Fetch user profile
      const userDetails = await fetchUserProfile();

      // Step 3: Handle login
      handleLogin(updatedToken, userDetails);
    } catch (error) {
      if (error?.response?.status === API_ERROR_CODES.STATUS_401) {
        logOut();
      }
      console.error('Error updating SSO token:', error);
    }
  }, [paramCompanyId, paramToken]);

  useEffect(() => {
    if (paramToken && paramCompanyId && IS_SSO_ON) {
      refreshSSOTokenAndLogin();
    }
  }, []);

  useEffect(() => {
    if (IS_SSO_ON && (!paramToken || !paramCompanyId) && !isLoggedIn()) {
      logOut();
    }
  }, []);
  return (
    <>
      {IS_SSO_ON && paramCompanyId && paramToken && (
        <div className="w-full h-screen flex justify-center items-center">
          <StatrysLoader />
        </div>
      )}

      {!IS_SSO_ON && (
        <div className="login-layout">
          <Seo
            title={
              activeLoginForm === LOGIN_TAB?.INVOICE
                ? 'Login - Invoicing | Statrys'
                : 'Login - Company Management | Statrys'
            }
            description={
              activeLoginForm === LOGIN_TAB?.INVOICE
                ? 'Log into your Statrys Invoicing Account.'
                : 'Log into your Company Management Portal.'
            }
          />
          <LoginImgBanner activeLoginForm={activeLoginForm}>
            <div className="bg-white md:p-10 py-10 px-4 rounded-lg md:mt-0">
              <div className="mb-10">
                <Logo />
              </div>
              <BaseText className="mb-4" title="Choose the service you want to use" />
              <div className="flex mb-4 cursor-pointer">
                <a
                  href={`${process.env.MARKETING_SITE_URL}/login`}
                  className={`w-full hover:no-underline border flex justify-center items-center gap-1 ${
                    activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT
                      ? 'border-coral-500 bg-salmon-500'
                      : 'border-gray-200'
                  } rounded-l-lg px-3 py-2`}
                >
                  <XSText
                    textAlign="text-center"
                    textColor={activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT ? 'text-coral-500' : 'text-gray-500'}
                    fontWeight={activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT ? 'text-bold' : 'text-medium'}
                    title={'Business \nAccount'}
                    className="mx-auto whitespace-pre-line"
                  />
                </a>
                <a
                  href={`${process.env.INVOICE_APP_URL}/login`}
                  className={`w-full border hover:no-underline ${
                    activeLoginForm === LOGIN_TAB?.INVOICE ? 'border-coral-500 bg-salmon-500' : 'border-gray-200'
                  } px-3 py-2 flex items-center justify-center"`}
                  onClick={() => handleActiveTab(LOGIN_TAB?.INVOICE)}
                >
                  <XSText
                    textAlign="text-center"
                    textColor={activeLoginForm === LOGIN_TAB?.INVOICE ? 'text-coral-500' : 'text-gray-500'}
                    fontWeight={activeLoginForm === LOGIN_TAB?.INVOICE ? 'text-bold' : 'text-medium'}
                    title={`Invoicing\nSoftware`}
                    className="mx-auto whitespace-pre-line"
                  />
                </a>
                <div
                  className={`w-full border hover:no-underline ${
                    activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT
                      ? 'border-coral-500 bg-salmon-500'
                      : 'border-gray-200'
                  } rounded-r-lg px-3 py-2 flex items-center justify-center"`}
                  onClick={() => handleActiveTab(LOGIN_TAB?.COMPANY_MANAGEMENT)}
                >
                  <XSText
                    textAlign="text-center"
                    textColor={activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'text-coral-500' : 'text-gray-500'}
                    fontWeight={activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'text-bold' : 'text-medium'}
                    title={`Company\nManagement`}
                    className="mx-auto whitespace-pre-line"
                  />
                </div>
              </div>
              <LoginForm activeLoginForm={activeLoginForm} />
            </div>
          </LoginImgBanner>
        </div>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSSOToken: (updateTokenPayload, token) => dispatch(LoginActions.updateSSOToken(updateTokenPayload, token)),
    getUserProfileByCode: (token) => dispatch(ProfileActions.getUserProfileByCode(token))
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);

LoginPage.propTypes = {
  updateSSOToken: PropTypes.func
};
