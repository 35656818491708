exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-documents-js": () => import("./../../../src/pages/company/documents.js" /* webpackChunkName: "component---src-pages-company-documents-js" */),
  "component---src-pages-company-incorporation-success-js": () => import("./../../../src/pages/company-incorporation-success.js" /* webpackChunkName: "component---src-pages-company-incorporation-success-js" */),
  "component---src-pages-company-list-js": () => import("./../../../src/pages/company-list.js" /* webpackChunkName: "component---src-pages-company-list-js" */),
  "component---src-pages-company-mailroom-js": () => import("./../../../src/pages/company/mailroom.js" /* webpackChunkName: "component---src-pages-company-mailroom-js" */),
  "component---src-pages-company-organization-js": () => import("./../../../src/pages/company/organization.js" /* webpackChunkName: "component---src-pages-company-organization-js" */),
  "component---src-pages-company-profile-js": () => import("./../../../src/pages/company/profile.js" /* webpackChunkName: "component---src-pages-company-profile-js" */),
  "component---src-pages-company-set-up-js": () => import("./../../../src/pages/company-set-up.js" /* webpackChunkName: "component---src-pages-company-set-up-js" */),
  "component---src-pages-company-setting-profile-js": () => import("./../../../src/pages/company/setting-profile.js" /* webpackChunkName: "component---src-pages-company-setting-profile-js" */),
  "component---src-pages-company-signup-js": () => import("./../../../src/pages/company-signup.js" /* webpackChunkName: "component---src-pages-company-signup-js" */),
  "component---src-pages-confirm-email-account-js": () => import("./../../../src/pages/confirm-email-account.js" /* webpackChunkName: "component---src-pages-confirm-email-account-js" */),
  "component---src-pages-eidv-sucessfully-done-js": () => import("./../../../src/pages/eidv/sucessfully-done.js" /* webpackChunkName: "component---src-pages-eidv-sucessfully-done-js" */),
  "component---src-pages-eidv-verify-and-confirm-js": () => import("./../../../src/pages/eidv/verify-and-confirm.js" /* webpackChunkName: "component---src-pages-eidv-verify-and-confirm-js" */),
  "component---src-pages-hk-incorporation-js": () => import("./../../../src/pages/hk-incorporation.js" /* webpackChunkName: "component---src-pages-hk-incorporation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-onfido-verification-process-js": () => import("./../../../src/pages/onfido-verification-process.js" /* webpackChunkName: "component---src-pages-onfido-verification-process-js" */),
  "component---src-pages-public-company-docs-js": () => import("./../../../src/pages/public-company-docs.js" /* webpackChunkName: "component---src-pages-public-company-docs-js" */),
  "component---src-pages-renew-payment-js": () => import("./../../../src/pages/renew-payment.js" /* webpackChunkName: "component---src-pages-renew-payment-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-signup-failure-js": () => import("./../../../src/pages/signup/failure.js" /* webpackChunkName: "component---src-pages-signup-failure-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-signup-start-js": () => import("./../../../src/pages/signup/start.js" /* webpackChunkName: "component---src-pages-signup-start-js" */),
  "component---src-pages-too-many-attempts-js": () => import("./../../../src/pages/too-many-attempts.js" /* webpackChunkName: "component---src-pages-too-many-attempts-js" */),
  "component---src-pages-under-maintenance-js": () => import("./../../../src/pages/under-maintenance.js" /* webpackChunkName: "component---src-pages-under-maintenance-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-verify-email-js": () => import("./../../../src/pages/verify-email.js" /* webpackChunkName: "component---src-pages-verify-email-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

