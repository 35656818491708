import React from 'react';
import { navigate } from 'gatsby';
import ActiveBuildings from '../../images/icons/sideBar/buildings-green.svg';
import ActiveHierarchy from '../../images/icons/sideBar/hierarchy-green.svg';
import ActiveMailbox from '../../images/icons/sideBar/mailbox-green.svg';
import ActiveBookLibrary from '../../images/icons/sideBar/book-library-green.svg';
import Buildings from '../../images/icons/sideBar/buildings.svg';
import Hierarchy from '../../images/icons/sideBar/hierarchy.svg';
import Mailbox from '../../images/icons/sideBar/mailbox.svg';
import BookLibrary from '../../images/icons/sideBar/book-library.svg';
import { PATH_PAGE } from '../../routes/paths';

const companyNavigation = [
  {
    id: 1,
    pageName: 'Profile',
    icon: Buildings,
    activeIcon: ActiveBuildings,
    to: PATH_PAGE.companyProfile
  },
  {
    id: 2,
    pageName: 'Organization',
    icon: Hierarchy,
    activeIcon: ActiveHierarchy,
    to: PATH_PAGE.companyOrganization
  },
  {
    id: 3,
    pageName: 'Inbox',
    icon: Mailbox,
    activeIcon: ActiveMailbox,
    to: PATH_PAGE.companyMailRoom
  },
  {
    id: 4,
    pageName: 'Documents',
    icon: BookLibrary,
    activeIcon: ActiveBookLibrary,
    to: PATH_PAGE.companyDocuments
  }
];
export default function BottomNavigation() {
  const currentPath = window?.location?.pathname;

  // Check if any navigation item matches the current path
  const isActivePage = companyNavigation.some((item) => item.to === currentPath);

  return (
    <div className="fixed rounded-t-[32px] bottom-0 left-0 right-0 flex justify-around items-center bg-white overflow-hidden py-6 mx-2 z-50">
      {companyNavigation.map((item) => {
        const isActive = isActivePage ? currentPath === item.to : item.to === PATH_PAGE.companyProfile;

        return (
          <div
            key={item.id}
            className="flex flex-col items-center cursor-pointer text-gray-500"
            onClick={() => navigate(item.to)}
          >
            <img src={isActive ? item.activeIcon : item.icon} alt={item.pageName} className="w-6 h-6 mb-1" />
            <span className={`text-xs ${isActive ? 'text-bold' : ''} text-gray-450`}>{item.pageName}</span>
          </div>
        );
      })}
    </div>
  );
}
