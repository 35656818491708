import * as React from 'react';
import { navigate } from 'gatsby';
import { getUser, isLoggedIn, logOut } from '../authService/auth';
import { INVOICE_DASHBOARD, COMPANY, PATH_PAGE } from '../../routes/paths';
import { IS_SSO_ON, SSO_SITE_LOGIN_URL } from '../../constants/enum';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== PATH_PAGE.login) {
    logOut();
    return null;
  } else if ((location.pathname === COMPANY || location.pathname === `${COMPANY}/`) && getUser()?.isIncorporation) {
    navigate(INVOICE_DASHBOARD);
    return null;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
