import React, { useEffect, useState } from 'react';
import '../../styles/pages/identity-verification-confirmation.scss.scss';
import GreenStudyLightIcon from '../../images/icons/bulb.svg';
import IdentifyVerification from '../../images/icons/confirm-identify-verification/confirm-identify-verification.svg';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { COMPANY_REGISTRATION_COUNTRY, JURISDICTION_AUTHORITY, SESSION_STORAGE_NAME } from '../../constants/enum';
import { getQueryParameter } from '../../helper/helpers';

function VerificationConfirmation({ getCompanyDetails }) {
  const location = useLocation();
  const [companyData, setCompanyData] = useState('');

  const redirectingUrl = getQueryParameter(
    {
      ...location,
      search: location?.search ? location?.search : ''
    },
    'url'
  );
  const handleVerificationConfirmationStep = () => {
    window.open(decodeURIComponent(redirectingUrl), '_self');
  };
  const getCompanyDetailsApiCall = async () => {
    const response = await getCompanyDetails(sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID));
    setCompanyData(response?.data?.data);
  };

  const jurisdictionMap = {
    [COMPANY_REGISTRATION_COUNTRY.HONG_KONG]: JURISDICTION_AUTHORITY.HONGKONG,
    [COMPANY_REGISTRATION_COUNTRY.SINGAPORE]: JURISDICTION_AUTHORITY.SINGAPORE
  };

  useEffect(() => {
    getCompanyDetailsApiCall();
  }, []);

  return (
    <div className="main-page-container">
      <div className="page-detail-container">
        <div className="banner-container">
          <div className="left-section md:order-0 order-1">
            <div>
              <div className="heading">Identity Verification</div>
              <div className="sub-description mt-4">
                {`Verifying your identity is required by applicable laws in ${
                  companyData?.registrationCountry
                } before we can submit
                the application to the ${jurisdictionMap[companyData?.registrationCountry] || null}.`}
              </div>
            </div>
            <div>
              <div className="bullet-container big-bullet">
                <div className="bullet-description">
                  You will be redirected to Onfido, our partner, to carry out the verification.
                </div>
                <div className="bullet-description">
                  You will need to take a photo of your original ID document and a selfie.
                </div>
              </div>

              <div className="tip-container mt-4">
                <img src={GreenStudyLightIcon} alt="" className="w-6 h-6" />
                <div className="tip-detail">
                  <div className="tip-heading">To perform the verification, you will need:</div>
                  <div className="bullet-container">
                    <div className="bullet-description">A valid passport</div>
                    <div className="bullet-description">A phone with a camera</div>
                    <div className="bullet-description">A good internet connection</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action-button flex flex-col md:flex-row">
              <PrimaryButton
                className="confirm-button md:w-auto w-full"
                onClick={handleVerificationConfirmationStep}
                linkClass="md:w-auto w-full"
                caption="Continue"
                fontSize="text-base"
              />

              <div className="button-tips whitespace-pre-line">
                {`By clicking here, you agree to the transfer \nof your data to our partner Onfido`}
              </div>
            </div>
          </div>
          <div className="right-section md:order-1 order-0">
            <div>
              <img src={IdentifyVerification} alt="Confirm Identify Verification Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(null, mapDispatchToProps)(VerificationConfirmation);

VerificationConfirmation.propTypes = {
  nextStep: PropTypes.number,
  getCompanyDetails: PropTypes.func
};
