export const GET_COMPANY_INCORPORATION_REQUEST_START = 'GET_COMPANY_INCORPORATION_REQUEST_START';
export const GET_COMPANY_INCORPORATION_REQUEST_FAIL = 'GET_COMPANY_INCORPORATION_REQUEST_FAIL';
export const GET_COMPANY_INCORPORATION_REQUEST_SUCCESS = 'GET_COMPANY_INCORPORATION_REQUEST_SUCCESS';

export const GET_INVOICE_REQUEST_START = 'GET_INVOICE_REQUEST_START';
export const GET_INVOICE_REQUEST_FAIL = 'GET_INVOICE_REQUEST_FAIL';
export const GET_INVOICE_REQUEST_SUCCESS = 'GET_INVOICE_REQUEST_SUCCESS';

export const GET_BUSINESS_ACCOUNT_REQUEST_START = 'GET_BUSINESS_ACCOUNT_REQUEST_START';
export const GET_BUSINESS_ACCOUNT_REQUEST_FAIL = 'GET_BUSINESS_ACCOUNT_REQUEST_FAIL';
export const GET_BUSINESS_ACCOUNT_REQUEST_SUCCESS = 'GET_BUSINESS_ACCOUNT_REQUEST_SUCCESS';

export const GET_INCORPORATION_COMPANIES_REQUEST_START = 'GET_INCORPORATION_COMPANIES_REQUEST_START';
export const GET_INCORPORATION_COMPANIES_REQUEST_FAIL = 'GET_INCORPORATION_COMPANIES_REQUEST_FAIL';
export const GET_INCORPORATION_COMPANIES_REQUEST_SUCCESS = 'GET_INCORPORATION_COMPANIES_REQUEST_SUCCESS';

export const COMPANY_INCORPORATION_REQUEST_START = 'COMPANY_INCORPORATION_REQUEST_START';
export const COMPANY_INCORPORATION_REQUEST_FAIL = 'COMPANY_INCORPORATION_REQUEST_FAIL';
export const COMPANY_INCORPORATION_REQUEST_SUCCESS = 'COMPANY_INCORPORATION_REQUEST_SUCCESS';

export const UPLOAD_PASSPORT_REQUEST_START = 'UPLOAD_PASSPORT_REQUEST_START';
export const UPLOAD_PASSPORT_REQUEST_FAIL = 'UPLOAD_PASSPORT_REQUEST_FAIL';
export const UPLOAD_PASSPORT_REQUEST_SUCCESS = 'UPLOAD_PASSPORT_REQUEST_SUCCESS';

export const SWITCH_COMPANY_REQUEST_START = 'SWITCH_COMPANY_REQUEST_START';
export const SWITCH_COMPANY_REQUEST_SUCCESS = 'SWITCH_COMPANY_REQUEST_SUCCESS';
export const SWITCH_COMPANY_REQUEST_FAILURE = 'SWITCH_COMPANY_REQUEST_FAILURE';

export const DELETE_STAKEHOLDER_REQUEST_START = 'DELETE_STAKEHOLDER_REQUEST_START';
export const DELETE_STAKEHOLDER_REQUEST_SUCCESS = 'DELETE_STAKEHOLDER_REQUEST_SUCCESS';
export const DELETE_STAKEHOLDER_REQUEST_FAILURE = 'DELETE_STAKEHOLDER_REQUEST_FAILURE';

export const UPDATE_STAKEHOLDER_REQUEST_START = 'UPDATE_STAKEHOLDER_REQUEST_START';
export const UPDATE_STAKEHOLDER_REQUEST_SUCCESS = 'UPDATE_STAKEHOLDER_REQUEST_SUCCESS';
export const UPDATE_STAKEHOLDER_REQUEST_FAILURE = 'UPDATE_STAKEHOLDER_REQUEST_FAILURE';

export const APPLICATION_ACCESS_REQUEST_START = 'APPLICATION_ACCESS_REQUEST_START';
export const APPLICATION_ACCESS_REQUEST_FAIL = 'APPLICATION_ACCESS_REQUEST_FAIL';
export const APPLICATION_ACCESS_REQUEST_SUCCESS = 'APPLICATION_ACCESS_REQUEST_SUCCESS';
