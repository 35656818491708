import React from 'react';
import IdVerification from '../components/onfido/idVerification/IdVerification';

function OnfidoVerificationProcess() {
  return (
    <div>
      <IdVerification />
    </div>
  );
}

export default OnfidoVerificationProcess;
