import * as actionTypes from './actionTypes';
import Api from '../../../store/MarketingInterceptor';
import { navigate } from 'gatsby';
import { API_CODE, APPLICATION_COMPLETION_PERCENTAGE, SESSION_STORAGE_NAME } from '../../../constants/enum';
import { ENDPOINTS, ONBOARDING_API } from '../../../service/services';

const getOnboardingManagementBaseURL = (prospectCode = null, customerID = null) => {
  return `${ONBOARDING_API}/${customerID || sessionStorage.getItem(SESSION_STORAGE_NAME.CUSTOMER_ID)}/prospects/${
    prospectCode || sessionStorage.getItem(sessionStorage.getItem(SESSION_STORAGE_NAME.PROSPECT_CODE))
  }`;
};

const getOnfidoIntegrationFlagsBaseUrl = (userCode = null) => {
  return `reference-data-management/v1/onfido-integration-flag?user-code=${
    userCode || sessionStorage.getItem(SESSION_STORAGE_NAME.CUSTOMER_ID)
  }`;
};

export const companyData = (companyData) => ({
  type: actionTypes.COMPANY_DATA_REQUEST_SUCCESS,
  companyData: companyData
});

export const selectedIndustry = (selectedIndustry) => ({
  type: actionTypes.BUSINESS_DATA_SELECTED_INDUSTRY_SUCCESS,
  selectedIndustry: selectedIndustry
});

export const resetSelectedIndustry = () => ({ type: actionTypes.BUSINESS_DATA_RESET_SELECTED_INDUSTRY_SUCCESS });

export const companyInformationRequestStart = () => ({ type: actionTypes.COMPANY_INFORMATION_REQUEST_START });
export const companyInformationRequestFail = (error) => ({
  type: actionTypes.COMPANY_INFORMATION_REQUEST_FAIL,
  error: error
});
export const companyInformationRequestSuccess = (companyInformation) => ({
  type: actionTypes.COMPANY_INFORMATION_REQUEST_SUCCESS,
  companyInformation: companyInformation
});
export const companyInformation = (prospectCode, customerID, companyInformationPayload) => {
  return async (dispatch) => {
    try {
      dispatch(companyInformationRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let companyInformationResponse = await Api.post(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.COMPANY_INFO}`,
        companyInformationPayload
      );
      if (companyInformationResponse.status === API_CODE.STATUS_204) {
        const applicationCompletion = await sessionStorage.getItem(SESSION_STORAGE_NAME.APPLICATION_COMPLETION);
        if (!applicationCompletion || applicationCompletion < APPLICATION_COMPLETION_PERCENTAGE.COMPLETION_40) {
          await sessionStorage.setItem(
            SESSION_STORAGE_NAME.APPLICATION_COMPLETION,
            APPLICATION_COMPLETION_PERCENTAGE.COMPLETION_20
          );
        }
        await dispatch(companyInformationRequestSuccess(companyInformationResponse.data));
        return companyInformationResponse;
      }
    } catch (error) {
      dispatch(companyInformationRequestFail(error.response));
    }
  };
};

export const mainStepsStatus = (mainStepsStatus) => ({
  type: actionTypes.STEPS_STATUS_REQUEST_SUCCESS,
  mainStepsStatus: mainStepsStatus
});

export const industriesListRequestStart = () => ({ type: actionTypes.INDUSTRIES_LIST_REQUEST_START });
export const industriesListRequestFail = (error) => ({ type: actionTypes.INDUSTRIES_LIST_REQUEST_FAIL, error: error });
export const industriesListRequestSuccess = (industriesList) => ({
  type: actionTypes.INDUSTRIES_LIST_REQUEST_SUCCESS,
  industriesList: industriesList
});
export const industriesList = () => {
  return async (dispatch) => {
    try {
      dispatch(industriesListRequestStart());
      let industriesListResponse = await Api.get(`${ENDPOINTS.INDUSTRIES}`);
      if (industriesListResponse) {
        await dispatch(industriesListRequestSuccess(industriesListResponse));
        return industriesListResponse;
      }
    } catch (error) {
      dispatch(industriesListRequestFail(error.response));
    }
  };
};

export const businessData = (businessData) => ({
  type: actionTypes.BUSINESS_DATA_REQUEST_SUCCESS,
  businessData: businessData
});

export const countriesListRequestStart = () => ({ type: actionTypes.COUNTRIES_LIST_REQUEST_START });
export const countriesListRequestFail = (error) => ({ type: actionTypes.COUNTRIES_LIST_REQUEST_FAIL, error: error });
export const countriesListRequestSuccess = (countriesList) => ({
  type: actionTypes.COUNTRIES_LIST_REQUEST_SUCCESS,
  countriesList: countriesList
});
export const countriesList = () => {
  return async (dispatch) => {
    try {
      dispatch(countriesListRequestStart());
      let countriesListResponse = await Api.get(`${ENDPOINTS.COUNTRIES}`);
      if (countriesListResponse.data) {
        await dispatch(countriesListRequestSuccess(countriesListResponse.data));
        return countriesListResponse.data;
      }
    } catch (error) {
      dispatch(countriesListRequestFail(error.response));
    }
  };
};

export const nationalitiesListRequestStart = () => ({ type: actionTypes.NATIONALITIES_LIST_REQUEST_START });
export const nationalitiesListRequestFail = (error) => ({
  type: actionTypes.NATIONALITIES_LIST_REQUEST_FAIL,
  error: error
});
export const nationalitiesListRequestSuccess = (nationalitiesList) => ({
  type: actionTypes.NATIONALITIES_LIST_REQUEST_SUCCESS,
  nationalitiesList: nationalitiesList
});
export const nationalitiesList = () => {
  return async (dispatch) => {
    try {
      dispatch(nationalitiesListRequestStart());
      let nationalitiesListResponse = await Api.get(`${ENDPOINTS.NATIONALITIES}`);
      if (nationalitiesListResponse.data) {
        await dispatch(nationalitiesListRequestSuccess(nationalitiesListResponse.data));
        return nationalitiesListResponse.data;
      }
    } catch (error) {
      dispatch(nationalitiesListRequestFail(error.response));
    }
  };
};

export const businessInformationRequestStart = () => ({ type: actionTypes.BUSINESS_INFORMATION_REQUEST_START });
export const businessInformationRequestFail = (error) => ({
  type: actionTypes.BUSINESS_INFORMATION_REQUEST_FAIL,
  error: error
});
export const businessInformationRequestSuccess = (businessInformation) => ({
  type: actionTypes.BUSINESS_INFORMATION_REQUEST_SUCCESS,
  businessInformation: businessInformation
});
export const businessInformation = (prospectCode, customerID, businessInformationPayload) => {
  return async (dispatch) => {
    try {
      dispatch(businessInformationRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let businessInformationResponse = await Api.post(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.BUSINESS_INFO}`,
        businessInformationPayload
      );
      if (businessInformationResponse.status === API_CODE.STATUS_201) {
        const applicationCompletion = await sessionStorage.getItem(SESSION_STORAGE_NAME.APPLICATION_COMPLETION);
        if (!applicationCompletion || applicationCompletion < APPLICATION_COMPLETION_PERCENTAGE.COMPLETION_60) {
          await sessionStorage.setItem(
            SESSION_STORAGE_NAME.APPLICATION_COMPLETION,
            APPLICATION_COMPLETION_PERCENTAGE.COMPLETION_40
          );
        }
        await dispatch(businessInformationRequestSuccess(businessInformationResponse));
        navigate('/signup/account-setup');
        return businessInformationResponse;
      }
    } catch (error) {
      dispatch(businessInformationRequestFail(error.response));
    }
  };
};

export const getUserInformationRequestStart = () => ({ type: actionTypes.USER_INFORMATION_REQUEST_START });
export const getUserInformationRequestFail = (error) => ({
  type: actionTypes.USER_INFORMATION_REQUEST_FAIL,
  error: error
});
export const getUserInformationRequestSuccess = (userInformation) => ({
  type: actionTypes.USER_INFORMATION_REQUEST_SUCCESS,
  userInformation: userInformation
});
export const getUserInformation = () => {
  return async (dispatch) => {
    try {
      dispatch(getUserInformationRequestStart());
      let getUserInformationResponse = await Api.get(
        `${ENDPOINTS.USERS}/${sessionStorage.getItem(SESSION_STORAGE_NAME.CUSTOMER_ID)}`
      );
      if (
        getUserInformationResponse.status === API_CODE.STATUS_200 ||
        getUserInformationResponse.status === API_CODE.STATUS_201
      ) {
        await dispatch(getUserInformationRequestSuccess(getUserInformationResponse));
        return getUserInformationResponse.data;
      }
    } catch (error) {
      dispatch(getUserInformationRequestFail(error.response));
    }
  };
};
export const createIndividualStakeHolderRequestStart = () => ({
  type: actionTypes.CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START
});
export const createIndividualStakeHolderRequestFail = (error) => ({
  type: actionTypes.CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL,
  error: error
});
export const createIndividualStakeHolderRequestSuccess = (createIndividualStakeHolder) => ({
  type: actionTypes.CREATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS,
  createIndividualStakeHolder: createIndividualStakeHolder
});
export const createIndividualStakeHolder = (prospectCode, customerID, createIndividualStakeHolderPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createIndividualStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let createIndividualStakeHolderResponse = await Api.post(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_INDIVIDUALS}`,
        createIndividualStakeHolderPayload
      );
      if (
        createIndividualStakeHolderResponse.status === API_CODE.STATUS_200 ||
        createIndividualStakeHolderResponse.status === API_CODE.STATUS_201 ||
        createIndividualStakeHolderResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(createIndividualStakeHolderRequestSuccess(createIndividualStakeHolderResponse));
        return createIndividualStakeHolderResponse;
      }
    } catch (error) {
      dispatch(createIndividualStakeHolderRequestFail(error.response));
    }
  };
};

export const getAllIndividualStakeHolderRequestStart = () => ({
  type: actionTypes.GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_START
});
export const getAllIndividualStakeHolderRequestFail = (error) => ({
  type: actionTypes.GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL,
  error: error
});
export const getAllIndividualStakeHolderRequestSuccess = (getAllIndividualStakeHolder) => ({
  type: actionTypes.GET_ALL_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS,
  getAllIndividualStakeHolder: getAllIndividualStakeHolder
});
export const getAllIndividualStakeHolder = (prospectCode, customerID) => {
  return async (dispatch) => {
    try {
      dispatch(getAllIndividualStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let getAllIndividualStakeHolderResponse = await Api.get(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_INDIVIDUALS}`
      );
      if (
        getAllIndividualStakeHolderResponse.status === API_CODE.STATUS_200 ||
        getAllIndividualStakeHolderResponse.status === API_CODE.STATUS_201
      ) {
        await dispatch(getAllIndividualStakeHolderRequestSuccess(getAllIndividualStakeHolderResponse));
        return getAllIndividualStakeHolderResponse.data;
      }
    } catch (error) {
      dispatch(getAllIndividualStakeHolderRequestFail(error.response));
    }
  };
};
export const getAllCompanyStakeHolderRequestStart = () => ({
  type: actionTypes.GET_ALL_COMPANY_STAKEHOLDER_REQUEST_START
});
export const getAllCompanyStakeHolderRequestFail = (error) => ({
  type: actionTypes.GET_ALL_COMPANY_STAKEHOLDER_REQUEST_FAIL,
  error: error
});
export const getAllCompanyStakeHolderRequestSuccess = (getAllCompanyStakeHolder) => ({
  type: actionTypes.GET_ALL_COMPANY_STAKEHOLDER_REQUEST_SUCCESS,
  getAllCompanyStakeHolder: getAllCompanyStakeHolder
});
export const getAllCompanyStakeHolder = (prospectCode, customerID) => {
  return async (dispatch) => {
    try {
      dispatch(getAllCompanyStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let getAllCompanyStakeHolderResponse = await Api.get(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_COMPANIES}`
      );
      if (
        getAllCompanyStakeHolderResponse.status === API_CODE.STATUS_200 ||
        getAllCompanyStakeHolderResponse.status === API_CODE.STATUS_201
      ) {
        await dispatch(getAllCompanyStakeHolderRequestSuccess(getAllCompanyStakeHolderResponse));
        return getAllCompanyStakeHolderResponse.data;
      }
    } catch (error) {
      dispatch(getAllCompanyStakeHolderRequestFail(error.response));
    }
  };
};

export const ownersIndividualData = (ownersIndividualData) => ({
  type: actionTypes.OWNERS_INDIVIDUAL_DATA_REQUEST_SUCCESS,
  ownersIndividualData: ownersIndividualData
});

export const ownersCompanyData = (ownersCompanyData) => ({
  type: actionTypes.OWNERS_COMPANY_DATA_REQUEST_SUCCESS,
  ownersCompanyData: ownersCompanyData
});

export const createCompanyStakeHolderRequestStart = () => ({
  type: actionTypes.CREATE_COMPANY_STAKEHOLDER_REQUEST_START
});
export const createCompanyStakeHolderRequestFail = (error) => ({
  type: actionTypes.CREATE_COMPANY_STAKEHOLDER_REQUEST_FAIL,
  error: error
});
export const createCompanyStakeHolderRequestSuccess = (createCompanyStakeHolder) => ({
  type: actionTypes.CREATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS,
  createCompanyStakeHolder: createCompanyStakeHolder
});
export const createCompanyStakeHolder = (prospectCode, customerID, createCompanyStakeHolderPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createCompanyStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let createCompanyStakeHolderResponse = await Api.post(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_COMPANIES}`,
        createCompanyStakeHolderPayload
      );
      if (
        createCompanyStakeHolderResponse.status === API_CODE.STATUS_200 ||
        createCompanyStakeHolderResponse.status === API_CODE.STATUS_201 ||
        createCompanyStakeHolderResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(createCompanyStakeHolderRequestSuccess(createCompanyStakeHolderResponse));
        return createCompanyStakeHolderResponse;
      }
    } catch (error) {
      dispatch(createCompanyStakeHolderRequestFail(error.response));
    }
  };
};
export const createThirdPartyInfoRequestStart = () => ({ type: actionTypes.CREATE_THIRD_PARTY_REQUEST_START });
export const createThirdPartyInfoRequestFail = (error) => ({
  type: actionTypes.CREATE_THIRD_PARTY_REQUEST_FAIL,
  error: error
});
export const createThirdPartyInfoRequestSuccess = (createThirdPartyInfo) => ({
  type: actionTypes.CREATE_THIRD_PARTY_REQUEST_SUCCESS,
  createThirdPartyInfo: createThirdPartyInfo
});
export const createThirdPartyInfo = (prospectCode, customerID, createThirdPartyInfoPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createThirdPartyInfoRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let createThirdPartyInfoResponse = await Api.post(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.THIRD_PARTIES}`,
        createThirdPartyInfoPayload
      );
      if (
        createThirdPartyInfoResponse.status === API_CODE.STATUS_200 ||
        createThirdPartyInfoResponse.status === API_CODE.STATUS_201 ||
        createThirdPartyInfoResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(createThirdPartyInfoRequestSuccess(createThirdPartyInfoResponse));
        return createThirdPartyInfoResponse;
      }
    } catch (error) {
      dispatch(createThirdPartyInfoRequestFail(error.response));
    }
  };
};
export const getAllThirdPartyInfoRequestStart = () => ({ type: actionTypes.GET_ALL_THIRD_PARTY_REQUEST_START });
export const getAllThirdPartyInfoRequestFail = (error) => ({
  type: actionTypes.GET_ALL_THIRD_PARTY_REQUEST_FAIL,
  error: error
});
export const getAllThirdPartyInfoRequestSuccess = (getAllThirdPartyInfo) => ({
  type: actionTypes.GET_ALL_THIRD_PARTY_REQUEST_SUCCESS,
  getAllThirdPartyInfo: getAllThirdPartyInfo
});
export const getAllThirdPartyInfo = (prospectCode, customerID, getAllThirdPartyInfoPayload) => {
  return async (dispatch) => {
    try {
      dispatch(getAllThirdPartyInfoRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let getAllThirdPartyInfoResponse = await Api.get(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.THIRD_PARTIES}`,
        getAllThirdPartyInfoPayload
      );
      if (
        getAllThirdPartyInfoResponse.status === API_CODE.STATUS_200 ||
        getAllThirdPartyInfoResponse.status === API_CODE.STATUS_201 ||
        getAllThirdPartyInfoResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(getAllThirdPartyInfoRequestSuccess(getAllThirdPartyInfoResponse));
        return getAllThirdPartyInfoResponse.data;
      }
    } catch (error) {
      dispatch(getAllThirdPartyInfoRequestFail(error.response));
    }
  };
};
export const deleteStakeHolderIndividualRequestStart = () => ({
  type: actionTypes.DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_START
});
export const deleteStakeHolderIndividualRequestFail = (error) => ({
  type: actionTypes.DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_FAIL,
  error: error
});
export const deleteStakeHolderIndividualRequestSuccess = (deleteStakeHolderIndividual) => ({
  type: actionTypes.DELETE_STAKEHOLDER_INDIVIDUAL_REQUEST_SUCCESS,
  deleteStakeHolderIndividual: deleteStakeHolderIndividual
});
export const deleteStakeHolderIndividual = (prospectCode, customerID, individualCode) => {
  return async (dispatch) => {
    try {
      dispatch(deleteStakeHolderIndividualRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let deleteStakeHolderIndividualResponse = await Api.delete(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_INDIVIDUALS}/${individualCode}`
      );
      if (
        deleteStakeHolderIndividualResponse.status === API_CODE.STATUS_200 ||
        deleteStakeHolderIndividualResponse.status === API_CODE.STATUS_201 ||
        deleteStakeHolderIndividualResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(deleteStakeHolderIndividualRequestSuccess(deleteStakeHolderIndividualResponse));
        return deleteStakeHolderIndividualResponse.data;
      }
    } catch (error) {
      dispatch(deleteStakeHolderIndividualRequestFail(error.response));
    }
  };
};
export const deleteStakeHolderCompanyRequestStart = () => ({
  type: actionTypes.DELETE_STAKEHOLDER_COMPANY_REQUEST_START
});
export const deleteStakeHolderCompanyRequestFail = (error) => ({
  type: actionTypes.DELETE_STAKEHOLDER_COMPANY_REQUEST_FAIL,
  error: error
});
export const deleteStakeHolderCompanyRequestSuccess = (deleteStakeHolderCompany) => ({
  type: actionTypes.DELETE_STAKEHOLDER_COMPANY_REQUEST_SUCCESS,
  deleteStakeHolderCompany: deleteStakeHolderCompany
});
export const deleteStakeHolderCompany = (prospectCode, customerID, CompanyCode) => {
  return async (dispatch) => {
    try {
      dispatch(deleteStakeHolderCompanyRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let deleteStakeHolderCompanyResponse = await Api.delete(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_COMPANIES}/${CompanyCode}`
      );
      if (
        deleteStakeHolderCompanyResponse.status === API_CODE.STATUS_200 ||
        deleteStakeHolderCompanyResponse.status === API_CODE.STATUS_201 ||
        deleteStakeHolderCompanyResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(deleteStakeHolderCompanyRequestSuccess(deleteStakeHolderCompanyResponse));
        return deleteStakeHolderCompanyResponse.data;
      }
    } catch (error) {
      dispatch(deleteStakeHolderCompanyRequestFail(error.response));
    }
  };
};
export const deleteThirdPartyInfoRequestStart = () => ({ type: actionTypes.DELETE_THIRD_PARTY_INFO_REQUEST_START });
export const deleteThirdPartyInfoRequestFail = (error) => ({
  type: actionTypes.DELETE_THIRD_PARTY_INFO_REQUEST_FAIL,
  error: error
});
export const deleteThirdPartyInfoRequestSuccess = (deleteThirdPartyInfo) => ({
  type: actionTypes.DELETE_THIRD_PARTY_INFO_REQUEST_SUCCESS,
  deleteThirdPartyInfo: deleteThirdPartyInfo
});
export const deleteThirdPartyInfo = (prospectCode, customerID, thirdPartyCode) => {
  return async (dispatch) => {
    try {
      dispatch(deleteThirdPartyInfoRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let deleteThirdPartyInfoResponse = await Api.delete(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.THIRD_PARTIES}/${thirdPartyCode}`
      );
      if (
        deleteThirdPartyInfoResponse.status === API_CODE.STATUS_200 ||
        deleteThirdPartyInfoResponse.status === API_CODE.STATUS_201 ||
        deleteThirdPartyInfoResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(deleteThirdPartyInfoRequestSuccess(deleteThirdPartyInfoResponse));
        return deleteThirdPartyInfoResponse.data;
      }
    } catch (error) {
      dispatch(deleteThirdPartyInfoRequestFail(error.response));
    }
  };
};

export const getIndividualStakeHolderRequestStart = () => ({
  type: actionTypes.GET_INDIVIDUAL_STAKEHOLDER_REQUEST_START
});
export const getIndividualStakeHolderRequestFail = (error) => ({
  type: actionTypes.GET_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL,
  error: error
});
export const getIndividualStakeHolderRequestSuccess = (getIndividualStakeHolder) => ({
  type: actionTypes.GET_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS,
  getIndividualStakeHolder: getIndividualStakeHolder
});
export const getIndividualStakeHolder = (prospectCode, customerID, individualCode) => {
  return async (dispatch) => {
    try {
      dispatch(getIndividualStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let getIndividualStakeHolderResponse = await Api.get(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_INDIVIDUALS}/${individualCode}`
      );
      if (
        getIndividualStakeHolderResponse.status === API_CODE.STATUS_200 ||
        getIndividualStakeHolderResponse.status === API_CODE.STATUS_201
      ) {
        await dispatch(getIndividualStakeHolderRequestSuccess(getIndividualStakeHolderResponse));
        return getIndividualStakeHolderResponse.data;
      }
    } catch (error) {
      dispatch(getIndividualStakeHolderRequestFail(error.response));
    }
  };
};

export const updateIndividualStakeHolderRequestStart = () => {
  return { type: actionTypes.UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_START };
};
export const updateIndividualStakeHolderRequestFail = (error) => {
  return { type: actionTypes.UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_FAIL, error: error };
};
export const updateIndividualStakeHolderRequestSuccess = (updateIndividualStakeHolder) => {
  return {
    type: actionTypes.UPDATE_INDIVIDUAL_STAKEHOLDER_REQUEST_SUCCESS,
    updateIndividualStakeHolder: updateIndividualStakeHolder
  };
};
export const updateIndividualStakeHolder = (prospectCode, customerID, individualCode, updateIndividualPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateIndividualStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let updateIndividualStakeHolderResponse = await Api.put(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_INDIVIDUALS}/${individualCode}`,
        updateIndividualPayload
      );
      if (updateIndividualStakeHolderResponse.status === 200 || updateIndividualStakeHolderResponse.status === 204) {
        await dispatch(updateIndividualStakeHolderRequestSuccess(updateIndividualStakeHolderResponse));
        return updateIndividualStakeHolderResponse;
      }
    } catch (error) {
      dispatch(updateIndividualStakeHolderRequestFail(error.response));
    }
  };
};

export const getCompanyStakeHolderRequestStart = () => {
  return { type: actionTypes.GET_COMPANY_STAKEHOLDER_REQUEST_START };
};
export const getCompanyStakeHolderRequestFail = (error) => {
  return { type: actionTypes.GET_COMPANY_STAKEHOLDER_REQUEST_FAIL, error: error };
};
export const getCompanyStakeHolderRequestSuccess = (getCompanyStakeHolder) => {
  return { type: actionTypes.GET_COMPANY_STAKEHOLDER_REQUEST_SUCCESS, getCompanyStakeHolder: getCompanyStakeHolder };
};
export const getCompanyStakeHolder = (prospectCode, customerID, companyCode) => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let getCompanyStakeHolderResponse = await Api.get(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_COMPANIES}/${companyCode}`
      );
      if (getCompanyStakeHolderResponse.status === 200 || getCompanyStakeHolderResponse.status === 201) {
        await dispatch(getCompanyStakeHolderRequestSuccess(getCompanyStakeHolderResponse));
        return getCompanyStakeHolderResponse.data;
      }
    } catch (error) {
      dispatch(getCompanyStakeHolderRequestFail(error.response));
    }
  };
};

export const updateCompanyStakeHolderRequestStart = () => {
  return { type: actionTypes.UPDATE_COMPANY_STAKEHOLDER_REQUEST_START };
};
export const updateCompanyStakeHolderRequestFail = (error) => {
  return { type: actionTypes.UPDATE_COMPANY_STAKEHOLDER_REQUEST_FAIL, error: error };
};
export const updateCompanyStakeHolderRequestSuccess = (updateCompanyStakeHolder) => {
  return {
    type: actionTypes.UPDATE_COMPANY_STAKEHOLDER_REQUEST_SUCCESS,
    updateCompanyStakeHolder: updateCompanyStakeHolder
  };
};
export const updateCompanyStakeHolder = (prospectCode, customerID, companyCode, updateCompanyPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateCompanyStakeHolderRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let updateCompanyStakeHolderResponse = await Api.put(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.STAKEHOLDER_COMPANIES}/${companyCode}`,
        updateCompanyPayload
      );
      if (
        updateCompanyStakeHolderResponse.status === API_CODE.STATUS_200 ||
        updateCompanyStakeHolderResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(updateCompanyStakeHolderRequestSuccess(updateCompanyStakeHolderResponse));
        return updateCompanyStakeHolderResponse;
      }
    } catch (error) {
      dispatch(updateCompanyStakeHolderRequestFail(error.response));
    }
  };
};
export const updateThirdPartyInfoRequestStart = () => {
  return { type: actionTypes.UPDATE_THIRD_PARTY_REQUEST_START };
};
export const updateThirdPartyInfoRequestFail = (error) => {
  return { type: actionTypes.UPDATE_THIRD_PARTY_REQUEST_FAIL, error: error };
};
export const updateThirdPartyInfoRequestSuccess = (updateThirdPartyInfo) => {
  return { type: actionTypes.UPDATE_THIRD_PARTY_REQUEST_SUCCESS, updateThirdPartyInfo: updateThirdPartyInfo };
};
export const updateThirdPartyInfo = (prospectCode, customerID, thirdPartyCode, updateThirdPartyPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateThirdPartyInfoRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let updateThirdPartyInfoResponse = await Api.put(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.THIRD_PARTIES}/${thirdPartyCode} `,
        updateThirdPartyPayload
      );
      if (
        updateThirdPartyInfoResponse.status === API_CODE.STATUS_200 ||
        updateThirdPartyInfoResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(updateThirdPartyInfoRequestSuccess(updateThirdPartyInfoResponse));
        return updateThirdPartyInfoResponse;
      }
    } catch (error) {
      dispatch(updateThirdPartyInfoRequestFail(error.response));
    }
  };
};

export const getCompanyInformationRequestStart = () => ({ type: actionTypes.GET_COMPANY_INFORMATION_REQUEST_START });
export const getCompanyInformationRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_INFORMATION_REQUEST_FAIL,
  error: error
});
export const getCompanyInformationRequestSuccess = (getCompanyInformation) => ({
  type: actionTypes.GET_COMPANY_INFORMATION_REQUEST_SUCCESS,
  getCompanyInformation: getCompanyInformation
});
export const getCompanyInformation = (prospectCode, customerID) => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyInformationRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      let getCompanyInformationResponse = await Api.get(`${onBoardingManagementBaseURL}/${ENDPOINTS.COMPANY_INFO}`);
      if (
        getCompanyInformationResponse.status === API_CODE.STATUS_200 ||
        getCompanyInformationResponse.status === API_CODE.STATUS_201 ||
        getCompanyInformationResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(getCompanyInformationRequestSuccess(getCompanyInformationResponse.data));
        return getCompanyInformationResponse.data;
      }
    } catch (error) {
      dispatch(getCompanyInformationRequestFail(error.response));
    }
  };
};

export const uploadDocumentsRequestStart = () => ({ type: actionTypes.UPLOAD_DOCUMENTS_REQUEST_START });
export const uploadDocumentsRequestFail = (error) => ({
  type: actionTypes.UPLOAD_DOCUMENTS_REQUEST_FAIL,
  error: error
});
export const uploadDocumentsRequestSuccess = (uploadDocuments) => ({
  type: actionTypes.UPLOAD_DOCUMENTS_REQUEST_SUCCESS,
  uploadDocuments: uploadDocuments
});
export const uploadDocuments = (uploadDocumentsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(uploadDocumentsRequestStart());
      let uploadDocumentsResponse = await Api.post(`document-management/v2/documents`, uploadDocumentsPayload);
      if (
        uploadDocumentsResponse.status === API_CODE.STATUS_200 ||
        uploadDocumentsResponse.status === API_CODE.STATUS_201 ||
        uploadDocumentsResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(uploadDocumentsRequestSuccess(uploadDocumentsResponse.data));
        return uploadDocumentsResponse.data;
      }
    } catch (error) {
      dispatch(uploadDocumentsRequestFail(error.response));
    }
  };
};

export const createCompanyDocumentsRequestStart = () => ({ type: actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_START });
export const createCompanyDocumentsRequestFail = (error) => ({
  type: actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_FAIL,
  error: error
});
export const createCompanyDocumentsRequestSuccess = (createCompanyDocuments) => ({
  type: actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_SUCCESS,
  createCompanyDocuments: createCompanyDocuments
});
export const createCompanyDocuments = (createCompanyDocumentsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createCompanyDocumentsRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL();
      let createCompanyDocumentsResponse = await Api.post(
        `${onBoardingManagementBaseURL}/company-documents`,
        createCompanyDocumentsPayload
      );
      if (
        createCompanyDocumentsResponse.status === API_CODE.STATUS_200 ||
        createCompanyDocumentsResponse.status === API_CODE.STATUS_201 ||
        createCompanyDocumentsResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(createCompanyDocumentsRequestSuccess(createCompanyDocumentsResponse.data));
        return createCompanyDocumentsResponse;
      }
    } catch (error) {
      dispatch(createCompanyDocumentsRequestFail(error.response));
    }
  };
};
export const createStakeHolderDocumentRequestStart = () => ({
  type: actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_START
});
export const createStakeHolderDocumentRequestFail = (error) => ({
  type: actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_FAIL,
  error: error
});
export const createStakeHolderDocumentRequestSuccess = (createStakeHolderDocument) => ({
  type: actionTypes.CREATE_COMPANY_DOCUMENTS_REQUEST_SUCCESS,
  createStakeHolderDocument: createStakeHolderDocument
});
export const createStakeHolderDocument = (createStakeHolderDocumentPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createStakeHolderDocumentRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL();
      let createStakeHolderDocumentResponse = await Api.post(
        `${onBoardingManagementBaseURL}/stakeholder-documents`,
        createStakeHolderDocumentPayload
      );
      if (
        createStakeHolderDocumentResponse.status === API_CODE.STATUS_200 ||
        createStakeHolderDocumentResponse.status === API_CODE.STATUS_201 ||
        createStakeHolderDocumentResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(createStakeHolderDocumentRequestSuccess(createStakeHolderDocumentResponse.data));
        return createStakeHolderDocumentResponse;
      }
    } catch (error) {
      dispatch(createStakeHolderDocumentRequestFail(error.response));
    }
  };
};
export const getBusinessInformationRequestStart = () => ({ type: actionTypes.GET_BUSINESS_INFORMATION_REQUEST_START });
export const getBusinessInformationRequestFail = (error) => ({
  type: actionTypes.GET_BUSINESS_INFORMATION_REQUEST_FAIL,
  error: error
});
export const getBusinessInformationRequestSuccess = (getBusinessInformation) => ({
  type: actionTypes.GET_BUSINESS_INFORMATION_REQUEST_SUCCESS,
  getBusinessInformation: getBusinessInformation
});
export const getBusinessInformation = () => {
  return async (dispatch) => {
    try {
      dispatch(getBusinessInformationRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL();
      let getBusinessInformationResponse = await Api.get(`${onBoardingManagementBaseURL}/${ENDPOINTS.BUSINESS_INFO}`);

      if (getBusinessInformationResponse.status === API_CODE.STATUS_200) {
        await dispatch(getBusinessInformationRequestSuccess(getBusinessInformationResponse.data));
        return getBusinessInformationResponse;
      }
    } catch (error) {
      dispatch(getBusinessInformationRequestFail(error.response));
    }
  };
};
export const getAllIndividualStakeHolderDocumentsRequestStart = () => ({
  type: actionTypes.GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_START
});
export const getAllIndividualStakeHolderDocumentsRequestFail = (error) => ({
  type: actionTypes.GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL,
  error: error
});
export const getAllIndividualStakeHolderDocumentsRequestSuccess = (getAllIndividualStakeHolderDocuments) => ({
  type: actionTypes.GET_All_INDIVIDUAL_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS,
  getAllIndividualStakeHolderDocuments: getAllIndividualStakeHolderDocuments
});
export const getAllIndividualStakeHolderDocuments = () => {
  return async (dispatch) => {
    try {
      dispatch(getAllIndividualStakeHolderDocumentsRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL();
      let getAllIndividualStakeHolderDocumentsResponse = await Api.get(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.INDIVIDUAL_STAKEHOLDER_DOCUMENTS}`
      );
      if (getAllIndividualStakeHolderDocumentsResponse.status === API_CODE.STATUS_200) {
        await dispatch(
          getAllIndividualStakeHolderDocumentsRequestSuccess(getAllIndividualStakeHolderDocumentsResponse)
        );
        return getAllIndividualStakeHolderDocumentsResponse;
      }
    } catch (error) {
      dispatch(getAllIndividualStakeHolderDocumentsRequestFail(error.response));
    }
  };
};
export const getAllCompanyStakeHolderDocumentsRequestStart = () => ({
  type: actionTypes.GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_START
});
export const getAllCompanyStakeHolderDocumentsRequestFail = (error) => ({
  type: actionTypes.GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_FAIL,
  error: error
});
export const getAllCompanyStakeHolderDocumentsRequestSuccess = (getAllCompanyStakeHolderDocuments) => ({
  type: actionTypes.GET_All_COMPANY_STAKEHOLDER_DOCUMENTS_REQUEST_SUCCESS,
  getAllCompanyStakeHolderDocuments: getAllCompanyStakeHolderDocuments
});
export const getAllCompanyStakeHolderDocuments = () => {
  return async (dispatch) => {
    try {
      dispatch(getAllCompanyStakeHolderDocumentsRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL();
      let getAllCompanyStakeHolderDocumentsResponse = await Api.get(
        `${onBoardingManagementBaseURL}/${ENDPOINTS.COMPANY_STAKEHOLDER_DOCUMENTS}`
      );
      if (getAllCompanyStakeHolderDocumentsResponse.status === API_CODE.STATUS_200) {
        await dispatch(getAllCompanyStakeHolderDocumentsRequestSuccess(getAllCompanyStakeHolderDocumentsResponse));
        return getAllCompanyStakeHolderDocumentsResponse;
      }
    } catch (error) {
      dispatch(getAllCompanyStakeHolderDocumentsRequestFail(error.response));
    }
  };
};
export const getCompanyDocumentsRequestStart = () => ({ type: actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_START });
export const getCompanyDocumentsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_FAIL,
  error: error
});
export const getCompanyDocumentsRequestSuccess = (getCompanyDocuments) => ({
  type: actionTypes.GET_COMPANY_DOCUMENTS_REQUEST_SUCCESS,
  getCompanyDocuments: getCompanyDocuments
});
export const getCompanyDocuments = () => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyDocumentsRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL();
      let getCompanyDocumentsResponse = await Api.get(`${onBoardingManagementBaseURL}/company-documents`);
      if (
        getCompanyDocumentsResponse.status === API_CODE.STATUS_200 ||
        getCompanyDocumentsResponse.status === API_CODE.STATUS_201 ||
        getCompanyDocumentsResponse.status === API_CODE.STATUS_204
      ) {
        await dispatch(getCompanyDocumentsRequestSuccess(getCompanyDocumentsResponse.data));
        return getCompanyDocumentsResponse.data;
      }
    } catch (error) {
      dispatch(getCompanyDocumentsRequestFail(error.response));
    }
  };
};
export const completeStageRequestStart = () => ({ type: actionTypes.COMPLETE_STAGE_REQUEST_START });
export const completeStageRequestFail = (error) => ({ type: actionTypes.COMPLETE_STAGE_REQUEST_FAIL, error: error });
export const completeStageRequestSuccess = (completeStage) => ({
  type: actionTypes.COMPLETE_STAGE_REQUEST_SUCCESS,
  completeStage: completeStage
});
export const completeStage = (completeStagePayload) => {
  return async (dispatch) => {
    try {
      dispatch(completeStageRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL();
      let completeStageResponse = await Api.patch(
        `${onBoardingManagementBaseURL}/complete-stage?stage=${completeStagePayload}`,
        completeStagePayload
      );
      if (completeStageResponse.status === API_CODE.STATUS_204) {
        await dispatch(completeStageRequestSuccess(completeStageResponse));
        return completeStageResponse;
      }
    } catch (error) {
      dispatch(completeStageRequestFail(error.response));
    }
  };
};
export const applicationSubmissionRequestStart = () => ({ type: actionTypes.APPLICATION_SUBMISSION_REQUEST_START });
export const applicationSubmissionRequestFail = (error) => ({
  type: actionTypes.APPLICATION_SUBMISSION_REQUEST_FAIL,
  error: error
});
export const applicationSubmissionRequestSuccess = (applicationSubmission) => ({
  type: actionTypes.APPLICATION_SUBMISSION_REQUEST_SUCCESS,
  applicationSubmission: applicationSubmission
});
export const applicationSubmissionMetaDataRequestSuccess = (applicationSubmissionMetaData) => ({
  type: actionTypes.APPLICATION_METADATA_SUBMISSION_REQUEST_SUCCESS,
  applicationSubmissionMetaData: applicationSubmissionMetaData
});
export const applicationSubmission = (
  applicationSubmissionPayload,
  prospectCode,
  customerID,
  isSubmittingMetadata = false
) => {
  return async (dispatch) => {
    try {
      dispatch(applicationSubmissionRequestStart());
      let onBoardingManagementBaseURL = await getOnboardingManagementBaseURL(prospectCode, customerID);
      const applicationSubmissionUrl = isSubmittingMetadata
        ? `${onBoardingManagementBaseURL}/metadata`
        : `${onBoardingManagementBaseURL}/submit`;
      let applicationSubmissionResponse = await Api.patch(applicationSubmissionUrl, applicationSubmissionPayload);
      if (
        applicationSubmissionResponse.status === API_CODE.STATUS_200 ||
        applicationSubmissionResponse.status === API_CODE.STATUS_204
      ) {
        if (isSubmittingMetadata) {
          await dispatch(applicationSubmissionMetaDataRequestSuccess(applicationSubmissionResponse));
        } else {
          await dispatch(applicationSubmissionRequestSuccess(applicationSubmissionResponse));
        }
        return true;
      }
    } catch (error) {
      dispatch(applicationSubmissionRequestFail(error.response));
    }
  };
};

export const paymentCountriesListRequestStart = () => ({ type: actionTypes.PAYMENT_COUNTRIES_LIST_REQUEST_START });
export const paymentCountriesListRequestFail = (error) => ({
  type: actionTypes.PAYMENT_COUNTRIES_LIST_REQUEST_FAIL,
  error: error
});
export const paymentCountriesListRequestSuccess = (paymentCountriesList) => ({
  type: actionTypes.PAYMENT_COUNTRIES_LIST_REQUEST_SUCCESS,
  paymentCountriesList: paymentCountriesList
});
export const paymentCountriesList = () => {
  return async (dispatch) => {
    try {
      dispatch(paymentCountriesListRequestStart());
      let paymentCountriesListResponse = await Api.get(`${ENDPOINTS.PAYMENT_COUNTRIES}`);
      if (paymentCountriesListResponse.data) {
        await dispatch(paymentCountriesListRequestSuccess(paymentCountriesListResponse.data));
        return paymentCountriesListResponse.data;
      }
    } catch (error) {
      dispatch(paymentCountriesListRequestFail(error.response));
    }
  };
};

export const countryDialCodesRequestStart = () => ({ type: actionTypes.COUNTRIES_DIAL_CODES_REQUEST_START });
export const countryDialCodesRequestFail = (error) => ({
  type: actionTypes.COUNTRIES_DIAL_CODES_REQUEST_FAIL,
  error: error
});
export const countryDialCodesRequestSuccess = (countryDialCodeList) => ({
  type: actionTypes.COUNTRIES_DIAL_CODES_REQUEST_SUCCESS,
  countryDialCodeList: countryDialCodeList
});
export const countryDialCodes = () => {
  return async (dispatch) => {
    try {
      dispatch(countryDialCodesRequestStart());
      let dialCodeListResponse = await Api.get(`${ENDPOINTS.COUNTRY_DIAL_CODES}`);
      if (dialCodeListResponse) {
        await dispatch(countryDialCodesRequestSuccess(dialCodeListResponse));
        return dialCodeListResponse;
      }
    } catch (error) {
      dispatch(countryDialCodesRequestFail(error.response));
    }
  };
};

export const phoneValidationOtpRequestStart = () => ({ type: actionTypes.PHONE_VALIDATION_OTPS_REQUEST_START });
export const phoneValidationOtpRequestFail = (error) => ({
  type: actionTypes.PHONE_VALIDATION_OTPS_REQUEST_FAIL,
  error: error
});
export const phoneValidationOtpRequestSuccess = (phoneOtpInformation) => ({
  type: actionTypes.PHONE_VALIDATION_OTPS_REQUEST_SUCCESS,
  phoneOtpInformation: phoneOtpInformation
});
export const phoneValidationOtp = (phoneValidationOtpPayload) => {
  return async (dispatch) => {
    try {
      dispatch(phoneValidationOtpRequestStart());
      let phoneValidationOtpResponse = await Api.post(`${ENDPOINTS.PHONE_VALIDATION_OTPS}`, phoneValidationOtpPayload);
      if (phoneValidationOtpResponse.status === API_CODE.STATUS_200) {
        await dispatch(phoneValidationOtpRequestSuccess(phoneValidationOtpResponse));
        return phoneValidationOtpResponse;
      }
    } catch (error) {
      dispatch(phoneValidationOtpRequestFail(error.response));
    }
  };
};

export const updatePhoneRequestStart = () => {
  return { type: actionTypes.UPADTE_PHONE_REQUEST_START };
};
export const updatePhoneRequestFail = (error) => {
  return { type: actionTypes.UPADTE_PHONE_REQUEST_FAIL, error: error };
};
export const updatePhoneRequestSuccess = (updatePhoneVerification) => {
  return { type: actionTypes.UPADTE_PHONE_REQUEST_SUCCESS, updatePhoneVerification: updatePhoneVerification };
};
export const updatePhone = (updatePhonePayload) => {
  return async (dispatch) => {
    try {
      dispatch(updatePhoneRequestStart());
      let updatePhoneResponse = await Api.put(`${ENDPOINTS.UPDATE_PHONE}`, updatePhonePayload);
      if (updatePhoneResponse.status === API_CODE.STATUS_200) {
        await dispatch(updatePhoneRequestSuccess(updatePhoneResponse));
        return updatePhoneResponse;
      }
    } catch (error) {
      dispatch(updatePhoneRequestFail(error.response));
    }
  };
};

export const getOnfidoIntegrationFlagRequestStart = () => ({
  type: actionTypes.GET_ONFIDO_INTEGRATION_FLAG_REQUEST_START
});
export const getOnfidoIntegrationFlagRequestFail = (error) => ({
  type: actionTypes.GET_ONFIDO_INTEGRATION_FLAG_REQUEST_FAIL,
  error: error
});
export const getOnfidoIntegrationFlagRequestSuccess = (onfidoIntegrationFlag) => ({
  type: actionTypes.GET_ONFIDO_INTEGRATION_FLAG_REQUEST_SUCCESS,
  onfidoIntegrationFlag: onfidoIntegrationFlag
});

export const getOnfidoIntegrationFlag = (prospectCode, customerID) => {
  return async (dispatch) => {
    try {
      if (!sessionStorage.getItem(SESSION_STORAGE_NAME.CUSTOMER_ID) && !customerID) {
        return;
      }
      dispatch(getOnfidoIntegrationFlagRequestStart());
      let getOnfidoIntegrationFlagResponse = await Api.get(`${getOnfidoIntegrationFlagsBaseUrl(customerID)}`);
      if (
        getOnfidoIntegrationFlagResponse.status === API_CODE.STATUS_200 ||
        getOnfidoIntegrationFlagResponse.status === API_CODE.STATUS_201
      ) {
        await dispatch(
          getOnfidoIntegrationFlagRequestSuccess({
            isEnabled: getOnfidoIntegrationFlagResponse.data
          })
        );
        return getOnfidoIntegrationFlagResponse.data;
      }
    } catch (error) {
      dispatch(getOnfidoIntegrationFlagRequestFail(error.response));
    }
  };
};
