export const ENDPOINTS = {
  CREATE: 'company/create',
  UPDATE: 'company/update',
  DETAILS: 'company/details',
  UPLOAD_FILE: 'company/uploadFile',
  CREATE_PAYMENT: 'company/createPayment',
  DELETE_STAKHOLDER: 'company/delete-stake-holder',
  EXISTING_REGISTRATION_COMPANY: '/company/existing-incorporation-register-company',
  EXISTING_REGISTRATION_COMPANY_V2: '/incorporation/users/auth-existing-account'
};
