import React, { useState } from 'react';
import BaseText from '../ui/typography/BaseText';
import CashInHand from '../../images/graphics/girl-with-cash.png';
import XXSText from '../ui/typography/XXSText';
import XSText from '../ui/typography/XSText';
import BackButton from '../ui/buttons/BackButton';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { API_CODE, CONSTANT_NUMBER, IS_SSO_ON, SESSION_STORAGE_NAME } from '../../constants/enum';
import CustomisedCheckbox from '../ui/Checkbox';
import CloseIcon from '../../images/icons/close-icon.svg';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import { FEES, COMPANY_REGISTRATION_COUNTRY } from '../../constants/enum';
import axios from 'axios';
import StatrysLuckySpinner from '../ui/loaders/StatrysLucky';
import DynamicModal from '../../components/ui/modal/DynamicModal';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { MUI_COLORS } from '../../constants/enum';
import CreditCardInline from '../../images/icons/inline/credit-card.inline.svg';
import CardErrorPNG from '../../images/graphics/white-card-error.png';
import { ENDPOINTS } from '../../service/api-end-points';
import { BASE_API_URL } from '../../constants/enum';
function PaymentAuthorization({ setStep, companyDetails }) {
  const [termAndConditions, setTermAndConditions] = useState(false);
  const [error, setError] = useState({ termAndConditions: false });
  const [errorMessage, setErrorMessage] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onBack = () => {
    setStep(CONSTANT_NUMBER.ONE);
  };

  const onClickCheckbox = (e) => {
    setTermAndConditions(!termAndConditions);
    if (e.target.checked) {
      setError({ ...error, termAndConditions: false });
    }
  };

  const closeButtonClicked = () => {
    if (IS_SSO_ON) {
      navigate(PATH_PAGE?.companyProfile);
    } else {
      navigate(PATH_PAGE?.companiesList);
    }
  };

  async function businessAccountDeductionConsent() {
    try {
      const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
      setLoading(true);

      if (!token) {
        throw new Error('Authorization token is missing');
      }

      const baseUrl = BASE_API_URL;
      const apiUrl = baseUrl + ENDPOINTS.BUSINESS_ACCOUNT_MANUAL_RENEWAL;
      const payload = {
        companyId: companyDetails?._id,
        email: companyDetails?.email
      };
      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === API_CODE.STATUS_200) {
        setStep(CONSTANT_NUMBER.THREE);
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      setPaymentModal(true);
      setErrorMessage(error?.response?.data?.message || error.response?.data?.customMessage || error.message);
    }
  }
  const onRenewButton = () => {
    if (termAndConditions) {
      businessAccountDeductionConsent();
    } else {
      setError({ ...error, termAndConditions: true });
    }
  };

  const registrationCountry = companyDetails?.registrationCountry;
  const renewalAmount =
    registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG
      ? FEES.COMPANY_CREATION_CAPTION_RENEWAL
      : FEES.SINGAPORE_COMPANY_CREATION_CAPTION_RENEWAL;

  return (
    <>
      <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between renew-payment-form-container">
        <div className="flex justify-between">
          <BaseText fontWeight="text-bold" fontSize="text-3xl" title="Payment Authorization" />
          <div onClick={() => closeButtonClicked()} className="cursor-pointer">
            <img src={CloseIcon} className="w-4 h-4" alt="close icon" />
          </div>
        </div>

        <div className="flex flex-col gap-4 justify-between whitespace-pre-line">
          <div className="flex flex-col items-center justify-between mt-8">
            <img src={CashInHand} className="w-[228px] h-[160px]" alt="Payment Mode" />
          </div>
          <XXSText
            title={`I want to proceed with the renewal of the Business Registration Certificate for ${companyDetails?.companyName} and authorize the debit of the business account held by ${companyDetails?.companyName} with Statrys to settle the renewal fee of HKD 7,740.`}
          />
          <div className="flex gap-2 p-4 bg-customBg-mainBg rounded-lg">
            <CustomisedCheckbox isChecked={termAndConditions} handleChange={onClickCheckbox} />
            <XXSText
              className="pt-[2px]"
              title="By ticking this box, I hereby authorize the renewal and associated payment."
            />
          </div>
          <div className="flex justify-between md:items-center md:flex-row flex-col-reverse w-full">
            <BackButton onClick={onBack} className="md:w-[114px]" />
            {!error?.termAndConditions && termAndConditions ? (
              <PrimaryButton
                caption="Renew Now"
                color="text-white"
                bgColor="bg-green-500"
                isBorderRequired={true}
                fontSize="text-base"
                className="md:w-36 w-full"
                onClick={() => onRenewButton()}
                id="payment-authorization-btn"
              />
            ) : (
              <PrimaryButton
                className="md:w-36 w-full"
                isBorderRequired={true}
                bgColor="bg-gray-200"
                fontSize="text-base"
                color="text-gray-500"
                caption="Renew Now"
                id="renew_Btn"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center payment-spinner-container">
        <DynamicModal hideCrossIcon={true} isCloseRequired={false} openDynamicModal={loading} minWidth="424px">
          <div className="bg-white flex flex-col items-center justify-center loader-modal-height">
            <StatrysLuckySpinner />
            <XSText title="Processing" className="mt-4" textColor="text-gray-450" textAlign="text-center" />
          </div>
        </DynamicModal>
      </div>

      <DynamicModal openDynamicModal={paymentModal} closeDynamicModal={() => setPaymentModal(false)} maxWidth="md">
        <div className="md:w-[411px] flex flex-col gap-6">
          <img src={CardErrorPNG} className="mx-auto mt-4" alt="secure" height={127} width={191} />
          <MDSubtitleText fontWeight="text-bold" textAlign="text-center" title="Your payment was not successful" />
          {errorMessage && <XSText textAlign="text-center" title={errorMessage} />}

          <XSText textAlign="text-center" title="To proceed, you may try using a different payment method" />
          <div className="flex gap-4 items-center justify-center">
            <div
              className="md:w-1/2 w-full bg-coral-500 flex items-center gap-2 justify-center py-4 rounded-lg cursor-pointer"
              onClick={onBack}
            >
              <CreditCardInline stroke={MUI_COLORS?.WHITE} />
              <XSText textAlign="text-center" textColor="text-white" title="Try another method" />
            </div>
          </div>
        </div>
      </DynamicModal>
    </>
  );
}

export default PaymentAuthorization;
