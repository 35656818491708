import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { API_CODE, MUI_COLORS, REQUIRED_LENGTH, TYPE_OF_OTP } from '../../constants/enum';
import '../../styles/verifyOtpInput.scss';
import ValidationMessage from '../ui/ValidationMessage';
import { connect } from 'react-redux';
import * as OtpActions from './reduxStore/action';
import PropTypes from 'prop-types';
import { PATH_PAGE } from '../../routes/paths';
import { navigate } from 'gatsby';
import { ENDPOINTS } from '../../service/services';
import axios from 'axios';
import { BASE_API_URL } from '../../constants/enum';

function VerifyOtpInput({ email, fromForgotPassword, emailForConfirmation, fromResendCode }) {
  const [otp, setOtp] = useState('');
  const [error, setOtpError] = useState('');

  const handleChange = async (otpValue) => {
    setOtp(otpValue);
    if (otpValue.length === REQUIRED_LENGTH.OTP_LENGTH) {
      let payload = {
        email: fromForgotPassword || fromResendCode ? emailForConfirmation : email,
        otp: otpValue,
        type: fromForgotPassword
          ? TYPE_OF_OTP?.FORGOT_PASSWORD
          : fromResendCode
          ? TYPE_OF_OTP?.RESEND_CODE
          : TYPE_OF_OTP?.SIGN_UP
      };
      try {
        const otpResponse = await axios.put(`${BASE_API_URL}${ENDPOINTS?.VERIFY_OTP}`, payload);
        if (otpResponse?.status === API_CODE?.STATUS_200) {
          navigate(PATH_PAGE?.resetPassword, {
            state: { emailForResetPassword: emailForConfirmation, otpForResetPassword: otpValue }
          });
        } else {
          setOtpError(otpResponse?.data?.message);
        }
      } catch (error) {
        setOtpError(error?.response?.data?.message);
      }
    }
  };
  const handleClickCapture = () => {
    if (error) {
      setOtp('');
      setOtpError('');
    }
  };

  return (
    <div onClickCapture={handleClickCapture}>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        isInputNum={true}
        shouldAutoFocus={true}
        separator={<span className="ml-2"></span>}
        inputStyle={error ? 'otp-input-error' : 'otp-input'}
        focusStyle={{
          border: error ? '1px solid #FF4F42' : '1px solid #2D0D45',
          background: error ? MUI_COLORS.LIGHT_CREAM : MUI_COLORS.WHITE,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          outline: 'none'
        }}
      />
      {error && <ValidationMessage title={error} />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    email: state?.signupReducer?.signupDetails?.data?.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendToSendGridContacts: (contacts) => dispatch(OtpActions.sendContacts(contacts))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtpInput);

VerifyOtpInput.propTypes = {
  email: PropTypes.string,
  fromForgotPassword: PropTypes.bool,
  emailForConfirmation: PropTypes.string,
  fromResendCode: PropTypes.bool,
  getInvoiceGenerator: PropTypes.func,
  updateUserInvoiceGenerator: PropTypes.func,
  sendToSendGridContacts: PropTypes.func
};
