import * as actionTypes from './actionTypes';

let initialState = {
  loginDetails: null,
  loading: false,
  error: false,
  resetPasswordDetails: {},
  updateSSOToken: null
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loginDetails: action.loginPayload,
        loading: false,
        error: false
      };
    case actionTypes.LOGIN_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.RESET_PASSWORD_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        resetPasswordDetails: action.resetPasswordPayload,
        loading: false,
        error: false
      };
    case actionTypes.RESET_PASSWORD_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    case actionTypes.UPDATE_SSO_TOKEN_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_SSO_TOKEN_REQUEST_SUCCESS:
      return {
        ...state,
        updateSSOToken: action.updateSSOTokenPayload,
        loading: false,
        error: false
      };
    case actionTypes.UPDATE_SSO_TOKEN_REQUEST_FAILURE:
      return { ...state, error: action.error, loading: false };

    default:
      return state;
  }
};
export default LoginReducer;
