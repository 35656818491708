const coralColor = '#E15741';
const whiteColor = '#FFFFFF';
const blackColor = '#2B2D33';
const lightCoralColor = '#FCEFE8';
const lightGrayColor = '#D9D9D9';
const defaultBorderRadius = '8px';
export const onfidoConfig = {
  disableWelcomeScreen: true,
  uploadFallback: false,
  customUI: {
    borderRadiusButton: defaultBorderRadius,
    borderRadiusMedium: defaultBorderRadius,
    colorBackgroundButtonPrimary: coralColor,
    colorBackgroundButtonPrimaryHover: coralColor,
    colorBorderButtonPrimaryHover: coralColor,
    colorBorderButtonPrimary: coralColor,
    colorBorderActionFocus: coralColor,
    colorPrimary500: coralColor,
    colorPrimary600: coralColor,
    colorBackgroundButtonPrimaryActive: coralColor,
    colorIcon: coralColor,
    colorInputOutline: coralColor,
    colorContentLinkTextHover: coralColor,
    colorContentButtonTertiaryText: blackColor,
    colorBackgroundActionSubtle: coralColor,
    colorBackgroundActionSubtleHover: coralColor,
    colorBorderLinkUnderline: coralColor,
    colorBackgroundLinkHover: 'transparent',
    colorBackgroundLinkActive: 'transparent',
    colorBackgroundButtonIconHover: 'transparent',
    colorBackgroundButtonIconActive: 'transparent',
    colorBorderDocTypeButton: blackColor,
    colorBorderDocTypeButtonActive: blackColor,
    colorBackgroundInfoPill: blackColor,

    colorBackgroundButtonSecondary: whiteColor,
    colorBorderButtonSecondary: coralColor,
    colorBackgroundButtonSecondaryHover: whiteColor,
    colorBackgroundButtonSecondaryActive: whiteColor,
    colorContentButtonSecondaryText: coralColor,

    colorBackgroundDocTypeButton: 'transparent',
    colorBackgroundDocTypeButtonHover: lightCoralColor,
    colorBackgroundDocTypeButtonActive: lightCoralColor,
    colorBorderDocTypeButtonHover: blackColor,

    colorBackgroundDisclaimer: lightCoralColor,
    colorContentButtonPrimaryText: whiteColor,

    colorBackgroundAlertInfo: coralColor,
    colorBackgroundAlertError: coralColor,

    colorBackgroundButtonCameraActive: lightCoralColor,
    colorBackgroundButtonCameraHover: lightCoralColor,
    colorBackgroundIcon: lightGrayColor,
    colorBorderSurfaceModal: lightGrayColor
  }
};

export const onfidoLanguagesConfig = {
  language: {
    locale: 'en_US',
    direction: 'ltr',
    phrases: {
      complete: {
        outro: {
          body: ' '
        }
      },
      cross_device_intro: {
        list_accessibility: 'Steps required to continue verification on your mobile',
        list_item_finish: 'Check back here to finish the submission',
        list_item_open_link: 'Open the link and complete the tasks',
        list_item_send_phone: 'Send a secure link to your phone',
        subtitle_upload: 'Please upload a recent photo of your original document'
      },
      doc_select: {
        subtitle_country: 'Your nationality will dictate wich ID document(s) are accepted for verification.',
        title: 'Confirm your nationality',
        section: {
          header_country: 'Nationality',
          header_doc_type: 'Accepted ID Document(s)',
          input_country_not_found: 'No Results',
          input_placeholder_country: 'Select nationality'
        }
      }
    }
  }
};

export const onfidoErrorCodes = {
  VERIFICATION_IN_PROCESS: 'ONF-1005',
  VERIFICATION_LINK_EXPIRED: 'ONF-1004',
  A_TECHNICAL_ERROR_OCCURRED: 'ONF-1000'
};
