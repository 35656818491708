import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN,
  SINGAPORE_COMPANY_CREATION_FEES_DROPDOWN,
  HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING,
  SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING,
  COMPANY_REGISTRATION_COUNTRY
} from '../../../constants/enum.js';


import '../../../styles/pages/new-company-signup/promo-price.scss';
import DropDown from '../../common/DropDown.js';
export default function PromoPrice({ registrationCountry }) {
  const currencyOfCompanyCreation =
    registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG
      ? HONG_KONG_COMPANY_CREATION_FEES_DROPDOWN
      : SINGAPORE_COMPANY_CREATION_FEES_DROPDOWN;
  const originalPricing =
    registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG
      ? HONG_HONG_COMPANY_CREATION_ORIGINAL_PRICING
      : SINGAPORE_COMPANY_CREATION_ORIGINAL_PRICING;
  const [selectedPrice, setSelectedPrice] = useState(currencyOfCompanyCreation?.[0]);
  const [isPricingDropDownOpen, setIsPricingDropDownOpen] = useState(false);
  const [currencyOptionsToShow, setCurrencyOptionsToShow] = useState([]);

  const checkDropdown = () => {
    setIsPricingDropDownOpen(!isPricingDropDownOpen);
  };
  const replaceCurrency = (optionValues) => {
    const selectedCurrency = currencyOptionsToShow.indexOf(optionValues);
    currencyOptionsToShow.splice(selectedCurrency, 1);
    setCurrencyOptionsToShow([...currencyOptionsToShow, selectedPrice]);
  };

  const selectOption = (optionValues) => {
    setSelectedPrice(optionValues);
    replaceCurrency(optionValues);
  };
  function equiv() {
    return currencyOptionsToShow?.[0]?.price
      ? currencyOptionsToShow?.[0]?.title + currencyOptionsToShow?.[0]?.price
      : currencyOfCompanyCreation[1]?.title + currencyOfCompanyCreation[1]?.price;
  }
  function removeSelectedCurrencyFromOptions() {
    const otherTwoCurrencies = currencyOfCompanyCreation.slice(1, 3);
    setCurrencyOptionsToShow(otherTwoCurrencies);
  }
  useEffect(() => {
    removeSelectedCurrencyFromOptions();
  }, []);

  const sanitizePrice = (price) => {
    return price ? Number(price.replace(/,/g, '')) : 0;
  };

  const originalPriceValue = sanitizePrice(
    originalPricing?.find((pricing) => pricing?.title === selectedPrice?.title)?.price
  );
  const selectedPriceValue = sanitizePrice(selectedPrice.price);

  
return (
  <>
    <div className="flex flex-col gap-2 w-full md:w-96 mx-auto" id="promobanner">
    
      <div
        className="border border-green-500 bg-white relative md:mt-0 mt-5 shadow-md rounded-md">
        
        {process.env.COMPANY_CREATION_DISCOUNT && (
registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG ? (
  <StaticImage
    src="../../../images/graphics/registerCompany/promo-cc-hk.png"
    className="w-[120px] h-[120px] md:w-[160px] md:h-[160px] absolute right-[-15px] top-[-58px] md:right-[-55px] md:top-[-58px] promoImage"
    alt="Company Creation Discount Promo for Hong Kong"
  />
) : (
  <StaticImage
    src="../../../images/graphics/registerCompany/promo-cc-sg.png" 
    className="w-[120px] h-[120px] md:w-[160px] md:h-[160px] absolute right-[-15px] top-[-58px] md:right-[-55px] md:top-[-58px] promoImage"
    alt="Company Creation Discount Promo"
  />
)
)}

       
        <div className="p-4 flex flex-col gap-4">
          <div className="w-full flex flex-col justify-center relative">
          <p className="text-[20px] text-bold leading-[100%] tracking-[-1px] pb-2 text-green-500">
          Limited Offer - Save Now!
                </p>
            <div className="flex gap-1 items-center">
              <span className="text-[24px] text-bold leading-[100%] tracking-[-1px]">Pay Only</span>
              <div className=" flex flex-row">
                <p className="text-[30px] text-bold leading-[100%] tracking-[-1px] text-green-500">
                  {selectedPrice?.title}
                </p>
                <p className="text-[30px] text-bold leading-[100%] tracking-[-1px] text-green-500">
                  {selectedPrice?.price}
                </p>
              </div>
              <div
                className="small-arrow-down ml-0.5 flex self-center cursor-pointer small-arrow-down"
                onMouseEnter={checkDropdown}
                onMouseLeave={checkDropdown}
              >
                {isPricingDropDownOpen && <DropDown options={currencyOptionsToShow} selectOption={selectOption} />}
              </div>
            </div>
            <p className="text-base text-medium leading-[100%] tracking-[-0.32x] text-green-600">
              (approx. {equiv()})
            </p>
          </div>
          {process.env.COMPANY_CREATION_DISCOUNT && (
            <div className="flex flex-col gap-1">
              <div className="flex gap-0.5 items-center">
                <span className="text-green-600 text-bold font-semibold leading-[100%] tracking-[-0.26px]">Full Price</span>
                <div className="relative inline-block p-1">
                 
                  <div className="flex justify-center text-green-600">
                    <p className="text-base font-semibold leading-[100%] tracking-[-0.26px]">
                      {originalPricing?.find((pricing) => pricing?.title === selectedPrice?.title)?.title}
                    </p>
                    <p className="text-base font-semibold leading-[100%] tracking-[-0.26px]">
                      {originalPricing?.find((pricing) => pricing?.title === selectedPrice?.title)?.price}
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
            
          )}
         
        </div>
        <p className="p-2 w-full px-4 bg-green-600 rounded-br-md rounded-bl-md text-yellow-500 text-bold text-lg text-center leading-[100%] tracking-[-0.26px]">
        Offer Ends on May 4!
        </p>
      </div>
    </div>
  </>
);
}
