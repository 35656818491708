import axios from 'axios';
import { Matching401ApiErrorStatus } from '../../helper/helpers';
import { PATH_PAGE } from '../../routes/paths';
import {
  API_ERROR_CODES,
  API_ERROR_MESSAGES,
  EMITTER_CONSTANTS,
  STATUS,
  SESSION_STORAGE_NAME
} from '../../constants/enum';
import { navigate } from 'gatsby';
import Emitter from '../../service/emitter';
import { BASE_API_URL } from '../../constants/enum';
const Api = axios.create({
  baseURL: BASE_API_URL
});

Api.interceptors.request.use(
  async function (config) {
    const token = await sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
    if (token && token?.length > 0) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const responseHandler = (response) => {
  return response;
};
Api.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    if (process.env.MAINTENANCE_FLOW_ENABLED === STATUS.TRUE) {
      if (typeof window !== `undefined`) {
        navigate('/under-maintenance');
      }
    }
    if (Matching401ApiErrorStatus(error)) {
      sessionStorage.removeItem('token');
      navigate(PATH_PAGE?.login);
    } else {
      if (error?.response?.data?.statusCode !== API_ERROR_CODES?.STATUS_405) {
        Emitter.emit(EMITTER_CONSTANTS.INPUT_FROM_MAIN, {
          snackbarState: true,
          errorCode: `${error?.response?.data?.statusCode} - ${
            error?.response?.data?.error ? error?.response?.data?.error : API_ERROR_MESSAGES.DEFAULT
          }`,
          errorMessage: error?.response?.data?.message
        });
      }
    }
    return Promise.reject(error);
  }
);
export default Api;
