import React, { useEffect, useState, useCallback, useLayoutEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import OutlineDownArrow from '../../images/icons/outlineDownArrow.svg';
import OutlineUpArrow from '../../images/icons/outlineUpArrow.svg';
import FooterLogo from '../../images/icons/footer-statrys-logo.svg';
import StatrysSupport from '../common/statrysSupport/StatrysSupport';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { navigate } from 'gatsby';
import {
  API_ERROR_CODES,
  MOBILE_APP_EVENTS,
  MUI_COLORS,
  REDIRECTION_SOURCE,
  REDIRECT_SCREEN_INFO,
  SESSION_STORAGE_NAME,
  SSO_SITE_AUTHENTICATION_URL,
  SSO_SITE_LOGIN_URL,
  STRING_MAX_CHAR,
  SVG_TYPES,
  USER_APP_ACCESS_STATUS,
  USER_APP_IDENTIFIER
} from '../../constants/enum';
import { PATH_PAGE, IMPORTING_INVOICE } from '../../routes/paths';
import { getSecondLastPathName, getURLFirstPathName, isBrowser, stringTruncate } from '../../helper/helpers';
import MSText from '../ui/typography/MSText';
import { useLocation } from '@reach/router';
import { clearStorage, getUser, isLoggedIn, logOut } from '../../components/authService/auth';
import { connect, useSelector } from 'react-redux';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { getSvgIcon } from '../../helper/svgHelper';
import BaseText from '../ui/typography/BaseText';
import NavigationDropDown from '../Navigation/NavigationDropDown';
import XSText from '../ui/typography/XSText';
import CompanyManagementIcon from '../../images/icons/header/company-management.svg';
import Buildings from '../../images/icons/sideBar/buildings.svg';
import Hierarchy from '../../images/icons/sideBar/hierarchy.svg';
import Mailbox from '../../images/icons/sideBar/mailbox.svg';
import BookLibrary from '../../images/icons/sideBar/book-library.svg';
import ActiveBuildings from '../../images/icons/sideBar/buildings-green.svg';
import ActiveHierarchy from '../../images/icons/sideBar/hierarchy-green.svg';
import ActiveMailbox from '../../images/icons/sideBar/mailbox-green.svg';
import ActiveBookLibrary from '../../images/icons/sideBar/book-library-green.svg';
import Avatar from '../ui/Avatar';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import Banner from '../../components/common/Banner';
import useIsMobile from '../../../utils/isMobile';
import useIsTablet from '../../../utils/useIsTablet';
import BottomNavigation from '../Navigation/MobileNavigation';
import NavigationDropDownLeft from '../Navigation/NavigationDropDownLeft';
import { IS_SSO_ON } from '../../constants/enum';
import { setUser } from '../../components/authService/auth';
import { is } from 'date-fns/locale';
const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const companyNavigation = [
  {
    id: 1,
    pageName: 'Company Profile',
    icon: Buildings,
    activeIcon: ActiveBuildings,
    to: PATH_PAGE.companyProfile
  },
  {
    id: 2,
    pageName: 'Organization',
    icon: Hierarchy,
    activeIcon: ActiveHierarchy,
    to: PATH_PAGE.companyOrganization
  },
  {
    id: 3,
    pageName: 'Mailroom',
    icon: Mailbox,
    activeIcon: ActiveMailbox,
    to: PATH_PAGE.companyMailRoom
  },
  {
    id: 4,
    pageName: 'Documents',
    icon: BookLibrary,
    activeIcon: ActiveBookLibrary,
    to: PATH_PAGE.companyDocuments
  }
];

const profileOptions = [
  {
    pageName: 'My profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    to: PATH_PAGE.companyProfileSetting,
    id: 2,
    onMenuItemClick: () => {
      if (IS_SSO_ON) {
        const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
        const userCode = sessionStorage.getItem(SESSION_STORAGE_NAME.USER_CODE);
        window.open(
          SSO_SITE_AUTHENTICATION_URL +
            `?token=${token}&source=${REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP}&identifierCode=${userCode}&redirectTo=${REDIRECT_SCREEN_INFO.MY_PROFILE}`,
          '_self'
        );
      } else {
        navigate(PATH_PAGE.companyProfileSetting);
      }
    }
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    onMenuItemClick: () => {
      clearStorage();
      if (sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE === REDIRECTION_SOURCE.MOBILE_APP)) {
        const data = {
          event: MOBILE_APP_EVENTS.USER_LOGOUT,
          data: null
        };
        isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
        return;
      }
      navigate(PATH_PAGE.login);
    },
    id: 4
  }
];

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

function NavigationBar({
  children,
  getProfile,
  getUserProfile,
  getCompanyDetails,
  noSideBar,
  hideBanner,
  isRenewalSuccessPage,
  getInvoiceCompanies,
  getBusinessAccounts,
  getIncorporationCompanies,
  getUserProfileByCode,
  getApplicationAccessList
}) {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(true);
  const [profileData, setProfileData] = useState({});
  const user = getUser();
  const location = useLocation();
  const [allCompanies, setAllCompanies] = useState([]);
  const allInvoiceCompanies = useSelector((state) => state?.companyIncorporationReducer?.getInvoiceData);
  const allBusinessAccounts = useSelector((state) => state?.companyIncorporationReducer?.businessAccountData);
  const allIncorporationCompanies = useSelector((state) => state?.companyIncorporationReducer?.incorporationCompanies);
  const applicationAccessList = useSelector((state) => state?.companyIncorporationReducer?.applicationAccessList);
  const [isLeftDropDownOpen, setIsLeftDropDownOpen] = useState(false);
  const [isRightDropDownOpen, setIsRightDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const rightDropdownRef = useRef(null);
  const [isChildElementHovered, setIsChildElementHovered] = useState(false);
  const [loadingInvoiceAndBusinessAccounts, setLoadingInvoiceAndBusinessAccounts] = useState(false);

  const [companyDetails, setCompanyDetails] = useState({});
  const [bannerVisible, setIsBannerVisible] = useState(false);
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );

  const navigationMenu = companyNavigation;

  const handleClick = (value) => {
    navigate(value);
  };

  const handleActiveTab = (urlLink) => {
    const urlFirstPathName = getURLFirstPathName(location?.pathname);
    const linkFirstPath = getURLFirstPathName(urlLink);
    if (`/${urlFirstPathName}` === PATH_PAGE.company) {
      const urlSecondPathName = getSecondLastPathName(location?.pathname);
      const linkSecondPath = getSecondLastPathName(urlLink);
      if (urlSecondPathName === linkSecondPath) {
        return true;
      } else {
        return false;
      }
    } else {
      if (urlFirstPathName === linkFirstPath) {
        return true;
      } else if (
        (`/${urlFirstPathName}` === PATH_PAGE.viewIncomingInvoices || `/${urlFirstPathName}` === IMPORTING_INVOICE) &&
        urlLink === PATH_PAGE.pay
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    if (!IS_SSO_ON) {
      getUserProfileInfo();
      getCompanyDetailsHandler();
    } else {
      getUserProfileByCode();
    }
  }, [isRenewalSuccessPage]);

  useLayoutEffect(() => {
    const bannerState = sessionStorage.getItem(SESSION_STORAGE_NAME.IS_BANNER_HIDDEN);
    setIsBannerVisible(null);
  }, []);

  const getCompanyDetailsHandler = useCallback(async () => {
    const companyId = sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID);
    if (companyId) {
      const loggedInCompanyDetails = await getCompanyDetails(companyId);
      setCompanyDetails(loggedInCompanyDetails?.data?.data);
    }
    const allCompanies = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME?.ALL_COMPANIES));
    setAllCompanies(allCompanies?.companies);
  }, [allCompanies]);

  const getUserProfileInfo = async () => {
    const response = await getUserProfile();
  };

  useEffect(() => {
    setProfileData(getProfile);
  }, [getProfile]);
  const firstName = profileData?.firstName ? profileData?.firstName : companyDetails?.stakeholder?.[0]?.firstName;
  const lastName = profileData?.firstName ? profileData?.lastName : companyDetails?.stakeholder?.[0]?.lastName;
  const fullName = firstName + ' ' + lastName;
  const avatarName = fullName || companyAllInformation?.companyName;

  const navBannerMarginHandler = () => {
    if (hideBanner || !bannerVisible) {
      return false;
    } else {
      return true;
    }
  };

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const IS_REDIRECTION_SOURCE_MOBILE_APP =
    isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP;

  async function fetchInvoiceCompanyIncorporationAndBusinessAccounts() {
    try {
      const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
      setLoadingInvoiceAndBusinessAccounts(true);

      const response = await getApplicationAccessList();
      const applicationAccessList = response.data;

      const hasAccess = (appCode) =>
        applicationAccessList.some(
          (app) => app.applicationCode === appCode && app.status === USER_APP_ACCESS_STATUS.APPROVED
        );

      if (hasAccess(USER_APP_IDENTIFIER.COMPANY_MANAGEMENT_APP)) await getIncorporationCompanies(token);
    } catch (err) {
      console.warn('Error:', err);
      if (err?.response?.status === API_ERROR_CODES.STATUS_401) logOut();
    } finally {
      setLoadingInvoiceAndBusinessAccounts(false);
    }
  }
  useEffect(() => {
    if (IS_SSO_ON) {
      fetchInvoiceCompanyIncorporationAndBusinessAccounts();
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsLeftDropDownOpen(false);
      }
    }

    function handleClickOutsideOfRightDropDown(event) {
      if (rightDropdownRef.current && !rightDropdownRef.current.contains(event.target)) {
        setIsRightDropDownOpen(false);
      }
    }

    if (isLeftDropDownOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    if (isRightDropDownOpen) {
      document.addEventListener('click', handleClickOutsideOfRightDropDown);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('click', handleClickOutsideOfRightDropDown);
    };
  }, [isLeftDropDownOpen, isRightDropDownOpen]);

  function formatName(firstName, lastName) {
    const capitalize = (name) => (name ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() : '');
    return `${capitalize(firstName) && capitalize(firstName)} ${capitalize(lastName) && capitalize(lastName)}`.trim();
  }
  return (
    <>
      <Box id="main-container" sx={{ display: 'flex' }} className="max-w-screen-2xl mx-auto">
        <>
          <AppBar
            open={open}
            position="fixed"
            sx={{
              marginBottom: navBannerMarginHandler() && '56px',
              backgroundColor: 'white',
              boxShadow: 'none',
              '& .MuiToolbar-root': {
                paddingRight: !isTablet && !isMobile ? '40px' : isTablet ? '16px' : '8px',
                paddingLeft: !isTablet && !isMobile ? '40px' : isTablet ? '16px' : '8px',
                minHeight: '66px',
                maxHeight: '66px'
              }
            }}
          >
            {!hideBanner && <Banner setIsBannerVisible={setIsBannerVisible} />}
            {isBrowser() && IS_REDIRECTION_SOURCE_MOBILE_APP ? null : (
              <Toolbar className="max-w-screen-2xl w-full mx-auto main-header">
                <div style={{ flexGrow: '1' }}>
                  <div
                    className="flex justify-start gap-2 items-center hover:cursor-pointer"
                    onClick={() => setIsLeftDropDownOpen((prev) => !prev)}
                    ref={dropdownRef}
                  >
                    <img src={CompanyManagementIcon} alt="company management" />
                    <div>
                      {IS_SSO_ON ? (
                        <p className="md:whitespace-normal text-xl whitespace-pre-line text-bold text-gray-300">
                          {`Company \nManagement`}{' '}
                        </p>
                      ) : (
                        <BaseText
                          fontSize="text-xl"
                          className="md:whitespace-normal whitespace-pre-line text-bold"
                          title={`Company \nManagement`}
                        />
                      )}
                    </div>
                    {IS_SSO_ON && (
                      <>
                        {getSvgIcon(SVG_TYPES.ARROW_DOWN_BOLD)}
                        <NavigationDropDownLeft
                          menuItems={profileOptions}
                          isNavigation={true}
                          needSeparator={true}
                          separtorPositions={[1]}
                          className={'dropdown-content-container-left'}
                          allBusinessAccounts={allBusinessAccounts}
                          allInvoiceCompanies={allInvoiceCompanies}
                          allIncorporationCompanies={allIncorporationCompanies}
                          loadingInvoiceAndBusinessAccounts={loadingInvoiceAndBusinessAccounts}
                          isLeftDropDownOpen={isLeftDropDownOpen}
                          setIsLeftDropDownOpen={setIsLeftDropDownOpen}
                        ></NavigationDropDownLeft>
                      </>
                    )}
                  </div>
                </div>
                {/* Right Dropdown */}
                <div
                  className={`flex gap-8 
                  ${
                    IS_SSO_ON && !isChildElementHovered ? 'md:px-2 rounded-lg cursor-pointer md:hover:bg-coral-300' : ''
                  }
                  ${IS_SSO_ON && isChildElementHovered ? 'md:px-2 rounded-lg cursor-pointer hover:bg-transparent' : ''}
                  `}
                >
                  <div
                    className={`flex items-center item-menu-dropdown ${IS_SSO_ON ? '' : 'dropdown'}`}
                    ref={rightDropdownRef}
                    onClick={
                      IS_SSO_ON
                        ? () => {
                            setIsRightDropDownOpen((prev) => !prev);
                          }
                        : () => {}
                    }
                  >
                    <div className="hidden md:flex flex-row gap-2 items-center cursor-pointer py-2">
                      <div className="flex gap-2 items-center">
                        {IS_SSO_ON && (
                          <Avatar
                            avatarStyling="w-8 h-8 block p-2"
                            fontSize="text-base"
                            avatarName={(user?.firstName?.charAt(0) || '') + (user?.lastName?.charAt(0) || '')}
                          />
                        )}

                        <div className="flex flex-col">
                          {IS_SSO_ON && (
                            <MSText
                              title={
                                isBrowser() &&
                                stringTruncate(formatName(user?.firstName, user?.lastName), STRING_MAX_CHAR.TWENTY_NINE)
                              }
                              textColor={'text-gray-500'}
                              fontWeight="text-bold"
                            />
                          )}
                          {!IS_SSO_ON && companyAllInformation?.companyName && (
                            <MSText
                              title={
                                isBrowser() &&
                                stringTruncate(
                                  sessionStorage.getItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED) ||
                                    allCompanies?.length === 1
                                    ? companyAllInformation?.companyName
                                    : fullName,
                                  STRING_MAX_CHAR.TWENTY_NINE
                                )
                              }
                              fontWeight="text-bold"
                            />
                          )}
                          <MSText
                            title={stringTruncate(user?.email, STRING_MAX_CHAR.TWENTY_NINE)}
                            className="menu-item-text"
                            textColor={'text-gray-500'}
                          />
                        </div>
                      </div>

                      {!IS_SSO_ON && <img src={OutlineDownArrow} alt="OutlineDownArrow" />}
                      {IS_SSO_ON ? (
                        isRightDropDownOpen ? (
                          <img src={OutlineUpArrow} alt="OutlineUpArrow" />
                        ) : (
                          <img src={OutlineDownArrow} alt="OutlineDownArrow" />
                        )
                      ) : null}
                    </div>

                    <Avatar
                      avatarStyling="w-10 h-10 md:hidden block mr-4"
                      fontSize="text-base"
                      avatarName={
                        IS_SSO_ON
                          ? (user?.firstName?.charAt(0) || '') + (user?.lastName?.charAt(0) || '')
                          : avatarName?.charAt(0)
                      }
                    />

                    <NavigationDropDown
                      menuItems={profileOptions}
                      isNavigation
                      companyList={allCompanies}
                      needSeparator
                      separtorPositions={[1]}
                      isRightDropDownOpen={isRightDropDownOpen}
                      setIsChildElementHovered={setIsChildElementHovered}
                      setIsLeftDropDownOpen={setIsLeftDropDownOpen}
                    >
                      {!IS_SSO_ON && (
                        <div className="mt-4 px-4">
                          <XSText
                            title={
                              profileData?.firstName && profileData?.lastName
                                ? stringTruncate(fullName, STRING_MAX_CHAR.THIRTY_FIVE)
                                : ''
                            }
                            fontWeight="text-bold"
                            lineHeight="leading-6"
                          />
                          <MSText
                            title={stringTruncate(user?.email, STRING_MAX_CHAR.THIRTY_FIVE)}
                            textColor="text-gray-450"
                            className="dropDown-email-text whitespace-nowrap overflow-hidden"
                          />
                        </div>
                      )}
                    </NavigationDropDown>
                  </div>
                </div>
              </Toolbar>
            )}
          </AppBar>
          {!noSideBar && !isMobile && (
            <Drawer
              variant="permanent"
              open={!isMobile && !isTablet}
              sx={{
                '& .MuiDrawer-paper': {
                  position: 'relative',
                  zIndex: '100',
                  backgroundColor: MUI_COLORS.LIGHT_GRAY,
                  borderRight: 'none',
                  paddingLeft: '32px',
                  paddingRight: '32px',
                  marginTop: navBannerMarginHandler() && '56px',
                  height: navBannerMarginHandler() ? '90%' : '100%'
                }
              }}
            >
              <div className="flex flex-col justify-between h-full mt-20">
                <div>
                  <List>
                    {navigationMenu.map((navigate) => (
                      <ListItem key={navigate.id} disablePadding sx={{ display: 'block', marginBottom: '4px' }}>
                        <ListItemButton
                          sx={{
                            minHeight: 56,
                            justifyContent: 'center',
                            px: 2.5,
                            '&:hover': { backgroundColor: !isMobile && !isTablet && 'white' }
                          }}
                          className={`${
                            handleActiveTab(navigate?.to) && !isMobile && !isTablet && 'bg-white'
                          } rounded-lg`}
                          onClick={() => handleClick(navigate?.to)}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 3 : 'auto',
                              justifyContent: 'center'
                            }}
                            className={`${
                              handleActiveTab(navigate?.to) && !isMobile && !isTablet && 'bg-white'
                            } rounded-lg`}
                            onClick={() => handleClick(navigate?.to)}
                          >
                            <img
                              className={`${handleActiveTab(navigate?.to) && 'text-green-500'}`}
                              src={handleActiveTab(navigate?.to) ? navigate?.activeIcon : navigate?.icon}
                              alt={navigate?.pageName}
                            />
                          </ListItemIcon>
                          <ListItemText
                            className={`${handleActiveTab(navigate?.to) && 'text-gray-500 font-bold'}`}
                            sx={{
                              opacity: open ? 1 : 0,
                              '& .MuiTypography-root': {
                                fontWeight: handleActiveTab(navigate?.to) && 700
                              }
                            }}
                          >
                            {navigate.pageName}
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </div>

                <footer className="pb-5 fixed-footer left-[54px] 2xl:left-auto">
                  <div className="flex justify-center">
                    <img src={FooterLogo} alt={FooterLogo} />
                  </div>
                  <StatrysSupport />
                </footer>
              </div>
            </Drawer>
          )}
          {!noSideBar && isMobile && <BottomNavigation />}
        </>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            backgroundColor: MUI_COLORS.LIGHT_GRAY,
            height: '90vh',
            marginTop: navBannerMarginHandler() && '56px'
          }}
        >
          <div className="layout-container pt-4 mx-auto min-h-full grid place-items-center bg-customBg-secondaryBg pb-8">
            <main
              className={`
                 ${
                   !isLoggedIn
                     ? 'py-0'
                     : isTablet
                     ? IS_REDIRECTION_SOURCE_MOBILE_APP
                       ? ''
                       : 'py-24'
                     : isMobile
                     ? IS_REDIRECTION_SOURCE_MOBILE_APP
                       ? 'mt-6 py-6'
                       : ''
                     : 'py-14'
                 }
                  h-full w-full flex 
                  ${noSideBar ? 'items-center' : 'items-start'} justify-center 
                  ${navBannerMarginHandler() ? 'md:py-14 pt-32' : ''} 
                  ${IS_REDIRECTION_SOURCE_MOBILE_APP ? 'mb-20 pt-10' : ''}
              `}
            >
              {children}
            </main>
          </div>
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getUserProfileByCode: () => dispatch(userProfileActions.getUserProfileByCode()),
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId)),
    getInvoiceCompanies: () => dispatch(CompanyAction.getInvoice()),
    getBusinessAccounts: (token) => dispatch(CompanyAction.getBusinessAccounts(token)),
    getIncorporationCompanies: () => dispatch(CompanyAction.getIncorporationCompanies()),
    getApplicationAccessList: () => dispatch(CompanyAction.getApplicationAccessList())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
