import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

export function IncorrectPage() {
  return (
    <div className="flex w-full justify-center invalid-link-container">
      <div className="flex items-center invalid-link-container">
        <div className="w-1/2 flex img-container items-center justify-end px-12">
          <div>
            <div className="div-img mx-auto">
              <StaticImage src="../../../images/graphics/reviewing-eidv.png" alt="Statrys platform" />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex items-center justify-start invalid-link-description">
          <div>
            <MDSubtitleText
              title={'Oops! The page you’re looking for can’t be found'}
              fontWeight="text-bold"
              className="whitespace-pre-line main-text invalid-link-text"
            ></MDSubtitleText>

            <div className="flex justify-center md:justify-start">
              <XSText lineHeight="leading-5" className="mt-4" title={`Having issues?`} />
              &nbsp;
              <Link to={process.env.MARKETING_SITE_URL + '/contact'} className="mt-4">
                <XSText lineHeight="leading-5" textColor="text-coral-500" title="Contact us" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
