import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import RegistrationSuccessPage from '../components/companyIncorporation/companyLastStep/RegistrationSuccessPage';
import PublicHeaderNavigation from '../components/Navigation/PublicHeaderNavigation';
import * as CompanyAction from '../components/companyIncorporation/reduxStore/action';
import NavigationBar from '../components/sideBar/NavigationBar';
import { getUser, isLoggedIn } from '../components/authService/auth';
import { IS_SSO_ON, SESSION_STORAGE_NAME } from '../constants/enum';
import { getUserCompanies, isBrowser } from '../helper/helpers';

function CompanyIncorporationSuccess({ getCompanyDetails, companyDetails, getIncorporationCompanies }) {
  const user = getUser();
  async function companyInformation() {
    await getCompanyDetails(sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID));
  }

  async function refreshCompanyDetails() {
    await getUserCompanies(
      sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN),
      user?.email || companyDetails?.data?.data?.email
    );
  }
  useEffect(() => {
    companyInformation();
    if (Object.keys(user).length !== 0 || companyDetails?.data?.data?.email) {
      if (!IS_SSO_ON) {
        refreshCompanyDetails();
      } else {
        getIncorporationCompanies();
      }
    }
  }, [isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID)]);
  const MainPage = () => {
    return (
      <div className="bg-white rounded-lg md:max-h-[560px] z-40">
        <RegistrationSuccessPage />
      </div>
    );
  };

  return (
    <div className="w-full bg-customBg-secondaryBg h-[100dvh]">
      {isLoggedIn() &&
      typeof window !== 'undefined' &&
      (sessionStorage.getItem(SESSION_STORAGE_NAME.ALL_COMPANIES) !== null || IS_SSO_ON) ? (
        <NavigationBar noSideBar={true} children={<MainPage />} />
      ) : (
        <div className="max-w-7xl layout-container pt-4 mx-auto">
          <PublicHeaderNavigation hideSignInButton={true} />
          <main className="flex h-[88dvh] justify-center items-center">
            <MainPage />
          </main>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    companyDetails: state?.companyIncorporationReducer?.getCompanyIncorporation
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId)),
    getIncorporationCompanies: () => dispatch(CompanyAction.getIncorporationCompanies())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyIncorporationSuccess);
