import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import '../../../styles/verifyOtpInput.scss';
import ValidationMessage from '../../ui/ValidationMessage';
import {
  MUI_COLORS,
  REQUIRED_LENGTH,
  API_CODE,
  IS_SSO_ON,
  SESSION_STORAGE_NAME,
  REDIRECTION_SOURCE
} from '../../../constants/enum';
import { ENDPOINTS } from '../../../service/services';

import axios from 'axios';
import { BASE_API_URL } from '../../../constants/enum';
import * as LoginActions from '../../login/reduxStore/action';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setCompleteAuthInSessionStorage, setTokenInSessionStorage } from '../../../helper/helpers';
const Otp = ({ nextStep, resendOtpClicked, userPassword, updateSSOToken }) => {
  const [otp, setOtp] = useState('');
  const [error, setOtpError] = useState('');

  const handleChange = async (otpValue) => {
    setOtpError(false);
    setOtp(otpValue);
    if (otpValue.length === REQUIRED_LENGTH.OTP_LENGTH) {
      let payload = {
        email: sessionStorage.getItem(SESSION_STORAGE_NAME.EMAIL).replace(/ /g, '+'),
        otp: otpValue,
        type: resendOtpClicked ? 3 : 1
      };
      if (IS_SSO_ON) {
        payload.password = userPassword;
      }
      try {
        const otpResponse = await axios.put(
          `${BASE_API_URL}${
            IS_SSO_ON ? ENDPOINTS.COMPANY_VERIFY_INCORPORATION_OTP_V2 : ENDPOINTS.COMPANY_VERIFY_INCORPORATION_OTP
          }`,
          payload
        );
        if (otpResponse?.status === API_CODE?.STATUS_200) {
          if (otpResponse?.data?.data?.token) {
            if (IS_SSO_ON) {
              const token = otpResponse?.data?.data?.token;
              const companyId = otpResponse?.data?.data?.companyId;
              const updatedTokenResponse = await updateSSOToken(
                {
                  companyId
                },
                token
              );
              const completeAuthInfo = JSON.stringify(updatedTokenResponse?.data?.data);
              setTokenInSessionStorage(updatedTokenResponse?.data?.data?.token);
              setCompleteAuthInSessionStorage(completeAuthInfo);

              // set user code for business accounts api.
              const userCode = updatedTokenResponse?.data?.data?.userCode;
              sessionStorage.setItem(SESSION_STORAGE_NAME.USER_CODE, userCode);
              nextStep();
            } else {
              const completeAuthInfo = JSON.stringify(otpResponse?.data?.data);
              setTokenInSessionStorage(otpResponse?.data?.data?.token);
              setCompleteAuthInSessionStorage(completeAuthInfo);
              nextStep();
            }
          }
        } else {
          setOtpError(otpResponse?.data?.message);
        }
      } catch (error) {
        setOtpError(error?.response?.data?.message);
      }
    }
  };

  const handleClickCapture = () => {
    if (error) {
      setOtp('');
      setOtpError('');
    }
  };
  return (
    <div onClickCapture={handleClickCapture}>
      <OtpInput
        value={otp}
        onChange={handleChange}
        numInputs={6}
        isInputNum={true}
        shouldAutoFocus={true}
        separator={<span className="ml-2"></span>}
        inputStyle={error ? 'otp-input-error' : 'otp-input'}
        focusStyle={{
          border: error ? '1px solid #FF4F42' : '1px solid #2D0D45',
          background: error ? MUI_COLORS.LIGHT_CREAM : MUI_COLORS.WHITE,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          outline: 'none'
        }}
      />
      {error && <ValidationMessage title={error} />}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSSOToken: (updateTokenPayload, token) => dispatch(LoginActions.updateSSOToken(updateTokenPayload, token))
  };
};

export default connect(null, mapDispatchToProps)(Otp);

Otp.propTypes = {
  updateSSOToken: PropTypes.func
};
