import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as CompanyDetailsActions from '../../components/company/reduxStore/action';
import { API_CODE, COMPANY_STATUS, IS_SSO_ON, PAGE_TITLE } from '../../constants/enum';
import Page from '../../components/Page';
import Layout from '../../components/layout';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Organization from '../../components/company/organization/Organization';
import StatrysLoader from '../../components/ui/loaders/StatrysLoader';
import useIsMobile from '../../../utils/isMobile';

function CompanyOrganization({ companyStackHoldersDetails, companyProfileDetails }) {
  const [stackHolderDetails, setStackHolderDetails] = useState([]);
  const [profileDetails, setProfileDetails] = useState([]);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isStackHolderLoading, setIsStackHolderLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const companyRegistartion = async () => {
    setIsProfileLoading(true);
    setIsLoading(true);
    const companyProfileResponse = await companyProfileDetails();
    if (companyProfileResponse?.status === API_CODE.STATUS_200) {
      setProfileDetails(companyProfileResponse?.data?.data);
      setIsProfileLoading(false);
      setIsLoading(false);
    }
  };

  const companyStackHolder = async () => {
    setIsStackHolderLoading(true);
    const companyStackHolderResponse = await companyStackHoldersDetails();
    if (companyStackHolderResponse?.status === API_CODE.STATUS_200) {
      setStackHolderDetails(companyStackHolderResponse?.data?.data[0]?.stakeholder);
      setIsStackHolderLoading(false);
    }
  };

  useEffect(() => {
    companyStackHolder();
    companyRegistartion();
  }, []);

  const isMobile = useIsMobile();

  return (
    <Layout>
      <Page title="Company" className="w-screen sm:w-full">
        {!isMobile && (
          <HeaderBreadcrumbs
            className={'pl-2'}
            heading={IS_SSO_ON ? PAGE_TITLE.COMPANY_MANAGEMENT_STATRYS : PAGE_TITLE.COMPANY_ORGANIZATION}
            links={[{ name: '' }]}
            sx={{ height: '68px', display: 'flex', alignItems: 'center', marginBottom: '32px' }}
          />
        )}

        {isLoading ? (
          <div className="mt-10 min-h-[50vh] flex justify-center items-center">
            <StatrysLoader />
          </div>
        ) : (
          <div className="w-full">
            <Organization
              profileDetails={profileDetails}
              stackHolderDetails={stackHolderDetails}
              isStackHolderLoading={isStackHolderLoading}
              isLoading={isProfileLoading}
            />
          </div>
        )}
      </Page>
    </Layout>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    companyStackHoldersDetails: () => dispatch(CompanyDetailsActions.companyStackHoldersDetails()),
    companyProfileDetails: () => dispatch(CompanyDetailsActions.companyProfileDetails())
  };
};

export default connect(null, mapDispatchToProps)(CompanyOrganization);

CompanyOrganization.propTypes = {
  companyStackHoldersDetails: PropTypes.func,
  companyProfileDetails: PropTypes.func
};
