import React, { useEffect } from 'react';
import { Onfido } from 'onfido-sdk-ui';
import '../../../styles/pages/id-verification/idVerification.scss';
import '../../../styles/pages/id-verification/InvalidLink.scss';
import { InvalidLink } from './invalid-link.js';
import { IncorrectPage } from './incorrect-page.js';
import StatrysLogo from '../../../../static/statrys-logo.svg';
import { onfidoConfig, onfidoErrorCodes, onfidoLanguagesConfig } from './id-verification.constant.js';
import * as idVerificationActions from './reduxStore/action.js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import CompanyIncorporationSuccess from '../../../pages/company-incorporation-success.js';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../../routes/paths.js';
import { getQueryParameter } from '../../../helper/helpers.js';
import StatrysLucky from '../../ui/loaders/StatrysLucky.js';
import SucessPage from '../SucessPage.js';
import PublicHeaderNavigation from '../../../components/Navigation/PublicHeaderNavigation';
import { isLoggedIn } from '../../authService/auth.js';

function IdVerification({ verificationInformation, verificationInformationError, fetchVerificationInformation }) {
  const location = useLocation();
  const [isValidToken, setIsValidToken] = React.useState(null);
  const [tokenError, setTokenError] = React.useState(null);
  const [showSkip, setShowSkip] = React.useState(true);
  const [isVerificationComplete, setVerificationComplete] = React.useState(false);

  function renderIdVerificationPage(sdkToken, workflowRunId) {
    Onfido.init({
      token: sdkToken,
      containerId: 'IDVerificationContainer',
      workflowRunId: workflowRunId,
      ...onfidoConfig,
      ...onfidoLanguagesConfig,
      onComplete() {
        setVerificationComplete(true);
      }
    });
  }

  const listenerOnFido = (event) => {
    setShowSkip(
      !!event?.detail?.eventName && ['CROSS_DEVICE_GET_LINK', 'CROSS_DEVICE_INTRO'].includes(event?.detail?.eventName)
    );
  };

  useEffect(() => {
    window.addEventListener('userAnalyticsEvent', listenerOnFido);
    return () => {
      window.removeEventListener('userAnalyticsEvent', listenerOnFido);
    };
  });

  useEffect(() => {
    if (verificationInformation?.token) {
      setIsValidToken(true);
    }
  }, [verificationInformation]);
  useEffect(() => {
    if (verificationInformationError) {
      setTokenError(verificationInformationError);
      setIsValidToken(false);
    }
  }, [verificationInformationError]);

  useEffect(() => {
    if (isValidToken) {
      renderIdVerificationPage(verificationInformation.token, verificationInformation.workflowRunId);
    }
  }, [isValidToken]);
  const initVerificationPage = () => {
    const workFlowCode = getQueryParameter(location, 'workFlowCode');
    const token = getQueryParameter(location, 'token');

    if (!workFlowCode || !token) {
      setTokenError({
        code: onfidoErrorCodes.A_TECHNICAL_ERROR_OCCURRED
      });
      setIsValidToken(false);
      return;
    }
    invalidLinkRenderer();

    fetchVerificationInformation({
      workFlowCode,
      token
    });
  };
  useEffect(() => {
    initVerificationPage();
  }, [location]);

  const invalidLinkRenderer = () => {
    if (!isValidToken && tokenError) {
      switch (tokenError.code) {
        case onfidoErrorCodes.VERIFICATION_IN_PROCESS:
          return <InvalidLink />;
        case onfidoErrorCodes.VERIFICATION_LINK_EXPIRED:
        case onfidoErrorCodes.A_TECHNICAL_ERROR_OCCURRED:
        default:
          return <IncorrectPage />;
      }
    }

    return null;
  };

  const skipIdentify = () => {
    navigate(isLoggedIn() ? PATH_PAGE.companyIncorporationSuccess : PATH_PAGE.sucessPage);
  };

  return isValidToken === null ? (
    <div className="flex justify-center items-center h-screen">
      <StatrysLucky />
    </div>
  ) : isValidToken ? (
    <div className="onfido-main-container">
      <div className="onfido-process-start-container">
        <div className="statrys-logo flex items-center">
          <img src={StatrysLogo} alt="Statrys Logo" height="40" width="146" />
        </div>

        <div className="onfido-succes-page-container">
          {isVerificationComplete ? (
            <>
              {isLoggedIn() ? (
                <CompanyIncorporationSuccess />
              ) : (
                <main className="flex h-[80dvh] justify-center items-center">
                  <SucessPage />
                </main>
              )}
            </>
          ) : (
            <div>
              <div className="onfido-description-section">
                <div className="heading">Identity Verification</div>
                <div className="description">You are in the process of submitting your passport and selfie.</div>
              </div>

              <div>
                <div className="eidv-container" id="IDVerificationContainer"></div>
              </div>
              {showSkip && (
                <div className="ml-auto text-center my-4">
                  <a className="text-coral-500 text-xl cursor-pointer !no-underline" onClick={() => skipIdentify()}>
                    Skip the process
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    invalidLinkRenderer()
  );
}

const mapStateToProps = (state) => {
  return {
    verificationInformation: state?.identityVerification?.verificationInformation?.data,
    verificationInformationError: state?.identityVerification?.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVerificationInformation: (requestBody) =>
      dispatch(idVerificationActions.fetchVerificationInformation(requestBody))
  };
};

IdVerification.propTypes = {
  verificationInformation: PropTypes.object,
  verificationInformationError: PropTypes.object,
  fetchVerificationInformation: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(IdVerification);
