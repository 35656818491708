import React from 'react';
import XXSText from '../../ui/typography/XXSText';
import ManWithPassport from '../../../images/icons/confirm-identify-verification/man-with-passport-check.svg';
import StampOfStatrys from '../../../images/graphics/company-incorporation/stamp-of-statrys.png';
import XSText from '../../ui/typography/XSText';
import { useSelector } from 'react-redux';
import { OFFICE_ADDRESS, REGISTERED_COUNTRY } from '../../../constants/enum';
import BaseText from '../../ui/typography/BaseText';

const RegistrationSuccessPage = () => {
  const companyAllInformation = useSelector(
    (state) => state?.companyIncorporationReducer?.getCompanyIncorporation?.data?.data || {}
  );

  return (
    <div className="p-8">
      <div className="flex md:flex-row flex-col md:gap-12 gap-8 w-full items-center">
        <div className="md:w-1/2 w-full md:order-0 order-1">
          <BaseText fontSize="text-4xl" lineHeight="text-9" title="Great! We are done!" textWeight="text-medium" />
          <XXSText
            textColor="text-gray-500"
            title="We're ready to begin the process of registering your company."
            className="mt-4 mb-2"
          />
          <XXSText textColor="text-gray-500" className="md:mt-16 mt-2" title="Your new registered address:" />
          <div className="flex justify-between rounded-lg mt-4 border p-6">
            <div className="h-36 flex flex-col justify-end">
              <XSText fontWeight="text-bold" title={companyAllInformation?.companyName} />
              {companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.HONGKONG && (
                <XSText
                  textColor="text-gray-500 whitespace-pre-line"
                  title={
                    companyAllInformation?.registeredAddress
                      ? companyAllInformation?.registeredAddress
                      : OFFICE_ADDRESS.HONG_KONG_ADDRESS
                  }
                />
              )}
              {companyAllInformation?.registrationCountry === REGISTERED_COUNTRY.SINGAPORE && (
                <XSText
                  textColor="text-gray-500 whitespace-pre-line"
                  title={
                    companyAllInformation?.registeredAddress
                      ? companyAllInformation?.registeredAddress
                      : OFFICE_ADDRESS.SINGAPORE_ADDRESS
                  }
                />
              )}
            </div>
            <div>
              <img src={StampOfStatrys} alt="stamp image of statrys" width="107" height="90" />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-full flex justify-center company-success-page-container md:order-1 order-0">
          <img
            src={ManWithPassport}
            alt="let's start company"
            width="368"
            className="object-contain md:w-[320px] md:h-[368px] w-[160px] h-[153px]"
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationSuccessPage;
