import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';
import MarketingAPi from '../../../store/MarketingInterceptor/';
import { API_ERROR_CODES, SESSION_STORAGE_NAME, SSO_SITE_LOGIN_URL } from '../../../constants/enum';
import { modifyBusinessAccountData } from '../../../helper/helpers';
import axios from 'axios';
import { navigate } from 'gatsby';
import { logOut } from '../../authService/auth';

export const getCompanyIncorporationRequestStart = () => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_START
});
export const getCompanyIncorporationRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_FAIL,
  error: error
});
export const getCompanyIncorporationRequestSuccess = (getCompanyIncorporationResponse) => ({
  type: actionTypes.GET_COMPANY_INCORPORATION_REQUEST_SUCCESS,
  getCompanyIncorporationData: getCompanyIncorporationResponse
});
export const getCompanyIncorporation = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyIncorporationRequestStart());
      let getCompanyIncorporationResponse = await Api.get(
        `${ENDPOINTS.GET_COMPANY_SET_UP_DETAILS}?companyId=${companyId}`
      );
      dispatch(getCompanyIncorporationRequestSuccess(getCompanyIncorporationResponse));
      return getCompanyIncorporationResponse;
    } catch (error) {
      dispatch(getCompanyIncorporationRequestFail(error.response));
    }
  };
};

export const getIncorporationCompaniesStart = () => ({
  type: actionTypes.GET_INCORPORATION_COMPANIES_REQUEST_START
});
export const getIncorporationCompaniesRequestFail = (error) => ({
  type: actionTypes.GET_INCORPORATION_COMPANIES_REQUEST_FAIL,
  error: error
});
export const getIncorporationCompaniesRequestSuccess = (incorporationCompanies) => ({
  type: actionTypes.GET_INCORPORATION_COMPANIES_REQUEST_SUCCESS,
  incorporationCompanies: incorporationCompanies
});
export const getIncorporationCompanies = () => {
  return async (dispatch) => {
    try {
      dispatch(getIncorporationCompaniesStart());
      let incorporationCompaniesResponse = await Api.get(`${ENDPOINTS.INCORPORATION_COMPANIES}`);
      dispatch(getIncorporationCompaniesRequestSuccess(incorporationCompaniesResponse?.data?.data));
      return incorporationCompaniesResponse?.data?.data;
    } catch (error) {
      if (error?.response?.status === API_ERROR_CODES.STATUS_401) {
        logOut();
      }
      dispatch(getIncorporationCompaniesRequestFail(error.response));
    }
  };
};
export const getInvoiceRequestStart = () => ({
  type: actionTypes.GET_INVOICE_REQUEST_START
});
export const getInvoiceRequestFail = (error) => ({
  type: actionTypes.GET_INVOICE_REQUEST_FAIL,
  error: error
});
export const getInvoiceRequestSuccess = (getInvoiceResponse) => ({
  type: actionTypes.GET_INVOICE_REQUEST_SUCCESS,
  getInvoiceData: getInvoiceResponse
});
export const getInvoice = () => {
  return async (dispatch) => {
    try {
      dispatch(getInvoiceRequestStart());
      let getInvoiceResponse = await Api.get(`${ENDPOINTS.INVOICE_COMPANIES}`);
      dispatch(getInvoiceRequestSuccess(getInvoiceResponse?.data?.data));
      return getInvoiceResponse?.data?.data;
    } catch (error) {
      if (error?.response?.status === API_ERROR_CODES.STATUS_401) {
        logOut();
      }
      dispatch(getInvoiceRequestFail(error.response));
    }
  };
};

export const getBusinessAccountsRequestStart = () => ({
  type: actionTypes.GET_BUSINESS_ACCOUNT_REQUEST_START
});
export const getBusinessAccountsFail = (error) => ({
  type: actionTypes.GET_BUSINESS_ACCOUNT_REQUEST_FAIL,
  error: error
});
export const getBusinessAccountsSuccess = (businessAccountData) => ({
  type: actionTypes.GET_BUSINESS_ACCOUNT_REQUEST_SUCCESS,
  businessAccountData: businessAccountData
});
export const getBusinessAccounts = () => {
  return async (dispatch) => {
    try {
      dispatch(getBusinessAccountsRequestStart());
      const userCode = sessionStorage.getItem(SESSION_STORAGE_NAME.USER_CODE);
      const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);

      let API_URL = `client-management/v1/users/${userCode}/accounts`;
      const response = await MarketingAPi.get(API_URL, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const {
        businessAccounts = [],
        cardAccounts = [],
        prospectAccounts = [],
        invitationAccounts = [],
        cardApplicationAccounts = []
      } = response?.data;

      let allApplications = [
        ...businessAccounts,
        ...cardAccounts,
        ...prospectAccounts,
        ...invitationAccounts,
        ...cardApplicationAccounts
      ];
      allApplications = modifyBusinessAccountData(allApplications);
      dispatch(getBusinessAccountsSuccess(allApplications));
      return allApplications;
    } catch (error) {
      if (error?.response?.status === API_ERROR_CODES.STATUS_401) {
        logOut();
      }
      dispatch(getBusinessAccountsFail(error.response));
    }
  };
};

export const companyIncorporationRequestStart = () => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_START
});
export const companyIncorporationRequestFail = (error) => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_FAIL,
  error: error
});
export const companyIncorporationRequestSuccess = (companyIncorporationResponse) => ({
  type: actionTypes.COMPANY_INCORPORATION_REQUEST_SUCCESS,
  companyIncorporationData: companyIncorporationResponse
});
export const updateCompanyIncorporation = (companyIncorporationPayload) => {
  return async (dispatch) => {
    try {
      dispatch(companyIncorporationRequestStart());
      let companyIncorporationResponse = await Api.put(
        ENDPOINTS?.GET_COMPANY_SET_UP_UPDATE,
        companyIncorporationPayload
      );
      dispatch(companyIncorporationRequestSuccess(companyIncorporationResponse));
      return companyIncorporationResponse;
    } catch (error) {
      dispatch(companyIncorporationRequestFail(error.response));
    }
  };
};

export const uploadedPassportRequestStart = () => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_START
});
export const uploadedPassportRequestFail = (error) => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_FAIL,
  error: error
});
export const uploadedPassportRequestSuccess = (uploadedPassportResponse) => ({
  type: actionTypes.UPLOAD_PASSPORT_REQUEST_SUCCESS,
  uploadedPassportData: uploadedPassportResponse
});
export const uploadedPassport = (uploadedPassportPayload) => {
  return async (dispatch) => {
    try {
      dispatch(uploadedPassportRequestStart());
      let uploadedPassportResponse = await Api.post(ENDPOINTS.PASSPORT_UPLOAD, { code: uploadedPassportPayload });
      dispatch(uploadedPassportRequestSuccess(uploadedPassportResponse));
      return uploadedPassportResponse;
    } catch (error) {
      dispatch(uploadedPassportRequestFail(error.response));
      return error?.response;
    }
  };
};

export const switchCompanyRequestStart = () => ({ type: actionTypes.SWITCH_COMPANY_REQUEST_START });
export const switchCompanyRequestFail = (error) => ({ type: actionTypes.SWITCH_COMPANY_REQUEST_FAILURE, error: error });
export const switchCompanyRequestSuccess = (switchCompanyPayload) => ({
  type: actionTypes.SWITCH_COMPANY_REQUEST_SUCCESS,
  switchCompanyPayload: switchCompanyPayload
});
export const switchCompany = (switchCompanyPayload) => {
  return async (dispatch) => {
    try {
      dispatch(switchCompanyRequestStart());
      let switchCompanyResponse = await Api.post(ENDPOINTS.SWITCH_COMPANY, switchCompanyPayload);
      dispatch(switchCompanyRequestSuccess(switchCompanyResponse?.data));
      return switchCompanyResponse;
    } catch (error) {
      dispatch(switchCompanyRequestFail(error.response));
      return error?.response;
    }
  };
};

export const deleteStakholderRequestStart = () => ({ type: actionTypes.DELETE_STAKEHOLDER_REQUEST_START });
export const deleteStakholderRequestFail = (error) => ({
  type: actionTypes.DELETE_STAKEHOLDER_REQUEST_FAILURE,
  error: error
});
export const deleteStakholderRequestSuccess = (deleteStakholderPayload) => ({
  type: actionTypes.DELETE_STAKEHOLDER_REQUEST_SUCCESS,
  deleteStakholderData: deleteStakholderPayload
});
export const deleteStakholder = (deleteStakholderPayload) => {
  return async (dispatch) => {
    try {
      dispatch(deleteStakholderRequestStart());
      let deleteStakholderResponse = await Api.post(ENDPOINTS.DELETE_STAKEHOLDER, deleteStakholderPayload);
      dispatch(deleteStakholderRequestSuccess(deleteStakholderResponse?.data));
      return deleteStakholderResponse;
    } catch (error) {
      dispatch(deleteStakholderRequestFail(error.response));
      return error?.response;
    }
  };
};

export const updateStakholderRequestStart = () => ({
  type: actionTypes.UPDATE_STAKEHOLDER_REQUEST_START
});
export const updateStakholderRequestFail = (error) => ({
  type: actionTypes.UPDATE_STAKEHOLDER_REQUEST_FAILURE,
  error: error
});
export const updateStakholderRequestSuccess = (updateStakholderPayload) => ({
  type: actionTypes.UPDATE_STAKEHOLDER_REQUEST_SUCCESS,
  updateStakholderData: updateStakholderPayload
});
export const updateStakholder = (updateStakholderPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateStakholderRequestStart());
      let updateStakholderResponse = await Api.put(ENDPOINTS.UPDATE_STAKEHOLDER, updateStakholderPayload);
      dispatch(updateStakholderRequestSuccess(updateStakholderResponse?.data));
      return updateStakholderResponse;
    } catch (error) {
      dispatch(updateStakholderRequestFail(error.response));
      return error?.response;
    }
  };
};

export const applicationAccessListRequestStart = () => ({
  type: actionTypes.APPLICATION_ACCESS_REQUEST_START
});
export const applicationAccessListRequestFail = (error) => ({
  type: actionTypes.APPLICATION_ACCESS_REQUEST_FAIL,
  error: error
});
export const applicationAccessListRequestSuccess = (companyListDetails) => ({
  type: actionTypes.APPLICATION_ACCESS_REQUEST_SUCCESS,
  applicationAccessList: companyListDetails
});
export const getApplicationAccessList = () => {
  const userCode = sessionStorage.getItem(SESSION_STORAGE_NAME.USER_CODE);
  const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
  const BUSINESS_COMPANIES = `user-identity-management/v1/users/${userCode}/application-access`;
  return async (dispatch) => {
    try {
      dispatch(applicationAccessListRequestStart());
      let companyDetailsResponse = await MarketingAPi.get(`${BUSINESS_COMPANIES}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      dispatch(applicationAccessListRequestSuccess(companyDetailsResponse?.data));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(applicationAccessListRequestFail(error.response));
      throw error;
    }
  };
};
