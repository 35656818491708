import React, { useState, useEffect } from 'react';
import {
  FEES,
  REGEX,
  PAYMENT_METHOD,
  MUI_COLORS,
  COMPANY_REGISTRATION_COUNTRY,
  CONSTANT_NUMBER,
  API_CODE,
  CARD_ERROR,
  EXTERNAL_LINKS,
  SESSION_STORAGE_NAME,
  STATUS,
  IS_SSO_ON
} from '../../constants/enum.js';
import PropTypes from 'prop-types';
import Aes from '../../images/graphics/registerCompany/aes.png';
import Ssl from '../../images/graphics/registerCompany/ssl.png';
import Stripe from '../../images/graphics/registerCompany/stripe.png';
import XSText from '../ui/typography/XSText.js';
import TextInput from '../ui/inputs/TextInput';
import { FormControl } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../ui/ValidationMessage';
import CreditCardInline from '../../images/icons/inline/credit-card.inline.svg';
import PayPalInline from '../../images/icons/inline/pay-pal.inline.svg';
import '../../styles/pages/new-company-signup/submit.scss';
import { defaultValidationMessage, expriyDateFormatter, handleEnter } from '../../helper/helpers.js';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import TermCondition from '../../components/new-company-signup/checkout/TermCondition.js';
import { connect } from 'react-redux';
import StatrysLuckySpinner from '../../components/ui/loaders/StatrysLucky';
import MobileNumberAutoComplete from '../ui/inputs/MobileNumberAutoComplete.js';
import * as lpn from 'google-libphonenumber';
import DynamicModal from '../../components/ui/modal/DynamicModal';
import MSText from '../ui/typography/MSText';
import MDSubtitleText from '../ui/typography/MDSubtitleText.js';
import Visa from '../../images/graphics/paymentCardType/visa.png';
import MasterCard from '../../images/graphics/paymentCardType/masterCard.png';
import Lines from '../../images/graphics/paymentCardType/lines.png';
import Amex from '../../images/graphics/paymentCardType/amex.png';
import PaypalCheckoutButton from '../../components/new-company-signup/checkout/PayPalCheckout.js';
import CardErrorPNG from '../../images/graphics/white-card-error.png';
import * as userActions from '../../components/account-setup/reduxStore/action';
import CloseIcon from '../../images/icons/close-icon.svg';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import axios from 'axios';
import { isBrowser } from '../../helper/helpers.js';
import '../../../src/styles/licenseRenewal.scss';
import { ENDPOINTS } from '../../service/api-end-points.js';
import { BASE_API_URL } from '../../constants/enum.js';

let cardTypes = [
  {
    image: Visa,
    alt: 'visa'
  },
  {
    image: MasterCard,
    alt: 'MasterCard'
  },
  {
    image: Lines,
    alt: 'Lines'
  },
  {
    image: Amex,
    alt: 'Amex'
  }
];

function LicenseRenewal({ dialCodes, registrationCountry, setStep, companyDetails, userDetails, location }) {
  location = isBrowser() ? window?.location : {};
  const [formData, setFormData] = useState((isBrowser() && JSON?.parse(sessionStorage?.getItem('formData'))) || {});
  const { handleSubmit, register, errors, setValue, watch, reset } = useForm({
    defaultValues: formData,
    mode: 'onSubmit'
  });
  const [expirationMonth, setExpirationMonth] = useState();
  const [expirationYear, setExpirationYear] = useState();
  const [cardNumberValue, setCardNumberValue] = useState();
  const [cvcNumber, setCvcNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [payPalLoading, setPayPalLoading] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState({ countryError: false });
  const [cardsError, setCardsError] = useState({ cardNumber: false, expiration: false, cvc: false });
  const [emailError, setEmailError] = useState(false);

  const [emailJsErrorModal, setEmailJsErrorModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [emailJsResponse, setEmailJsResponse] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD?.CREDIT_CARD);
  const phoneUtil = lpn.PhoneNumberUtil.getInstance();
  const emailRegex = REGEX.EMAIL_REGEX;
  const expriration = (date) => {
    const expiryDateAndMonth = expriyDateFormatter(date);
    const [expirationMonth, expirationYear] = expiryDateAndMonth?.split('/');
    setExpirationMonth(expirationMonth);
    setExpirationYear(expirationYear);
    setValue('expiration', expriyDateFormatter(date));
  };

  const changeTextInput = (e) => {
    setCardsError((prevState) => {
      return {
        ...prevState,
        [e.target.name]: false
      };
    });
  };

  const onCardNumberChange = (event) => {
    changeTextInput(event);
    const trimmedCardNumber = event?.target?.value.replaceAll(REGEX.FIND_ALL_WHITESPACE, '');
    if (trimmedCardNumber.length < 17 && !trimmedCardNumber.match(REGEX.ONLY_STRING_REGEX)) {
      const result = trimmedCardNumber.toString().replace(REGEX.CARD_NUMBER_SPACE_REGEX, '$& ');
      setValue('cardNumber', result);
      setCardNumberValue(result);
    }
  };

  const onCvcChange = (event) => {
    changeTextInput(event);
    if (event?.target?.value.length < 4) {
      let cvcNumberRegex = event?.target?.value.replace(/\D/g, '');
      setCvcNumber(cvcNumberRegex);
      setValue('cvc', cvcNumberRegex);
    }
  };

  const onChangeExpiration = (e) => {
    expriration(e?.target?.value);
    changeTextInput(e);
  };

  let allCountriesCode = [];
  dialCodes?.forEach((each) => {
    let dialCodeObj = {};
    dialCodeObj['name'] = each.name;
    dialCodeObj['dialCode'] = each.dialCode;
    dialCodeObj['code'] = each.code;
    dialCodeObj['priority'] = each.priority;
    dialCodeObj['displayString'] = each.name + ' +' + each.dialCode;
    allCountriesCode.push(dialCodeObj);
  });

  function validationMobileNumber(phoneNumber) {
    try {
      if (phoneNumber) {
        const number = phoneUtil.parse(phoneNumber, '');
        const isValid = phoneUtil.isValidNumber(number);
        if (!isValid) {
          setMobileNumberError({ countryError: true });
          return false;
        } else {
          setMobileNumberError({ countryError: false });
          return true;
        }
      } else {
        setMobileNumberError({ countryError: false });
        return false;
      }
    } catch (error) {
      setMobileNumberError({ countryError: false });
      return false;
    }
  }

  const validateMobilenumber = (phoneNumber) => {
    if (phoneNumber.length > 0) {
      let isMobileNumberValid = validationMobileNumber(phoneNumber);
      if (isMobileNumberValid) {
        setValue('mobileNumber', phoneNumber);
      }
    } else {
      if (phoneNumber !== '') {
        setMobileNumberError({ countryError: true });
      }
    }
  };

  const onPayPalClick = async () => {
    const result = true;
    return result;
  };

  const tryAnotherCard = () => {
    setValue(`mobileNumber`, '');
    setValue(`cardNumber`, '');
    setValue(`expiration`, '');
    setValue(`cvc`, '');
    setPaymentMethod(PAYMENT_METHOD?.CREDIT_CARD);
    setPaymentModal(false);
  };

  let transactionDetails = {};

  const onSuccessfulPayPalPayment = (transactionInfo) => {
    transactionDetails = {
      paidWith: PAYMENT_METHOD?.PAY_PAL,
      date: transactionInfo?.create_time,
      id: transactionInfo.id,
      payerId: transactionInfo?.payer?.payer_id,
      payerEmailId: transactionInfo?.payer?.email_address,
      purchaseUnits: transactionInfo?.purchase_units
    };
    handleSubmit(onSubmit)();
  };

  const closeDynamicModal = () => {
    setEmailJsErrorModal(false);
  };

  const closeButtonClicked = () => {
    if (IS_SSO_ON) {
      navigate(PATH_PAGE?.companyProfile);
    } else {
      navigate(PATH_PAGE?.companiesList);
    }
  };

  const onSubmit = async (data) => {
    setFormData(data);
    sessionStorage?.setItem('formData', JSON?.stringify(data));
    const manualRenewalPayload = {
      companyId: companyDetails?._id,
      email: companyDetails?.email,
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      phoneNumber: companyDetails?.phoneNumber || '',
      cardNumber: Number(cardNumberValue.split(' ').join('')),
      month: expirationMonth,
      year: expirationYear,
      cvc: cvcNumber,
      description: 'Renew',
      registrationCountry: companyDetails?.registrationCountry
    };

    checkoutFunction(manualRenewalPayload);
    const updateManualRenewalPayload = {
      registrationCountry: companyDetails?.registrationCountry,
      companyId: companyDetails?._id,
      email: companyDetails?.email,
      phoneNumber: companyDetails?.phoneNumber || '',
      intentId: ''
    };

    sessionStorage?.setItem('updateManualRenewalPayload', JSON?.stringify(updateManualRenewalPayload));
  };

  async function checkout(payload) {
    try {
      const baseUrl = BASE_API_URL;
      const apiUrl = baseUrl + ENDPOINTS.PAYMENT_MANUAL_RENEWAL;

      const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);

      setLoading(true);

      if (!token) {
        throw new Error('Authorization token is missing');
      }

      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      console.error('Error while checkout', err);
      return err;
    }
  }

  async function checkoutFunction(payload) {
    const checkoutResponse = await checkout(payload);
    if (checkoutResponse?.status === API_CODE.STATUS_200) {
      if (checkoutResponse?.data?.statusCode === API_CODE.STATUS_200) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Payment'
        });
        if (checkoutResponse?.data?.data?.validationRequire === true) {
          // This for 3D card redirect to the bank page for payment
          navigate(checkoutResponse?.data?.data?.results?.next_action?.redirect_to_url?.url);
        } else {
          // This is for not 3D secured cards payment is done show success modal directly
          setStep((prevStep) => prevStep + 1);
        }
      } else if (
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.CARD_DECLINED ||
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.INCORRECT_NUMBER
      ) {
        setCardsError({ ...cardsError, cardNumber: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);
        setLoading(false);
      } else if (
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.INVALID_EXPIRY_YEAR ||
        checkoutResponse?.data?.apiStatus === CARD_ERROR?.INVALID_EXPIRY_MONTH
      ) {
        setCardsError({ ...cardsError, expiration: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);

        setLoading(false);
      } else if (checkoutResponse?.data?.apiStatus === CARD_ERROR?.INVALID_CVC) {
        setCardsError({ ...cardsError, cvc: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);

        setLoading(false);
      } else {
        setCardsError({ ...cardsError, cardNumber: true, message: checkoutResponse?.data?.message });
        setPaymentModal(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setCardsError({
        ...cardsError,
        cardNumber: true,
        message: checkoutResponse?.response?.data?.message || checkoutResponse?.message
      });
      setPaymentModal(true);
    }
  }

  const updateManualRenewalPayload = isBrowser() && JSON.parse(sessionStorage?.getItem('updateManualRenewalPayload'));
  const params = new URLSearchParams(location?.search);
  const intentId = params.get('payment_intent');

  async function updatePayment(updateManualRenewalPayload) {
    try {
      const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
      setLoading(true);
      if (!token) {
        throw new Error('Authorization token is missing');
      }
      const baseUrl = BASE_API_URL;
      const apiUrl = baseUrl + ENDPOINTS.PAYMENT_UPDATE_MANUAL_RENEWAL;
      const updateResponse = await axios.post(apiUrl, updateManualRenewalPayload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (updateResponse?.data?.statusCode === API_CODE?.STATUS_200) {
        setStep((prev) => prev + 1);
      } else {
        setPaymentModal(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setPaymentModal(true);
    }
  }
  useEffect(() => {
    if (updateManualRenewalPayload && intentId) {
      updateManualRenewalPayload.intentId = intentId;
      updatePayment(updateManualRenewalPayload);
    }
  }, []);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between min-h-[440px] max-w-[480px] shadow-lg">
      <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
        <FormControl className="w-full">
          {loading && (
            <DynamicModal hideCrossIcon={true} isCloseRequired={false} openDynamicModal={loading}>
              <div className="bg-white flex flex-col items-center justify-center w-[80vw] sm:w-[424px] max-w-full">
                <StatrysLuckySpinner />
                <XSText title="Processing" className="mt-4" textColor="text-gray-450" textAlign="text-center" />
              </div>
            </DynamicModal>
          )}
          {!loading && (
            <>
              <div className="flex justify-between">
                <MDSubtitleText title="License renewal" fontWeight="text-bold" />
                <div onClick={() => closeButtonClicked()} className="cursor-pointer">
                  <img src={CloseIcon} className="w-4 h-4" alt="close icon" />
                </div>
              </div>
              <div className="hidden bg-gray-200 rounded-lg border border-gray-200 mt-8 cursor-pointer">
                <div
                  onClick={() => setPaymentMethod(PAYMENT_METHOD?.CREDIT_CARD)}
                  className={`px-6 py-4 flex items-center justify-center gap-2 rounded-lg w-full ${
                    paymentMethod === PAYMENT_METHOD?.CREDIT_CARD && 'bg-white'
                  } `}
                >
                  <CreditCardInline stroke={MUI_COLORS?.BLACK} />
                  <MSText title="Credit Card" />
                </div>
                <div
                  onClick={() => setPaymentMethod(PAYMENT_METHOD?.PAY_PAL)}
                  className={`px-6 py-4 flex items-center justify-center gap-2 rounded-lg w-full ${
                    paymentMethod === PAYMENT_METHOD?.PAY_PAL && 'bg-white'
                  } `}
                >
                  <PayPalInline />
                  <MSText title="Paypal" />{' '}
                </div>{' '}
              </div>
              {paymentMethod === PAYMENT_METHOD?.PAY_PAL && (
                <div>
                  <XSText className="md:whitespace-pre-line mt-4" title={`Contact information`} />
                  <div className="mt-2">
                    <TextInput
                      onKeyDown={handleEnter}
                      name="email"
                      value=""
                      disabled
                      label="Email Address"
                      placeholder="e.g. name@domain.com"
                      variant="filled"
                      inputRef={register({
                        required: true,
                        pattern: {
                          value: emailRegex,
                          message: 'Please fill in a valid Email Address'
                        }
                      })}
                      error={errors.email ? true : false}
                      helperText={errors.email && <ValidationMessage title="Please fill in a valid Email Address" />}
                    />
                    {emailError && <ValidationMessage title="Please fill in a valid Email Address" />}
                    <div className="mt-4">
                      <MobileNumberAutoComplete
                        label="Phone Number"
                        placeholder="+85212312231212"
                        options={allCountriesCode}
                        value=""
                        disabled
                        name="mobileNumber"
                        onInputValueChange={validateMobilenumber}
                        inputRef={register({
                          required: true
                        })}
                      />
                    </div>
                  </div>
                </div>
              )}

              {paymentMethod === PAYMENT_METHOD?.CREDIT_CARD ? (
                <div>
                  <XSText className="md:whitespace-pre-line mt-4" title={`Card information`} />
                  <div className="mt-2 flex felx-row relative">
                    <TextInput
                      defaultValue=""
                      name="cardNumber"
                      label="Card Number"
                      placeholder="1234 1234 1234 1234"
                      className="w-full"
                      max={19}
                      textFieldContainerClass="card-number-input"
                      onChange={(event) => onCardNumberChange(event)}
                      inputRef={register({
                        required: true
                      })}
                      error={errors.cardNumber || cardsError?.cardNumber ? true : false}
                      helperText={
                        (errors?.cardNumber || cardsError?.cardNumber) && (
                          <ValidationMessage title={cardsError?.message} />
                        )
                      }
                      autoComplete="cc-number"
                    />
                    <div className="card-image-container flex wrap ">
                      {cardTypes?.map((card, index) => {
                        return <img src={card?.image} alt={card?.alt} key={index} className="card-images" />;
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row gap-4 mt-4">
                    <TextInput
                      defaultValue=""
                      name="expiration"
                      label="Expiration"
                      placeholder="MM/YY"
                      onChange={(event) => onChangeExpiration(event)}
                      textFieldContainerClass="w-full"
                      inputRef={register({
                        required: true
                      })}
                      error={errors.expiration || cardsError?.expiration ? true : false}
                      helperText={
                        (errors?.expiration || cardsError?.expiration) && (
                          <ValidationMessage title={defaultValidationMessage('Expiration')} />
                        )
                      }
                    />
                    <TextInput
                      defaultValue=""
                      name="cvc"
                      label="CVC"
                      placeholder="123"
                      textFieldContainerClass="w-full"
                      max={3}
                      onChange={(event) => onCvcChange(event)}
                      inputRef={register({
                        required: true
                      })}
                      error={errors.cvc || cardsError?.cvc ? true : false}
                      helperText={
                        (errors?.cvc || cardsError?.cvc) && (
                          <ValidationMessage title={defaultValidationMessage('CVC')} />
                        )
                      }
                    />
                  </div>
                  <TermCondition />

                  <div className="mt-6 flex md:flex-row flex-col-reverse justify-between items-center">
                    <PrimaryButton
                      id="cc-paybutton"
                      caption={
                        registrationCountry === COMPANY_REGISTRATION_COUNTRY.HONG_KONG
                          ? `Confirm renewal for HKD${FEES.COMPANY_CREATION_CAPTION_RENEWAL}`
                          : `Confirm renewal for SGD${FEES.SINGAPORE_COMPANY_CREATION_CAPTION_RENEWAL}`
                      }
                      fontSize="text-base"
                      className="capitalize w-full my-2"
                      onClick={handleSubmit(onSubmit)}
                      isBorderRequired={true}
                      disabled={loading ? true : false}
                      linkClass="w-full"
                      bgColor={loading ? 'bg-gray-400' : 'bg-coral-500'}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <TermCondition />
                  <div className=" flex justify-between items-center mt-6 py-2">
                    {payPalLoading ? (
                      <StatrysLuckySpinner />
                    ) : (
                      <PaypalCheckoutButton
                        onPayPalClick={onPayPalClick}
                        onPayPalDecline={() => setPaymentModal(true)}
                        onSuccessfulPayPalPayment={onSuccessfulPayPalPayment}
                        registrationCountry={registrationCountry}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </FormControl>
      </form>

      <DynamicModal openDynamicModal={emailJsErrorModal} closeDynamicModal={closeDynamicModal}>
        <MDSubtitleText fontWeight="text-bold" title="Oops! There has been an error" />
        <MSText className="my-6" title={`Error message: ${emailJsResponse?.text}`} />
      </DynamicModal>

      <DynamicModal openDynamicModal={paymentModal} closeDynamicModal={() => setPaymentModal(false)} maxWidth="md">
        <div className="md:w-[411px] flex flex-col gap-6">
          <img src={CardErrorPNG} className="mx-auto mt-4" alt="secure" height={127} width={191} />
          <MDSubtitleText fontWeight="text-bold" textAlign="text-center" title="Your payment was not successful" />
          {cardsError?.message && <XSText textAlign="text-center" title={cardsError?.message} />}
          <XSText textAlign="text-center" title="To proceed, you may try using a different credit card" />
          <div className="flex gap-4 items-center justify-center">
            <div
              className="md:w-1/2 w-full bg-coral-500 flex items-center gap-2 justify-center py-4 rounded-lg cursor-pointer"
              onClick={tryAnotherCard}
            >
              <CreditCardInline stroke={MUI_COLORS?.WHITE} />
              <XSText textAlign="text-center" textColor="text-white" title="Try another card" />
            </div>
          </div>
        </div>
      </DynamicModal>
      <div className="flex flex-row gap gap-6 mt-6 justify-center">
        <img src={Ssl} alt="ssl" className="secure-logo" />
        <img src={Stripe} alt="powered by stripe" className="secure-logo" />
        <img src={Aes} alt="aes" className="secure-logo" />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dialCodes: state?.AccountSetupInfo?.countryDialCodeList?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    countryDialCodes: () => dispatch(userActions.countryDialCodes())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseRenewal);

LicenseRenewal.propTypes = {
  dialCodes: PropTypes.array,
  setStep: PropTypes.func
};
