import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import PropTypes from 'prop-types';
import XSText from '../../ui/typography/XSText';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from '../redux/action';
import {
  COMPANY_REGISTRATION_COUNTRY,
  IS_SSO_ON,
  REDIRECTION_SOURCE,
  SESSION_STORAGE_NAME
} from '../../../constants/enum';
import IconHongKong from '../../../images/icons/hong-kong-sar.png';
import IconSingapore from '../../../images/icons/singapore.png';
import IconArrowRight from '../../../images/icons/arrow-right-1.svg';
import BackButton from '../../ui/buttons/BackButton';
import { ENDPOINTS } from '../../../service/end-points';
import { clearStorage, isLoggedIn, logOut } from '../../authService/auth';

function RegistrationCountry({ nextStep, newCompanyData, newCompanyAllInfo }) {
  const selectedCountry = async (country) => {
    // Push event to GTM dataLayer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'jurisdiction_set',
      'CC Jurisdiction': country
    });
    // Update state with selected country
    let newCompanyPayload = {
      ...newCompanyAllInfo,
      registrationCountry: country
    };
    await newCompanyData(newCompanyPayload);
    nextStep();
  };
  return (
    <div className="bg-white md:p-8 p-4 md:mt-0 rounded-lg flex flex-col justify-between company-registration-form-container">
      <div></div>
      <div className="flex flex-col justify-between step-field-container">
        <MDSubtitleText
          className="whitespace-pre-line mb-6"
          fontWeight="text-bold"
          title="Where would you like to register your company?"
        />
        <div className="mt-8 bg-white">
          <div
            onClick={() => selectedCountry('HongKong')}
            className={`w-full py-4 border hover:border-purple-500 border-gray-200 rounded-t-lg flex items-center pl-5 cursor-pointer`}
          >
            <img src={IconHongKong} alt="Hong Kong" className="country-icon w-8" />
            <XSText className="ml-4 country-text " title={'Hong Kong SAR'} />
            <img src={IconArrowRight} alt="arrow right" className="mr-4 ml-auto" />
          </div>
          <div
            onClick={() => selectedCountry('Singapore')}
            className={`w-full py-4 border hover:border-purple-500 border-gray-200 rounded-b-lg flex items-center pl-5 cursor-pointer`}
          >
            <img src={IconSingapore} alt="Singapore" className="h-8 w-8" />
            <XSText className="ml-4 country-text " title={'Singapore'} />
            <img src={IconArrowRight} alt="arrow right" className="mr-4 ml-auto" />
          </div>
        </div>
      </div>
      <BackButton
        onClick={() => {
          if (IS_SSO_ON && isLoggedIn()) {
            const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
            const identifierCode = sessionStorage.getItem(SESSION_STORAGE_NAME.USER_CODE);
            clearStorage();
            window.open(
              `${process.env.MARKETING_SITE_URL}/authenticate-user?token=${token}&identifierCode=${identifierCode}&source=${REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP}&redirectTo=DASHBOARD`,
              '_self'
            );
          } else if (IS_SSO_ON) {
            logOut();
          }
        }}
        urlLink={
          IS_SSO_ON
            ? '#'
            : newCompanyAllInfo?.registrationCountry === COMPANY_REGISTRATION_COUNTRY?.HONG_KONG
            ? ENDPOINTS?.HONG_KONG_LANDING_PAGE
            : newCompanyAllInfo?.registrationCountry === COMPANY_REGISTRATION_COUNTRY?.SINGAPORE
            ? ENDPOINTS?.SINGAPORE_LANDING_PAGE
            : ENDPOINTS?.OPEN_ACCOUNT
        }
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData,
    companyId: state?.newAccountSetup?.companyCreateDetails?.data?._id
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(actionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData)),
    companyCreate: (companyCreateData) => dispatch(actionsForRegistrationNewCompany.companyCreate(companyCreateData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationCountry);

RegistrationCountry.propTypes = {
  newCompanyData: PropTypes.object,
  newCompanyAllInfo: PropTypes.object,
  nextStep: PropTypes.func,
  setCompletedSteps: PropTypes.func,
  completedSteps: PropTypes.object,
  companyCreate: PropTypes.func,
  location: PropTypes.func,
  setAllFieldsData: PropTypes.func,
  allFieldsData: PropTypes.object,
  companyId: PropTypes.object
};
