import React from 'react';

const Logout = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <g stroke="#E15741" strokeLinecap="round" strokeLinejoin="round" clipPath="url(#clip0_1879_12836)">
        <path d="M.969 8.004h9.844M8.469 10.348l2.344-2.344L8.469 5.66"></path>
        <path d="M2.566 10.876a6.562 6.562 0 1 0 .112-5.964"></path>
      </g>
      <defs>
        <clipPath id="clip0_1879_12836">
          <path fill="#fff" d="M0 .001h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
export default Logout;
