import React, { useState } from 'react';
import {
  COMPANY_SETUP_STEP,
  CONSTANT_NUMBER,
  COMPANY_SETUP_SIDEBAR_STEP,
  SESSION_STORAGE_NAME
} from '../../../constants/enum';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import StatrysLoader from '../../ui/loaders/StatrysLoader';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import OwnersCard from '../common/OwnersDirectorsCard';
import { connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../../constants/enum';

function OwnerAndDirectorList({
  setStep,
  setIsAddingOwner,
  setSubStep,
  setIsOwnerDirectorEdit,
  stackHolderPayload,
  updateCompanyIncorporation,
  companyAllInformation,
  setCurrentStackHolder,
  setStackHolderPayload,
  deleteStakholder,
  getCompanyDetails,
  setActiveStep,
  isOwnerDirectorEdit,
  setPassportUploadResponse
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreOwnerClicked, setNoMoreOwnerClicked] = useState(false);
  const FinalOwnerStackHolder = companyAllInformation?.stakeholder;

  const handleOwnerBackButton = () => {
    setStep(COMPANY_SETUP_STEP?.BUSINESS_DESCRIPTION);
    setActiveStep(COMPANY_SETUP_SIDEBAR_STEP?.COMPANY_INFORMATION);
  };
  const addOwnerHandler = () => {
    setCurrentStackHolder({});
    setIsOwnerDirectorEdit({ ...isOwnerDirectorEdit, isEdit: false });
    setIsAddingOwner();
    setSubStep(CONSTANT_NUMBER.TWO);
    setStep(CONSTANT_NUMBER.EIGHT);
  };

  const handleNoMoreDirector = async () => {
    for (let i = 0; i < FinalOwnerStackHolder.length; i++) {
      FinalOwnerStackHolder[i].ownership = '';
      FinalOwnerStackHolder[i].shares = 0;
      FinalOwnerStackHolder[i].totalInvestment = 0;
    }
    let finalPayload = {
      stakeholder: FinalOwnerStackHolder,
      companyId: companyAllInformation?._id,
      completedStep: COMPANY_SETUP_STEP.OWNER_AND_DIRECTOR_LIST
    };
    const response = await updateCompanyIncorporation(finalPayload);
    if (response?.status === API_CODE?.STATUS_200) {
      setStep(CONSTANT_NUMBER.FOURTEEN);
      setSubStep(CONSTANT_NUMBER.THREE);
      setStackHolderPayload([]);
    }
  };

  const handleNoMoreOwner = async () => {
    setNoMoreOwnerClicked(true);
  };

  const deleteOwner = async (id, isLocalStakholder) => {
    if (isLocalStakholder) {
      const deleteStackHoler = stackHolderPayload.filter((holder) => holder?.id !== id);
      setStackHolderPayload([...deleteStackHoler]);
      if (FinalOwnerStackHolder?.length === 1) {
        if (deleteStackHoler?.length === 0 && id && companyAllInformation?._id) {
          let payload = {
            companyId: companyAllInformation?._id,
            stakeholderId: id
          };
          const deleteStackHolerResponse = await deleteStakholder(payload);
          if (deleteStackHolerResponse?.status === API_CODE?.STATUS_200) {
            getCompanyDetails(sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID));
            const localStakholder = stackHolderPayload.filter((holder) => holder?.id !== id);
            if (localStakholder?.length === 0 && deleteStackHolerResponse?.data?.data?.stakeholder?.length === 0) {
              addOwnerHandler();
            }
          }
        }
      }
      if (deleteStackHoler?.length === 0 && companyAllInformation?.stakeholder?.length === 0) {
        addOwnerHandler();
      }
    } else {
      if (companyAllInformation?._id && id) {
        let payload = {
          companyId: companyAllInformation?._id,
          stakeholderId: id
        };
        const deleteStackHolerResponse = await deleteStakholder(payload);
        if (deleteStackHolerResponse?.status === API_CODE?.STATUS_200) {
          getCompanyDetails(sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID));
          if (deleteStackHolerResponse?.data?.data?.stakeholder?.length === 0) {
            addOwnerHandler();
          }
        }
      }
    }
  };

  return (
    <div className="flex flex-col w-full h-full md:gap-0 gap-6 justify-between">
      <MDSubtitleText title="Owners and directors" fontWeight="text-bold" />
      <div>
        {isLoading ? (
          <div className="flex items-center justify-center document-list-loader">
            <StatrysLoader />
          </div>
        ) : (
          <>
            <div className="md:w-1/2 w-full md:py-8 py-0">
              {FinalOwnerStackHolder?.map((ownerDirectorData) => {
                return (
                  <div key={ownerDirectorData.code} className="mb-2">
                    <OwnersCard
                      id={ownerDirectorData?.id || ownerDirectorData?._id}
                      isLocal={ownerDirectorData?._id ? false : true}
                      deleteCard={(value, isLocal) => deleteOwner(value, isLocal)}
                      setIsOwnerDirectorEdit={setIsOwnerDirectorEdit}
                      isOwnerDirectorEdit={isOwnerDirectorEdit}
                      setStep={setStep}
                      numberOfStakholder={FinalOwnerStackHolder?.length}
                      setActiveStep={setActiveStep}
                      setSubStep={setSubStep}
                      allData={ownerDirectorData}
                      setPassportUploadResponse={setPassportUploadResponse}
                    />
                  </div>
                );
              })}
              <div className="flex md:flex-row flex-col justify-between md:gap-4 gap-2 items-center  w-full">
                {noMoreOwnerClicked ? (
                  <PrimaryButton
                    caption="Add director"
                    bgColor="bg-white"
                    color="text-coral-500"
                    className="mr-2 w-full  owner-and-directorList"
                    linkClass="w-full"
                    onClick={addOwnerHandler}
                  />
                ) : (
                  <PrimaryButton
                    caption="Add owner"
                    bgColor="bg-white"
                    color="text-coral-500"
                    className="mr-2 w-full owner-and-directorList"
                    linkClass="w-full"
                    onClick={addOwnerHandler}
                  />
                )}
                {noMoreOwnerClicked ? (
                  <PrimaryButton
                    caption="No more directors"
                    className="w-full owner-and-directorList"
                    color="text-white"
                    linkClass="w-full"
                    onClick={handleNoMoreDirector}
                  />
                ) : (
                  <PrimaryButton
                    caption="No more owners"
                    className="w-full owner-and-directorList"
                    color="text-white"
                    linkClass="w-full"
                    onClick={handleNoMoreOwner}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex justify-between">
        <BackButton onClick={handleOwnerBackButton} />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload)),
    deleteStakholder: (payload) => dispatch(CompanyAction.deleteStakholder(payload)),
    getCompanyDetails: (companyId) => dispatch(CompanyAction.getCompanyIncorporation(companyId))
  };
};

export default connect(null, mapDispatchToProps)(OwnerAndDirectorList);
