import {
  FILE_UPLOAD_TYPE,
  OWNER_DIRECTOR_TYPES,
  API_ERROR_CODES,
  API_CODE,
  COUNTRY_CODE,
  ERROR_STATUS_CODE,
  FILE_SIZE,
  REQUIRED_LENGTH,
  SPECIAL_CHARACTER_VALIDATION,
  MUI_COLORS,
  CHAR_CODE,
  AVATAR_COlORS,
  REGEX,
  SESSION_STORAGE_NAME,
  CLIENT_APPLICATION_STATUS_DATA,
  SSO_SITE_LOGIN_URL,
  IS_SSO_ON,
  REDIRECTION_SOURCE,
  MOBILE_APP_EVENTS
} from '../constants/enum';
import { DateFormats } from '../constants/enum';
import { RESOURCES_HUBSPOT_INTEGRATION } from '../service/services';
import { navigate } from 'gatsby';
import moment from 'moment';
import axios from 'axios';
import { getImageData } from 'gatsby-plugin-image';
import InvoiceApi from '../store/invoiceInterceptor';
import { ENDPOINTS } from '../service/invoice-service';
import { ENDPOINTS as SERVICES } from '../service/services';
import { BASE_API_URL } from '../constants/enum';
import { logOut } from '../components/authService/auth';

export const handleEnter = (event) => {
  if (event.key.toLowerCase() === 'enter') {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
  if (
    event.target.value.length === 0 &&
    event.key.toLowerCase() === 'backspace' &&
    (event.target.name === 'month' || event.target.name === 'year')
  ) {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index - 1].focus();
    event.preventDefault();
  }
};

export const isBrowser = () => typeof window !== 'undefined';

export const companyAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.MSWORD ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.OFFICE_DOCUMENT) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};
export const stakeHolderAcceptedFileFormats = (selectedDocument) => {
  if (
    (selectedDocument[0].type === FILE_UPLOAD_TYPE.PDF ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.PNG ||
      selectedDocument[0].type === FILE_UPLOAD_TYPE.JPEG) &&
    selectedDocument[0].size <= FILE_SIZE.FOUR_MB_IN_BYTES
  )
    return true;
  else return false;
};
export const stakeHolderOwnersCheck = (companyStakeHolder) => {
  if (
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.OWNER ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.OWNER_DIRECTOR ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.NOMINEE_OWNER_DIRECTOR ||
    companyStakeHolder.type === OWNER_DIRECTOR_TYPES.NOMINEE_OWNER
  )
    return true;
  else return false;
};

export const MatchingModalApiErrorStatus = (getApiError) => {
  if (
    (getApiError?.status === API_ERROR_CODES.STATUS_422 &&
      getApiError?.data?.code !== ERROR_STATUS_CODE.ONB_1038 &&
      getApiError?.data?.code !== ERROR_STATUS_CODE.ONB_1008) ||
    getApiError?.status === API_ERROR_CODES.STATUS_412 ||
    getApiError?.status === API_ERROR_CODES.STATUS_403 ||
    getApiError?.status === API_ERROR_CODES?.STATUS_402
  ) {
    return true;
  } else {
    return false;
  }
};
export const MatchingInvoiceApiErrorStatus = (getApiError) => {
  if (getApiError?.status === API_ERROR_CODES.STATUS_400 || getApiError?.status === API_ERROR_CODES.STATUS_500) {
    return true;
  } else {
    return false;
  }
};
export const MatchingOopsApiErrorStatus = (error) => {
  if (
    error?.response?.status !== API_ERROR_CODES.STATUS_422 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_412 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_401 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_402 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_403 &&
    error?.response?.status !== API_ERROR_CODES.STATUS_400
  ) {
    return true;
  } else {
    return false;
  }
};

export const Matching401ApiErrorStatus = (error) => {
  if (
    error?.response?.status === API_ERROR_CODES.STATUS_401 ||
    error?.response?.status === API_ERROR_CODES.STATUS_404
  ) {
    return true;
  } else {
    return false;
  }
};

export const convertDateToDDMMYYYY = (date) => {
  return moment(date).format(DateFormats.DDMMMYYY);
};
export const setURLQueryParamsAndRedirectToPaymentApp = async () => {
  const auth = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.AUTH));
  if (auth) {
    const clientCode = sessionStorage.getItem(SESSION_STORAGE_NAME.CLIENT_CODE) || '';
    const queryParams = `clientCode=${clientCode}&expiryTime=${auth.expiryTime}&code=${auth.token}&uuid=${auth.uuid}`;

    if (queryParams) {
      window.open(`${process.env.PAYMENT_APP_LOGIN}?${queryParams}`, '_self');
      sessionStorage.clear();
    }
  }
};

export const getCountryName = (countriesOptions, counrtyCode) => {
  let filterJurisdiction = countriesOptions.find((countryOption) => {
    if (countryOption[COUNTRY_CODE.ALPHA_2] == counrtyCode) {
      return countryOption?.name;
    }
  });
  return filterJurisdiction;
};

export const sendHubspotRequestResources = (final_data, downloadURI) => {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', RESOURCES_HUBSPOT_INTEGRATION.HUBSPOT_URL);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(final_data);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == API_CODE.STATUS_200) {
      downloadURI();
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_400) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_403) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_404) {
      alert(xhr.responseText);
    }
  };
};

export function hubspotCookie() {
  var b = document.cookie.match(REGEX.HUBSPOT_COOKIE_REGEX);
  return b ? b.pop() : '';
}

export const getLastPublishedDate = (publishedDate) => {
  const now = moment(new Date()); //todays date
  const end = moment(publishedDate); // another date
  const duration = moment.duration(now.diff(end));
  const secs = duration.asSeconds();
  if (secs < 60) {
    return Math.floor(secs) === 1 ? 'one second ago' : Math.floor(secs) + ' seconds ago';
  } else {
    const minutes = duration.asMinutes();
    if (minutes < 60) {
      return Math.floor(minutes) === 1 ? 'one minute ago' : Math.floor(minutes) + ' minutes ago';
    } else {
      const hours = duration.asHours();
      if (hours < 24) {
        return Math.floor(hours) === 1 ? 'an hour ago' : Math.floor(hours) + ' hours ago';
      } else {
        const days = duration.asDays();
        return Math.floor(days) === 1 ? 'Yesterday' : moment(publishedDate).format('DD/MM/YYYY');
      }
    }
  }
};

// export const redirectUserToLogin = () => {
//   location.replace('/login');
// };
export async function fetchAppVersion() {
  try {
    let response = await axios.get('/app-version.html');
    let splittedAppVersion = response.data.toString().split('\n');
    let trimmedAppVersion = splittedAppVersion[0].trim();
    sessionStorage.setItem(SESSION_STORAGE_NAME.APP_VERSION, trimmedAppVersion);
  } catch (error) {
    console.error(error);
  }
}

export const isUserLoggedIn = () => {
  let userLogin = sessionStorage.getItem(SESSION_STORAGE_NAME.USER_LOGIN);
  let customerID = sessionStorage.getItem(SESSION_STORAGE_NAME.CUSTOMER_ID);
  let prospectCode = sessionStorage.getItem(SESSION_STORAGE_NAME.PROSPECT_CODE);
  if (!userLogin || !customerID || !prospectCode) {
    logOut();
  } else {
    return true;
  }
};

export const isUserCodePresent = () => {
  let customerID = sessionStorage.getItem(SESSION_STORAGE_NAME.CUSTOMER_ID);
  if (!customerID) {
    logOut();
  } else {
    return true;
  }
};
export const isProspectCodePresent = () => {
  let prospectCode = sessionStorage.getItem(SESSION_STORAGE_NAME.PROSPECT_CODE);
  if (!prospectCode) {
    return false;
  } else {
    return true;
  }
};

export const redirectToOopsPage = (error) => {
  navigate('/oops', {
    state: {
      message: error?.response?.data?.message,
      errorList: error?.response?.data?.items,
      apiErrorCode: error?.response?.data?.code,
      apiStatusCode: error?.response?.status
    }
  });
};

export const getChoppedDocumentName = (selectedDocs) => {
  let choppedDocumentname = selectedDocs[0].name
    .split('.')[0]
    .substring(0, REQUIRED_LENGTH.ALLOWED_DOCUMENT_LENGTH + 1);
  return choppedDocumentname;
};

export const stringTruncate = (text, numberOfString) => {
  let finalString;
  if (text?.length > numberOfString) {
    finalString = text?.substring(0, numberOfString) + '...';
  } else {
    finalString = text;
  }
  return finalString ? finalString : '';
};

export const makeFirstLetterCapitalize = (value) => {
  const capitalizedString = value ? value?.charAt(0)?.toUpperCase() + value?.slice(1) : '';
  return capitalizedString;
};

export const invoiceInputBackgroundColor = (inputValue) => {
  return inputValue ? MUI_COLORS.WHITE : MUI_COLORS.PAPAYA_WHIP;
};
export const replaceSpaceAndSpecialCharacter = (text) => {
  return text
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_SPACE, '-')
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_NBSP, '')
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_COMMA, '')
    .replace(SPECIAL_CHARACTER_VALIDATION.FOR_NAMES, '')
    .toLocaleLowerCase();
};

export const defaultValidationMessage = (fieldName) => {
  return `Please provide a valid ${fieldName}`;
};

export const itemsTotalPrice = (itemDetails) => {
  let totalItemsAmount = 0;
  itemDetails?.forEach((itemDetails) => {
    totalItemsAmount = totalItemsAmount + itemDetails?.itemPrice * itemDetails?.itemQuantity;
  });
  return totalItemsAmount;
};

export const tasksTotalPrice = (taskDetails) => {
  let totalTasksAmount = 0;
  taskDetails?.forEach((taskDetails) => {
    totalTasksAmount = totalTasksAmount + taskDetails?.taskHours * taskDetails?.taskRate;
  });
  return totalTasksAmount;
};

export const newItemsTotalPrice = (itemDetails) => {
  let totalNewItemsAmount = 0;
  itemDetails?.forEach((itemDetails) => {
    totalNewItemsAmount = totalNewItemsAmount + itemDetails?.itemPriceRate * itemDetails?.itemQuantity;
  });
  return totalNewItemsAmount;
};

export const priceCalculatorThroughPercentage = (total, percentage) => {
  return (total * percentage) / 100;
};
export function percentage(total, number) {
  return (number / total) * 100;
}
export const isEven = (num) => {
  return num % 2 === 0;
};
export const redirectToDashboardPage = async () => {
  navigate('/dashboard');
};

export const concatenateSelectedCurrency = (currency, amount, nofractionDigits) => {
  return `${currency?.symbol ? currency?.symbol : currency?.name}${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: nofractionDigits ? 0 : 2,
          maximumFractionDigits: nofractionDigits ? 0 : 2
        })
      : 0
  }`;
};
export const handleReDirectToStore = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const concatenateCurrencyAmountWithoutSpace = (currency, amount, nofractionDigits) => {
  return `${currency?.symbol ? currency?.symbol : currency?.name}${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: nofractionDigits ? 0 : 2,
          maximumFractionDigits: nofractionDigits ? 0 : 2
        })
      : 0
  }`;
};

export const multiplyNumbers = (Numbersarray) => {
  const sum = Numbersarray.reduce((prevValue, curValue) => {
    return prevValue * curValue;
  }, 1);
  return sum;
};
export const concatenateSelectedCurrencyForPDF = (currency, amount, nofractionDigits) => {
  return `${currency?.name}${
    amount
      ? amount.toLocaleString('en-US', {
          minimumFractionDigits: nofractionDigits ? 0 : 2,
          maximumFractionDigits: nofractionDigits ? 0 : 2
        })
      : 0
  }`;
};
const colors = AVATAR_COlORS;

export function getAvatarBackgroundColor(text) {
  if (typeof text !== 'string' || !text.trim()) {
    return colors[0];
  }
  const charCodes = text
    ?.split('')
    .map((char) => char.charCodeAt(0))
    .join('');
  return colors[parseInt(charCodes, 10) % colors.length];
}

export const remainingAmount = (currency, totalAmount, paidAmount) => {
  const remaining = totalAmount - paidAmount;
  return concatenateSelectedCurrency(currency, remaining);
};
export function isIbanNumber(number) {
  if (
    number.charAt(0).match(REGEX.ONLY_STRING_REGEX) &&
    number.charAt(1).match(REGEX.ONLY_STRING_REGEX) &&
    number.charAt(2).match(REGEX.NUMBER_CHECKER_REGEX) &&
    number.charAt(3).match(REGEX.NUMBER_CHECKER_REGEX)
  ) {
    return 'IBAN:';
  } else return 'Account Number:';
}
export const addCommas = (num) => {
  const addedValue = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return addedValue?.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });
};

export const getURLFirstPathName = (urlPathName) => {
  const firstUrlPath = urlPathName?.replace(/^\/([^\/]*).*$/, '$1');
  return firstUrlPath;
};

export const getSecondLastPathName = (pathname) => {
  const secondUrlPath = pathname?.substr(pathname?.lastIndexOf('/') + 1);
  return secondUrlPath;
};

export const getPathName = () => {
  return isBrowser() && window.location.pathname;
};
export function takeOutImageName(url) {
  const parts = url?.split('/');
  return parts?.[parts?.length - 1];
}

export const expriyDateFormatter = (value) => {
  const expdate = value;
  const expDateFormatter =
    expdate.replace(REGEX.REPLACE_SLASH, '').substring(0, 2) +
    (expdate.length > 2 ? '/' : '') +
    expdate.replace(REGEX.REPLACE_SLASH, '').substring(2, 4);
  return expDateFormatter;
};
export const handleKeyEnter = (event, performFunction) => {
  if (event?.keyCode === CHAR_CODE?.ENTER_KEY_CODE) {
    return performFunction();
  }
};
export function getFileExtension(url) {
  const urlWithoutQuery = url?.split('?')[0];
  const filename = urlWithoutQuery?.substring(urlWithoutQuery?.lastIndexOf('/') + 1);
  const extension = filename?.split('.')?.pop();
  const validExtensions = ['pdf', 'png', 'jpeg', 'jpg'];
  return validExtensions?.includes(extension?.toLowerCase()) ? extension : null;
}
export const sendHubspotRequest = (final_data, hubspot_integration) => {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', hubspot_integration);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(final_data);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == API_CODE.STATUS_200) {
      return true;
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_400) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_403) {
      alert(xhr.responseText);
    } else if (xhr.readyState == 4 && xhr.status == API_ERROR_CODES.STATUS_404) {
      alert(xhr.responseText);
    }
  };
};

export const getFileName = (fileUrl) => {
  var url = fileUrl;
  var fileName = url?.substring(url?.lastIndexOf('/') + 1);
  const fileNameFromUrl = {
    name: decodeURI(fileName)
  };
  return fileNameFromUrl;
};

export const findTotalCharacter = (currency, amount) => {
  if (currency && amount) {
    const countOfChar = `${currency?.symbol ? currency?.symbol : currency?.name} ${addCommas(amount)}`;
    if (countOfChar.length > 12) {
      return true;
    } else {
      return false;
    }
  }
};

export const addSpaceAfterFourDigits = (input) => {
  const digitsOnly = input.replace(/\D/g, '');
  return digitsOnly.replace(/(\d{4})/g, '$1 ');
};
export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function checkValueExistOrNot(value) {
  return value ? value : '-';
}

export const addYearToConvertDate = (date) => {
  return moment(date).add(1, 'year').format(DateFormats.DDMMMYYY);
};
const removeFileExtensions = (fileName) => {
  const regex = /\.(png|jpeg|jpg|pdf)(?:\?|#|$)/i;
  return fileName.replace(regex, '');
};
export const convertToBase64 = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-store' // Prevent caching of the response
    });
    const blob = await response.blob();
    const base64 = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

    return base64;
  } catch (error) {
    console.error('Error fetching the file:', error);
    return null;
  }
};

export const getFinalDocumentName = (shareDetails) => {
  const { documentName, documentCode } = shareDetails;

  const validExtensions = ['.jpg', '.png', '.pdf'];

  const hasValidExtension = validExtensions.some((ext) => documentName.endsWith(ext));

  if (hasValidExtension) {
    return documentName;
  }

  const extractedExtension = documentCode.substring(documentCode.lastIndexOf('.'));

  return `${documentName}${extractedExtension}`;
};

export const downloadImage = async (imageUrl, fileName) => {
  if (sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP) {
    const convertedBase64Link = await convertToBase64(imageUrl);
    const data = {
      event: MOBILE_APP_EVENTS.DOWNLOAD_DOCUMENTS,
      data: {
        url: convertedBase64Link,
        fileName: `${fileName}`
      }
    };
    isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
    return;
  }

  fetch(imageUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-store' // Prevent caching of the response
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      const filename = fileName
        ? `${removeFileExtensions(fileName)}.${getFileExtension(imageUrl)}`
        : `document.${getFileExtension(imageUrl)}`;

      link.setAttribute('download', filename);
      document.body.appendChild(link);

      link.click();

      link.parentNode.removeChild(link);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const isDecimal = (num) => {
  if (num % 1 !== 0) return true;
};

export const getLocalStorageValue = (key) => {
  const value = typeof window !== 'undefined' && localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (error) {
    return value;
  }
};

export const storeDataInLocalStorage = (key, data) => {
  try {
    const dataString = JSON.stringify(data);
    typeof window !== 'undefined' && localStorage.setItem(key, dataString);
    return true;
  } catch (error) {
    return false;
  }
};

export const isCompanyNameRegistered = (listOfComapny, companyName) => {
  const lowerCaseName = listOfComapny?.map((element) => element.toLowerCase());
  if (lowerCaseName?.includes(companyName?.toLowerCase())) return true;
};

export const replaceWatchWithEmbed = (url) => {
  return url.replace(REGEX?.WATCH, 'embed/');
};

export const heightOfElement = (id) => {
  const element = document.getElementById(id);
  return element?.getBoundingClientRect().height;
};

export const pxToVh = (px) => {
  const viewportHeight = isBrowser && window.innerHeight;
  return (px / viewportHeight) * 100;
};

export function getExampleImageData({ image, ...props }) {
  return getImageData({
    baseUrl: image?.url,
    sourceWidth: image?.width,
    sourceHeight: image?.height,
    urlBuilder,
    pluginName: 'gatsby-plugin-sharp',
    formats: ['auto', 'webp'],
    ...props
  });
}

export function urlBuilder({ baseUrl }) {
  return baseUrl;
}

export const sendDetailsToBackOffice = async (payload) => {
  try {
    return await InvoiceApi.post(ENDPOINTS?.BUSINESS_ACCOUNT, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export const updateBusinessAccountDetailsToBackOffice = async (payload) => {
  try {
    return await InvoiceApi.put(ENDPOINTS?.BUSINESS_ACCOUNT_UPDATE, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export const checkBusinessAccount = async (payload) => {
  try {
    return await InvoiceApi.post(ENDPOINTS?.CHECK_USER_ACCOUNT, payload);
  } catch (error) {
    alert(error?.response?.message);
  }
};

export function getCountryNameByCode(countryCode) {
  switch (countryCode.toUpperCase()) {
    case 'SG':
      return 'Singapore';
    case 'HK':
      return 'Hong Kong';
    case 'AR':
      return 'British Virgin';
    default:
      return 'Other Jurisdiction';
  }
}
export function isValidMonthAndYear(expirationDate) {
  const [month, year] = expirationDate.split('/').map(Number);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100; // Extract last two digits
  if (year < currentYear) {
    return false;
  }
  if (month < 1 || month > 12) {
    return false;
  }
  if (year === currentYear && month <= currentDate.getMonth() + 1) {
    return false;
  }
  return true;
}

export const getUserCompanies = async (token, email) => {
  const checkUserCompanies = await axios.get(`${BASE_API_URL}${SERVICES?.GET_COMPANIES_LIST}`, {
    params: {
      email: email
    },
    headers: { Authorization: `Bearer ${token}` }
  });
  typeof window !== 'undefined' &&
    window.sessionStorage.setItem(SESSION_STORAGE_NAME?.ALL_COMPANIES, JSON.stringify(checkUserCompanies?.data?.data));
  return checkUserCompanies;
};

export const companyDetails = async (token, companyId) => {
  try {
    let getCompanyIncorporationResponse = await axios.get(
      BASE_API_URL + `${SERVICES.GET_COMPANY_SET_UP_DETAILS}?companyId=${companyId}`,
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );
    return getCompanyIncorporationResponse;
  } catch (error) {}
};

export const modifyBusinessAccountData = (applications) => {
  if (!applications || applications.length === 0) {
    console.warn('No applications provided to modifyData.');
    return [];
  }

  try {
    const modifiedApplications = applications.map((element) => {
      const statusInfo = CLIENT_APPLICATION_STATUS_DATA.find((item) => item.status === element?.status);

      return {
        ...element,
        statusBackgroundColor: statusInfo?.backgroundColor || '',
        statusDisplayValue: statusInfo?.displayValue || ''
      };
    });

    return modifiedApplications;
  } catch (error) {
    console.error('Error modifying applications data:', error);
  }
};

export function setTokenInSessionStorage(token) {
  isBrowser() && sessionStorage.setItem(SESSION_STORAGE_NAME.TOKEN, token);
}

export function setCompleteAuthInSessionStorage(authInfo) {
  isBrowser() && sessionStorage.setItem(SESSION_STORAGE_NAME.COMPLETE_AUTH_INFO, authInfo);
}

export function getQueryParameter(location, key) {
  const params = new URLSearchParams(location?.search);
  return params.get(key);
}

export function isEnglishCharacter(char) {
  return REGEX.UPLOAD_DOCUMENT_ENGLISH_CHARACTERS.test(char);
}
