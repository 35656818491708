module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/statrys-symbol.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd1a4367cc9e5dabd1c4164efb6f4974"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"statrys","accessToken":"MC5Yelg4V1JJQUFDSUFNel9k.77-9D0JbF--_vQvvv71OIEbvv73vv70xSu-_ve-_vQ5PE--_vXFa77-977-9Su-_vWHvv73vv71A77-9","schemas":{"country":{"Main":{"name":{"type":"StructuredText","config":{"single":"heading1,heading2,heading3,heading4,heading5,heading6","label":"Name"}},"uid":{"type":"UID","config":{"label":"UID"}},"order":{"type":"Number","config":{"label":"order"}}}}},"prismicToolbar":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
