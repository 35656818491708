import React from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import { StaticImage } from 'gatsby-plugin-image';

export function InvalidLink() {
  return (
    <div className="flex w-full justify-center invalid-link-container">
      <div className="flex items-center invalid-link-container">
        <div className="w-1/2 flex img-container items-center justify-end px-12">
          <div>
            <div className="div-img mx-auto">
              <StaticImage src="../../../images/graphics/reviewing-eidv.png" alt="Statrys platform" />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex items-center justify-start invalid-link-description">
          <div>
            <MDSubtitleText
              title={'We are reviewing your case.'}
              fontWeight="text-bold"
              className="whitespace-pre-line main-text"
            ></MDSubtitleText>

            <div>
              <XSText
                className="mt-4 whitespace-pre-line sub-text"
                title={`Our team is reviewing your case and may come back if more information is needed.`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
