import * as React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../authService/auth';
import { PATH_PAGE } from '../../routes/paths';
import { isBrowser } from '../../helper/helpers';
import { IS_SSO_ON, SSO_RESET_PASSWORD_URL } from '../../constants/enum';
const PrivateRoute = ({ component: Component, ...rest }) => {
  if (isLoggedIn() && !IS_SSO_ON) {
    navigate(PATH_PAGE.companyProfile);
    return null;
  }

  const { path } = rest;
  const shouldRedirectToSSO =
    IS_SSO_ON &&
    isBrowser() &&
    [PATH_PAGE.resetPassword, PATH_PAGE.verifyEmail, PATH_PAGE.confirmEmailAccount].includes(path);

  if (shouldRedirectToSSO) {
    navigate(SSO_RESET_PASSWORD_URL);
    return null;
  }
  return <Component {...rest} />;
};

export default PrivateRoute;
