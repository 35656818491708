import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';
import axios from 'axios';
import { BASE_API_URL } from '../../../constants/enum';

export const loginRequestStart = () => ({ type: actionTypes.LOGIN_REQUEST_START });
export const loginRequestFail = (error) => ({ type: actionTypes.LOGIN_REQUEST_FAILURE, error: error });
export const loginRequestSuccess = (loginPayload) => ({
  type: actionTypes.LOGIN_REQUEST_SUCCESS,
  loginPayload: loginPayload
});
export const login = (loginPayload) => {
  return async (dispatch) => {
    try {
      dispatch(loginRequestStart());
      let loginResponse = await axios.post(`${BASE_API_URL}${ENDPOINTS.COMPANY_LOGIN}`, loginPayload);
      dispatch(loginRequestSuccess(loginResponse?.data));
      return loginResponse;
    } catch (error) {
      dispatch(loginRequestFail(error.response));
      return error?.response;
    }
  };
};

export const resetPasswordRequestStart = () => ({ type: actionTypes.RESET_PASSWORD_REQUEST_START });
export const resetPasswordRequestFail = (error) => ({ type: actionTypes.RESET_PASSWORD_REQUEST_FAILURE, error: error });
export const resetPasswordRequestSuccess = (resetPasswordPayload) => ({
  type: actionTypes.RESET_PASSWORD_REQUEST_SUCCESS,
  resetPasswordPayload: resetPasswordPayload
});
export const resetPassword = (resetPasswordPayload) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordRequestStart());
      let resetPasswordResponse = await Api.put(ENDPOINTS.RESET_PASSWORD, resetPasswordPayload);
      dispatch(resetPasswordRequestSuccess(resetPasswordResponse?.data));
      return resetPasswordResponse;
    } catch (error) {
      dispatch(resetPasswordRequestFail(error.response));
      return error?.response;
    }
  };
};

export const updateSSOTokenRequestStart = () => ({ type: actionTypes.UPDATE_SSO_TOKEN_REQUEST_START });
export const updateSSOTokenRequestFail = (error) => ({
  type: actionTypes.UPDATE_SSO_TOKEN_REQUEST_FAILURE,
  error: error
});
export const updateSSOTokenRequestSuccess = (updateSSOTokenPayload) => ({
  type: actionTypes.UPDATE_SSO_TOKEN_REQUEST_SUCCESS,
  updateSSOTokenPayload: updateSSOTokenPayload
});
export const updateSSOToken = (updateSSOTokenPayload, authToken) => {
  return async (dispatch) => {
    try {
      dispatch(updateSSOTokenRequestStart());
      let updateSSOTokenResponse = await Api.put(ENDPOINTS.UPDATE_INCORPORATION_SSO_TOKEN, updateSSOTokenPayload, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      dispatch(updateSSOTokenRequestSuccess(updateSSOTokenResponse?.data));
      return updateSSOTokenResponse;
    } catch (error) {
      dispatch(updateSSOTokenRequestFail(error.response));
      return error?.response;
    }
  };
};
