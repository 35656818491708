import React from 'react';
import ManWithPassport from '../../images/icons/confirm-identify-verification/man-with-passport-check.svg';
import BaseText from '../ui/typography/BaseText';
import XXSText from '../ui/typography/XXSText';

function SucessPage() {
  return (
    <div>
      <div className="bg-white rounded-lg md:max-h-[560px] z-40">
        <div className="p-8">
          <div className="flex md:flex-row flex-col md:gap-12 gap-8 w-full items-center">
            <div className="md:w-1/2 w-full md:order-0 order-1">
              <BaseText fontSize="text-4xl" lineHeight="text-9" title="Great! We are done!" textWeight="text-medium" />
              <XXSText
                textColor="text-gray-500"
                title="We're ready to begin the process of registering your company."
                className="mt-4 mb-2"
              />
            </div>
            <div className="md:w-1/2 w-full flex justify-center company-success-page-container md:order-1 order-0">
              <img
                src={ManWithPassport}
                alt="let's start company"
                width="368"
                className="object-contain md:w-[320px] md:h-[368px] w-[160px] h-[153px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SucessPage;
