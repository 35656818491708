import React, { useEffect, useState } from 'react';
import NavigationBar from '../components/sideBar/NavigationBar';
import { isLoggedIn, logOut } from '../components/authService/auth';
import { CONSTANT_NUMBER, IS_SSO_ON, SSO_SITE_LOGIN_URL } from '../constants/enum';
import { navigate } from 'gatsby';
import PackageDetails from '../components/renew-payment/PackageDetails';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import LicenseRenewal from '../components/renew-payment/LicenseRenewal';
import RenewalSuccessBanner from '../components/renew-payment/RenewalSuccessBanner';
import '../styles/pages/renew-payment/renew-payment.scss';
import SelectPaymentMode from '../components/renew-payment/SelectPaymentMode';
import PaymentAuthorization from '../components/renew-payment/PaymentAuthorization';
import AutoRenewalSuccess from '../components/renew-payment/AutoRenewalSuccessPage';
import { isBrowser } from '../helper/helpers';
import { PATH_PAGE } from '../routes/paths';
import Seo from '../components/seo';

function RenewPayment({ location }) {
  let stateData = {};
  let companyDetails = stateData?.companyDetails || {};
  let userDetails = stateData?.userDetails || {};

  const [isRenewalSuccessPage, setIsRenewalSuccessPage] = useState(false);

  if (isBrowser()) {
    location = window?.location;
    stateData = window?.history?.state;
    companyDetails = stateData?.companyDetails || JSON?.parse(sessionStorage?.getItem('companyDetails'));
    userDetails = stateData?.userDetails || JSON?.parse(sessionStorage?.getItem('userDetails'));

    sessionStorage?.setItem('companyDetails', JSON?.stringify(companyDetails));
    sessionStorage?.setItem('userDetails', JSON?.stringify(userDetails));
  }

  if (!companyDetails) {
    logOut();
  }
  const [step, setStep] = useState(0);

  const [isBusinessAccount, setIsBusinessAccount] = useState(companyDetails?.isBusinessAccountUser);

  let selectedCaseNoBA = () => {
    switch (step) {
      default:
        return (
          <div className="loader-container flex items-center justify-center">
            <StatrysLoader />
          </div>
        );
      case CONSTANT_NUMBER.ZERO:
        return <PackageDetails setStep={setStep} companyDetails={companyDetails} />;
      case CONSTANT_NUMBER.ONE:
        return (
          <LicenseRenewal
            registrationCountry={companyDetails?.registrationCountry}
            setStep={setStep}
            companyDetails={companyDetails}
            userDetails={userDetails}
            location={location}
          />
        );
      case CONSTANT_NUMBER.TWO:
        return (
          <RenewalSuccessBanner companyDetails={companyDetails} setIsRenewalSuccessPage={setIsRenewalSuccessPage} />
        );
    }
  };

  let selectedCaseForBA = () => {
    switch (step) {
      default:
        return (
          <div className="loader-container flex items-center justify-center">
            <StatrysLoader />
          </div>
        );
      case CONSTANT_NUMBER.ZERO:
        return <PackageDetails setStep={setStep} companyDetails={companyDetails} />;
      case CONSTANT_NUMBER.ONE:
        return <SelectPaymentMode setStep={setStep} setIsBusinessAccount={setIsBusinessAccount} />;
      case CONSTANT_NUMBER.TWO:
        return <PaymentAuthorization setStep={setStep} companyDetails={companyDetails} />;
      case CONSTANT_NUMBER.THREE:
        return <AutoRenewalSuccess companyDetails={companyDetails} setIsRenewalSuccessPage={setIsRenewalSuccessPage} />;
    }
  };

  const updateManualRenewalPayload = isBrowser() && JSON.parse(sessionStorage?.getItem('updateManualRenewalPayload'));
  const params = new URLSearchParams(location?.search);
  const intentId = params.get('payment_intent');

  useEffect(() => {
    if (updateManualRenewalPayload && intentId) {
      setIsBusinessAccount(false);
      setStep(CONSTANT_NUMBER.ONE);
    }
  }, []);

  useEffect(() => {
    if (!updateManualRenewalPayload && !intentId) {
      if (step === CONSTANT_NUMBER.ZERO) {
        navigate('?=package-details', {
          state: stateData
        });
      }
      if (step === CONSTANT_NUMBER.ONE) {
        navigate(isBusinessAccount ? '?=license-renewal' : '?=select-payment-mode', {
          state: stateData
        });
      }
      if (step === CONSTANT_NUMBER.TWO) {
        navigate(isBusinessAccount ? '?=success-page' : '?=payment', {
          state: stateData
        });
      }
    }
  }, [step]);

  return (
    <>
      <div className="w-screen min-h-screen bg-customBg-secondaryBg relative">
        <Seo title="Renewal" />
        {isLoggedIn() && (
          <NavigationBar hideBanner={true} noSideBar={true} isRenewalSuccessPage={isRenewalSuccessPage}>
            <div className="md:px-6 px-4 flex flex-col md:flex-row items-center justify-center h-full mx-auto w-auto md:pt-0 pt-10">
              {isBusinessAccount ? selectedCaseForBA() : selectedCaseNoBA()}
            </div>
          </NavigationBar>
        )}
      </div>
    </>
  );
}

export default RenewPayment;
