import React, { useEffect, useState } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import XSText from '../../ui/typography/XSText';
import TextInput from '../../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import CustomisedCheckbox from '../../ui/Checkbox';
import BackButton from '../../ui/buttons/BackButton';
import PrimaryButton from '../../ui/buttons/PrimaryButton';
import ValidationMessage from '../../ui/ValidationMessage';
import { COMPANY_SETUP_SIDEBAR_STEP, COMPANY_SETUP_STEP } from '../../../constants/enum';
import { connect } from 'react-redux';
import * as CompanyAction from '../../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../../constants/enum';
import * as onfidoReducerAction from '../../onfido/idVerification/reduxStore/action';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../../routes/paths';

const SignConfirm = ({
  prevStep,
  setActiveStep,
  companyAllInformation,
  updateCompanyIncorporation,
  identityCheckProcess
}) => {
  const [termAndConditions, setTermAndConditions] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [required, setRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClickCheckbox = () => {
    setTermAndConditions(!termAndConditions);
  };
  const back = () => {
    prevStep();
    setActiveStep(COMPANY_SETUP_SIDEBAR_STEP?.OWNERS_AND_DIRECTORS);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setRequired(true);
    if (data && termAndConditions) {
      let payload = {
        confirmAndSign: {
          name: data?.sign,
          sign: data?.sign
        },
        companyId: companyAllInformation?._id,
        isOnboardingCompleted: true,
        finishedApplication: true,
        completedStep: COMPANY_SETUP_STEP?.STEP_COMPLETE_SUCCESS
      };
      const response = await updateCompanyIncorporation(payload);
      if (response?.status === API_CODE?.STATUS_200) {
        onIdentityCheck();
      } else {
        setIsLoading(false);
      }
    }
  };

  const onIdentityCheck = async () => {
    let payload = {
      companyId: companyAllInformation?._id
    };
    const response = await identityCheckProcess(payload);
    if (response?.status === API_CODE?.STATUS_200) {
      navigate(`${PATH_PAGE.verificationConfirmation}?url=${encodeURIComponent(response?.data?.data?.link)}`);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:justify-between gap-6 h-full sign-form-width main-wrapper-container">
      <MDSubtitleText title="Confirm and sign" />
      <div className="flex flex-col">
        <div className="flex flex-row gap-4">
          <CustomisedCheckbox isChecked={termAndConditions} handleChange={onClickCheckbox} />
          <XSText
            title={
              <div
                dangerouslySetInnerHTML={{
                  __html: `<span class='text-bold'>I certify</span> that the information is complete and accurate and commit to notifying Statrys Corporate Services Limited promptly of any alterations.`
                }}
              ></div>
            }
          />
        </div>
        {!termAndConditions && required && (
          <div>
            <ValidationMessage title={'In order to sign up you need to agree to these terms.'} />
          </div>
        )}
        <XSText title="Type your name to confirm the information provided" className="mt-8" />
        <form id="sign-and-confirm" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            defaultValue={
              companyAllInformation?.confirmAndSign?.name ? companyAllInformation?.confirmAndSign?.name : ''
            }
            name="sign"
            placeholder="name"
            label="Your full name"
            margin="mt-2"
            inputRef={register({
              required: true
            })}
            error={errors?.sign ? true : false}
            helperText={errors?.sign && <ValidationMessage title="Please fill your name" />}
          />
        </form>
      </div>

      <div className="flex md:flex-row flex-col-reverse justify-between">
        <BackButton onClick={back} className="md:w-[114px] w-full" />
        <PrimaryButton
          fontSize="text-md"
          caption="Confirm and Verify your ID"
          color="text-white"
          className="w-full md:w-[227px]"
          isLoading={isLoading}
          isBorderRequired={true}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    identityCheckResponse: state?.identityVerification?.identityCheckResponse?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyIncorporation: (payload) => dispatch(CompanyAction.updateCompanyIncorporation(payload)),
    identityCheckProcess: (payload) => dispatch(onfidoReducerAction.identityCheckProcess(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignConfirm);
