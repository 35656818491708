import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Avatar from '../ui/Avatar';
import { companyDetails, stringTruncate } from '../../helper/helpers';
import {
  INVOICE_SIGNUP_URL,
  ONBOARDING_BASE_REDIRECTION_URL,
  PAYMENT_APP_APPROVED_APPLICATION_URL,
  REDIRECTION_SOURCE,
  REDIRECT_SCREEN_INFO,
  SESSION_STORAGE_NAME,
  SSO_SITE_AUTHENTICATION_URL,
  STRING_MAX_CHAR,
  SVG_TYPES,
  SWITCH_TO_STATRYS,
  USER_APP_ACCESS_STATUS,
  USER_APP_IDENTIFIER
} from '../../constants/enum';
import '../../styles/headerNavigation.scss';
import Tick from '../../images/icons/check-box-tick.svg';
import { connect, useSelector } from 'react-redux';
import * as LoginActions from '../../components/login/reduxStore/action';
import * as CompanyAction from '../../components/companyIncorporation/reduxStore/action';
import { API_CODE } from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';
import { getSvgIcon } from '../../helper/svgHelper';
import { EXTERNAL_LINKS } from '../../constants/enum';
import SkeletonRectangle from '../common/ReactangleSkeleton';
import { INVOICE_LOGIN_URL, COMPANY_INCORPORATION_STATUS } from '../../constants/enum';
import { setTokenInSessionStorage, setCompleteAuthInSessionStorage } from '../../helper/helpers';
import { ENDPOINTS } from '../../service/end-points';
import { clearStorage, logOut } from '../authService/auth';
import useIsMobile from '../../../utils/isMobile';

function NavigationDropDownLeft({
  allIncorporationCompanies,
  updateSSOToken,
  allBusinessAccounts,
  allInvoiceCompanies,
  loadingInvoiceAndBusinessAccounts,
  isLeftDropDownOpen,
  getInvoiceCompanies,
  getBusinessAccounts
}) {
  const [onHover, setOnHover] = useState();
  const [openBussinessAccountList, setOpenBussinessAccountList] = useState(false);
  const [openInvoicingList, setOpenInvoicingList] = useState(false);

  const switchCompany = async (company) => {
    if (!company) return;

    const { status, identifierCode } = company;
    const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);

    switch (status) {
      case COMPANY_INCORPORATION_STATUS.APPROVED:
        sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, identifierCode);
        sessionStorage.setItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED, true);
        try {
          const response = await updateSSOToken({ companyId: identifierCode }, token);

          if (response?.status === API_CODE.STATUS_200) {
            setTokenInSessionStorage(response.data?.data?.token);
            setCompleteAuthInSessionStorage(JSON.stringify(response?.data?.data));
            navigate(ENDPOINTS.COMPANY_PROFILE);
          }
        } catch (error) {
          console.error('Error updating SSO token:', error);
        }
        break;

      case COMPANY_INCORPORATION_STATUS.UNDER_REVIEW:
        sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, identifierCode);
        navigate(`${PATH_PAGE.companyIncorporationSuccess}?continue-form=true`);
        break;

      case COMPANY_INCORPORATION_STATUS.UNFINISHED:
        sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, identifierCode);
        navigate(`${PATH_PAGE.companyIncorporation}?continue-form=true`);
        break;

      case COMPANY_INCORPORATION_STATUS.EXPIRING_SOON:
        sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, identifierCode);
        sessionStorage.setItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED, true);
        try {
          const response = await updateSSOToken({ companyId: identifierCode }, token);
          if (response?.status === API_CODE.STATUS_200) {
            setTokenInSessionStorage(response.data?.data?.token);
            setCompleteAuthInSessionStorage(JSON.stringify(response?.data?.data));
            const selectedCompany = await companyDetails(response.data?.data?.token, identifierCode);
            handleCompanyExpiring(selectedCompany?.data?.data);
          }
        } catch (error) {
          console.error('Error updating SSO token:', error);
          logOut();
        }
        break;

      default:
        console.warn('Unhandled company status:', status);
    }
  };

  const handleNewCompanyCreation = () => {
    navigate(`${PATH_PAGE.signUp}/start`);
    sessionStorage.setItem(SESSION_STORAGE_NAME.COMPANY_ID, '');
  };

  const handleSwitchToStatrys = () => {
    window.open(`${SWITCH_TO_STATRYS}`, '_blank');
    return;
  };

  const handleCompanyExpiring = (companyDetails) => {
    navigate(PATH_PAGE?.renewPayment, {
      state: {
        companyDetails: companyDetails
      }
    });
  };

  function handleInvoiceCompany(company) {
    const identifierCode = company?.identifierCode;
    const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
    clearStorage();
    window.open(
      `${INVOICE_LOGIN_URL}?token=${token}&identifierCode=${identifierCode}&source=${REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP}`,
      '_self'
    );
    return;
  }

  function handleManageYourInvoice() {
    const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
    clearStorage();
    window.open(`${INVOICE_SIGNUP_URL}?token=${token}&source=${REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP}`, '_self');
    return;
  }

  function handleApplyForBusinessAccount() {
    const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
    const userCode = sessionStorage.getItem(SESSION_STORAGE_NAME.USER_CODE);

    if (!token || !userCode) {
      console.error('Missing token or userCode');
      return;
    }

    const params = new URLSearchParams({
      prospectCode: '',
      newApplication: true,
      applicationCompletion: 0,
      userLogin: token,
      customerID: userCode
    }).toString();

    const redirectUrl = `${ONBOARDING_BASE_REDIRECTION_URL}?${params}`;
    clearStorage();
    window.open(redirectUrl, '_self');
    return;
  }

  const renderCompanyItem = (company, status, hasDivider) => {
    return (
      <>
        <div
          className="flex flex-row gap-4 items-center justify-between cursor-pointer px-2 py-2 hover:bg-coral-300 rounded-lg"
          onClick={() => switchCompany(company)}
        >
          <div className="flex flex-row gap-4">
            <div className="rounded-full">
              <Avatar
                avatarStyling="w-10 h-10"
                avatarImage={company?.logo}
                avatarName={company?.companyName?.charAt(0)}
              />
            </div>
            <div className="flex flex-col justify-center w-full">
              <p className="text-gray-300 text-sm text-regular leading-[22px] uppercase">
                {stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
              </p>

              {status && <div className={`status-pill bg-${status.color}`}>{status.title}</div>}
            </div>
          </div>
          {company?.identifierCode == sessionStorage.getItem(SESSION_STORAGE_NAME.COMPANY_ID) &&
            (sessionStorage.getItem(SESSION_STORAGE_NAME?.IS_ANY_COMPANY_CLICKED) ||
              allIncorporationCompanies.length === 1) && <img src={Tick} width="16" height="16" />}
        </div>
        {hasDivider && <div className="divider" style={{ marginTop: '0px' }} />}
      </>
    );
  };

  const applicationAccessList = useSelector((state) => state?.companyIncorporationReducer?.applicationAccessList);
  const apisLoading = useSelector((state) => state?.companyIncorporationReducer?.loading);

  const hasAccess = useCallback(
    (appCode) =>
      applicationAccessList?.some(
        (app) => app.applicationCode === appCode && app.status === USER_APP_ACCESS_STATUS.APPROVED
      ),
    [applicationAccessList]
  );

  useEffect(() => {
    const fetchCompanies = async () => {
      if (openBussinessAccountList && hasAccess(USER_APP_IDENTIFIER.PAYMENT_APP) && !allBusinessAccounts.length) {
        await getBusinessAccounts();
      }
      if (openInvoicingList && hasAccess(USER_APP_IDENTIFIER.INVOICING_APP) && !allInvoiceCompanies.length) {
        await getInvoiceCompanies();
      }
    };

    fetchCompanies();
  }, [openBussinessAccountList, openInvoicingList, hasAccess]);

  const dropdownRef = useRef(null);
  const [overlayHeight, setOverlayHeight] = useState('100vh');

  useEffect(() => {
    if (isLeftDropDownOpen && dropdownRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();

      // Subtract everything above the dropdown
      const heightUntilDropdown = dropdownRect.top + dropdownRect.height - 20;

      setOverlayHeight(`calc(100vh - ${heightUntilDropdown}px)`);
    }
  }, [isLeftDropDownOpen]);
  const isMobile = useIsMobile();
  return (
    <>
      <div
        className={`absolute dropdown-content-container-left top-[55px] sm:top-[70px] md:top-[45px] left-0  sm:left-[25px]`}
        onClick={(e) => {
          if (isLeftDropDownOpen) {
            e.stopPropagation();
          }
        }}
      >
        <div className="dropdown-content m-0" style={isLeftDropDownOpen ? { display: 'block' } : {}} ref={dropdownRef}>
          <div className="tooltip-container">
            <div className="tooltip-icon " style={{ position: 'absolute', left: '0' }}></div>
          </div>
          <div
            className="dropdown-content-container relative z-10 overflow-y-auto max-h-[80vh] pb-2 px-2  w-screen sm:w-[339px] max-w-screen-sm sm:max-w-[95vw]  flex flex-col"
            onMouseLeave={() => setOnHover()}
            style={{
              boxShadow: !isMobile ? '0px 16px 24px 0px rgba(0, 0, 0, 0.12)' : '',
              filter: !isMobile ? 'drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.12))' : ''
            }}
          >
            {/* Company Incorporation List */}
            {allIncorporationCompanies && (
              <div className="pt-2">
                {(() => {
                  let totalCompanies = allIncorporationCompanies.length;
                  let currentIndex = 0;

                  return [
                    {
                      status: COMPANY_INCORPORATION_STATUS.APPROVED,
                      color: '',
                      title: ''
                    },
                    {
                      status: COMPANY_INCORPORATION_STATUS.UNDER_REVIEW,
                      color: 'green-200',
                      title: 'Under Review'
                    },
                    {
                      status: COMPANY_INCORPORATION_STATUS.UNFINISHED,
                      color: 'salmon-500',
                      title: 'Unfinished Application'
                    },
                    {
                      status: COMPANY_INCORPORATION_STATUS.EXPIRING_SOON,
                      color: 'yellow-500',
                      title: 'License Expiring Soon'
                    }
                  ].map(({ status, color, title }) => {
                    return allIncorporationCompanies
                      .filter((company) => company.status === status)
                      .map((company, index, filteredCompanies) => {
                        currentIndex++;
                        return renderCompanyItem(
                          company,
                          title ? { color, title } : undefined,
                          currentIndex !== totalCompanies
                        );
                      });
                  });
                })()}
              </div>
            )}

            {/* Register another company */}
            <div
              className={`flex flex-row gap-4 items-center rounded-lg px-5 py-4  cursor-pointer hover:bg-coral-300 text-sm text-gray-300 text-regular`}
              onClick={() => handleNewCompanyCreation()}
            >
              <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
              <p className="text-gray-300 text-sm text-regular leading-[22px]">Register a company</p>
            </div>

            {/* Switch to  statrys */}
            <div
              className={`flex flex-row gap-4 items-center rounded-lg px-5 py-4 cursor-pointer hover:bg-coral-300`}
              onClick={() => handleSwitchToStatrys()}
            >
              <div>{getSvgIcon(SVG_TYPES.SWITCH_TO_STATRYS)}</div>
              <p className="text-gray-300 text-sm text-regular leading-[22px]">Switch to Statrys</p>
            </div>

            <div className="divider" style={{ marginTop: '0px' }}></div>

            {/* Business Account */}
            <div
              className={`flex flex-row gap-2 items-center rounded-lg px-2 py-2 hover:bg-coral-300 cursor-pointer `}
              onClick={() => {
                setOpenBussinessAccountList((prev) => !prev);
                setOpenInvoicingList(false);
              }}
            >
              <div>{getSvgIcon(SVG_TYPES.BUSINESS_ACCOUNT)}</div>
              <div className="flex justify-between items-center flex-grow">
                <p className="text-gray-300 ml-2 text-sm">Business Account</p>
                {openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                {!openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
              </div>
            </div>
            {openBussinessAccountList && (
              <>
                <BusinessAccountList
                  loadingInvoiceAndBusinessAccounts={apisLoading}
                  allBusinessAccounts={allBusinessAccounts}
                />
                <div
                  className={`flex flex-row items-center rounded-lg px-5 py-4 cursor-pointer group hover:bg-coral-300`}
                  onClick={allBusinessAccounts?.length ? handleApplyForBusinessAccount : undefined}
                >
                  {allBusinessAccounts?.length ? (
                    <>
                      <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                      <p className="text-gray-300 ml-4 text-sm">Apply for a Business Account</p>
                    </>
                  ) : (
                    <>
                      <div>{getSvgIcon(SVG_TYPES.GRAY_ADD_CIRCLE)}</div>
                      <div className="relative">
                        <span className="ml-4  text-sm text-gray-450">Apply for a Business Account</span>
                        <div className="absolute mt-1 p-2 min-w-min-content w-[250px] hidden group-hover:block w-max bg-purple-500 text-white rounded-lg px-2 py-1 text-sm top-[-30px] left-[20px]">
                          This feature will be available soon
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {/* Invoicing */}
            <div
              className={`flex flex-row gap-2 items-center rounded-lg px-2 py-2 my-1 hover:bg-coral-300 cursor-pointer`}
              onClick={() => {
                setOpenBussinessAccountList(false);
                setOpenInvoicingList((prev) => !prev);
              }}
            >
              <div>{getSvgIcon(SVG_TYPES.INVOICING)}</div>
              <div className="flex justify-between items-center flex-grow">
                <p className="text-gray-300 ml-2 text-sm">Invoicing</p>
                {openInvoicingList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                {!openInvoicingList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
              </div>
            </div>
            {openInvoicingList && (
              <>
                <InvoiceList
                  loadingInvoiceAndBusinessAccounts={apisLoading}
                  allInvoiceCompanies={allInvoiceCompanies}
                  handleInvoiceCompany={handleInvoiceCompany}
                />
                {!allInvoiceCompanies.length && (
                  <div
                    className={`flex flex-row gap-4 items-center rounded-lg px-5 py-4  mt-2 cursor-pointer hover:bg-coral-300`}
                    onClick={() => handleManageYourInvoice()}
                  >
                    <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                    <p className="text-gray-300 text-sm">Manage your invoices</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="sm:hidden"
          style={
            isLeftDropDownOpen
              ? {
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100vw',
                  height: overlayHeight,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1
                }
              : {}
          }
        ></div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSSOToken: (updateTokenPayload, token) => dispatch(LoginActions.updateSSOToken(updateTokenPayload, token)),
    getInvoiceCompanies: () => dispatch(CompanyAction.getInvoice()),
    getBusinessAccounts: (token) => dispatch(CompanyAction.getBusinessAccounts(token)),
    getIncorporationCompanies: () => dispatch(CompanyAction.getIncorporationCompanies()),
    getApplicationAccessList: () => dispatch(CompanyAction.getApplicationAccessList())
  };
};
export default connect(null, mapDispatchToProps)(NavigationDropDownLeft);

NavigationDropDownLeft.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPositions: PropTypes.array,
  children: PropTypes.any
};

function ApplicationListItem({ itemData, onClick, index }) {
  return (
    <div className={`cursor-pointer hover:bg-coral-300 p-2 rounded-lg`} onClick={() => onClick(itemData)}>
      <div className="text-sm text-gray-300 text-regular leading-[22px] flex items-center gap-4">
        <Avatar avatarStyling="w-10 h-10" avatarImage={itemData?.logo} avatarName={itemData?.companyName?.charAt(0)} />
        <div className="flex flex-col justify-center  name-status-section">
          <div className="application-name uppercase text-sm text-gray-300 leading-[22px">{itemData?.companyName}</div>
          {itemData?.statusDisplayValue && (
            <div className="status-pill" style={{ backgroundColor: itemData?.statusBackgroundColor }}>
              {itemData?.statusDisplayValue}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ApplicationListItem.propTypes = {
  itemData: PropTypes.object,
  onClick: PropTypes.func
};

function InvoiceList({ loadingInvoiceAndBusinessAccounts, allInvoiceCompanies, handleInvoiceCompany }) {
  if (loadingInvoiceAndBusinessAccounts) {
    return (
      <div className="px-2 flex flex-col gap-3 mt-2">
        <SkeletonRectangle />
        <SkeletonRectangle />
        <SkeletonRectangle />
      </div>
    );
  }

  if (allInvoiceCompanies?.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col mt-2">
      {allInvoiceCompanies.map((company) => (
        <>
          <div
            key={company._id}
            className={`flex flex-row gap-4 items-center cursor-pointer px-2 py-2 hover:bg-coral-300 rounded-lg`}
            onClick={() => handleInvoiceCompany(company)}
          >
            <div className="rounded-full">
              <Avatar
                avatarStyling="w-10 h-10"
                avatarImage={company?.logo}
                avatarName={company?.companyName?.charAt(0)}
              />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex flex-row items-start justify-between w-full gap-2">
                <p className="text-gray-300 text-sm text-regular leading-[22px] uppercase">
                  {stringTruncate(company?.companyName, STRING_MAX_CHAR.TWENTY_NINE)}
                </p>
              </div>
            </div>
          </div>
          {allInvoiceCompanies?.length > 1 && <div className="divider" style={{ marginTop: '0px' }}></div>}
        </>
      ))}
    </div>
  );
}

function BusinessAccountList({ loadingInvoiceAndBusinessAccounts, allBusinessAccounts }) {
  function handleBusinessAccount(itemData) {
    if (!itemData?.status) return;

    const token = sessionStorage.getItem(SESSION_STORAGE_NAME.TOKEN);
    const userCode = sessionStorage.getItem(SESSION_STORAGE_NAME.USER_CODE);

    const redirectMap = {
      [REDIRECT_SCREEN_INFO.UNDER_REVIEW]: {
        url: SSO_SITE_AUTHENTICATION_URL,
        params: {
          token,
          identifierCode: itemData.identifierCode,
          type: itemData.type,
          status: itemData.status,
          source: REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP,
          redirectTo: REDIRECT_SCREEN_INFO.UNDER_REVIEW
        }
      },
      [REDIRECT_SCREEN_INFO.UNFINISHED]: {
        url: ONBOARDING_BASE_REDIRECTION_URL,
        params: {
          isFinishedBtnClicked: true,
          applicationCompletion: itemData.completionPercentage,
          prospectCode: itemData.identifierCode,
          customerID: userCode,
          userLogin: token,
          newApplication: ''
        }
      },
      [REDIRECT_SCREEN_INFO.APPROVED]: {
        url: PAYMENT_APP_APPROVED_APPLICATION_URL,
        params: {
          identifierCode: itemData.identifierCode,
          type: itemData.type,
          token,
          status: itemData.status,
          source: REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP
        }
      }
    };

    const redirectInfo = redirectMap[itemData.status];
    if (redirectInfo) {
      const queryParams = new URLSearchParams(redirectInfo.params).toString();
      clearStorage();
      window.open(`${redirectInfo.url}?${queryParams}`, '_self');
      return;
    }
  }

  if (loadingInvoiceAndBusinessAccounts) {
    return (
      <div className="px-2 flex flex-col gap-3 mt-2">
        <SkeletonRectangle />
        <SkeletonRectangle />
        <SkeletonRectangle />
      </div>
    );
  }

  if (allBusinessAccounts?.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col mt-2">
      {allBusinessAccounts?.map((account, index) => (
        <>
          <ApplicationListItem key={account._id} itemData={account} onClick={handleBusinessAccount} index={index} />
          <div className="divider" style={{ marginTop: '0px' }}></div>
        </>
      ))}
    </div>
  );
}
