import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StepsIndicator from '../ui/StepsIndicator';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import XSText from '../ui/typography/XSText';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import BackButton from '../ui/buttons/BackButton';
import CheckCircleIcon from '../../images/icons/green-check.svg';
import RoadSign from '../../images/icons/road-sign.svg';
import { connect } from 'react-redux';
import * as actionsForRegistrationNewCompany from './redux/action';
import ValidationMessage from '../ui/ValidationMessage';
import { getUser, isLoggedIn } from '../authService/auth';
import { COMPANY_CREATE_STEPS, API_CODE, IS_SSO_ON } from '../../constants/enum';

function Accounting({
  prevStep,
  nextStep,
  newCompanyData,
  newCompanyAllInfo,
  setPayload,
  companyUpdate,
  companyCreate,
  companyId,
  existingCompanyCreate
}) {
  const [selectedOption, setSelectedOption] = useState(newCompanyAllInfo?.accounting);
  const [selectedOptionError, setSelectedOptionError] = useState(false);
  const checkoutApiCalls = async () => {
    const user = getUser();

    if (!isLoggedIn()) return companyId;

    const newCompanyPayload = {
      ...newCompanyAllInfo,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user.email
    };

    await newCompanyData(newCompanyPayload);

    const companyPayload = {
      companyName: newCompanyAllInfo?.companyName,
      numberOfOwners: newCompanyAllInfo?.selectedNumberOfOwners,
      industries: [newCompanyAllInfo?.industry],
      accountingAssistance: newCompanyAllInfo?.accounting,
      registrationCountry: newCompanyAllInfo?.registrationCountry,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user.email,
      stakeholder: [
        {
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phoneNumber: user?.phoneNumber
        }
      ]
    };

    let response;

    if (companyId) {
      companyPayload.companyId = companyId;
      response = await companyUpdate(companyPayload);
    } else {
      response = await companyCreate(companyPayload);
    }

    if (response?.status === API_CODE?.STATUS_200 && !IS_SSO_ON) {
      const existingCompanyPayload = {
        companyId: response?.data?.data?._id,
        email: user.email
      };
      await existingCompanyCreate(existingCompanyPayload);
    }

    return companyId;
  };
  const handleContinue = async () => {
    if (selectedOption) {
      let newCompanyPayload = {
        ...newCompanyAllInfo,
        accounting: selectedOption
      };
      await newCompanyData(newCompanyPayload);
      if (isLoggedIn()) {
        await checkoutApiCalls();
        setPayload((prev) => ({
          ...prev,
          step: 5
        }));
      } else {
        nextStep();
      }
    } else {
      setSelectedOptionError(true);
    }
  };

  useEffect(() => {
    if (selectedOption) {
      setSelectedOptionError(false);
    }
  }, [selectedOption]);
  return (
    <div className="bg-white md:p-8 p-4 rounded-lg flex flex-col items-stretch justify-between company-registration-form-container">
      <StepsIndicator numberOfSteps={5} currentStepNumber={2} />
      <div className="flex flex-col justify-center step-field-container">
        <MDSubtitleText
          className="md:whitespace-pre-line mb-8"
          fontWeight="text-bold"
          title={`Would you like assistance with \nyour company’s accounting?`}
        />
        <div className="flex gap-4">
          <div
            className={`px-6 py-4 border ${
              selectedOption === 'Yes' ? 'border-green-500 bg-green-100' : 'border-gray-400'
            } rounded-lg w-full cursor-pointer`}
            onClick={() => setSelectedOption('Yes')}
          >
            <div className="flex items-center gap-2 justify-center">
              <img src={CheckCircleIcon} className="w-6" alt="check-mark" />
              <XSText title="Yes" />
            </div>
          </div>
          <div
            className={`px-6 py-4 border ${
              selectedOption === 'No' ? 'border-red-alert bg-red-100' : 'border-gray-400'
            } rounded-lg w-full cursor-pointer`}
            onClick={() => setSelectedOption('No')}
          >
            <div className="flex items-center justify-center gap-2">
              <img src={RoadSign} className="w-6" alt="check-mark" />
              <XSText title="No" />
            </div>
          </div>
        </div>
        {selectedOptionError && <ValidationMessage title="Please select a valid option" />}
      </div>

      <div className="flex md:flex-row flex-col-reverse justify-between items-center w-full">
        <BackButton onClick={prevStep} className="w-full" />
        <PrimaryButton
          className="capitalize md:w-32 w-full onboarding-button"
          fontSize="text-base"
          caption="Continue"
          onClick={handleContinue}
          linkClass="md:w-auto w-full"
        />
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    newCompanyAllInfo: state?.newAccountSetup?.newCompanyRegistrationData,
    companyId: state?.newAccountSetup?.companyCreateDetails?.data?._id
      ? state?.newAccountSetup?.companyCreateDetails?.data?._id
      : state?.newAccountSetup?.newCompanyRegistrationData?.companyId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newCompanyData: (newCompanyRegistrationData) =>
      dispatch(actionsForRegistrationNewCompany.newCompanyRegistrationData(newCompanyRegistrationData)),
    companyCreate: (companyCreateData) => dispatch(actionsForRegistrationNewCompany.companyCreate(companyCreateData)),
    companyUpdate: (companyUpdateData) => dispatch(actionsForRegistrationNewCompany.companyUpdate(companyUpdateData)),
    existingCompanyCreate: (companyCreateData) =>
      dispatch(actionsForRegistrationNewCompany.existingCompanyCreate(companyCreateData))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Accounting);

Accounting.propTypes = {
  nextStep: PropTypes.number,
  prevStep: PropTypes.func,
  setCompletedSteps: PropTypes.func,
  completedSteps: PropTypes.bool,
  newCompanyData: PropTypes.func,
  newCompanyAllInfo: PropTypes.object
};
