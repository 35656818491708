import axios from 'axios';
import { BASE_API_URL, BASE_API_URL_FOR_ONFIDO, SESSION_STORAGE_NAME } from '../constants/enum';
import { ENDPOINTS } from './end-points';

export const getUuid = async () => {
  const auth = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_NAME.AUTH)) || null;
  return auth && auth.uuid;
};

export const getSDKToken = async (requestBody) => {
  const { token, workFlowCode } = requestBody;
  return axios.get(BASE_API_URL_FOR_ONFIDO + ENDPOINTS.GENERATE_ONFIDO_TOKEN, {
    params: {
      token,
      workFlowCode
    }
  });
};
