import React, { useEffect } from 'react';
import Layout from '../components/layout';
import Page from '../components/Page';
import CompanyList from '../components/companyList/CompanyList';
import { IS_SSO_ON, SESSION_STORAGE_NAME } from '../constants/enum';
import { navigate } from 'gatsby';
import { isBrowser } from '../helper/helpers';

const CompanyListing = () => {
  const stateData = isBrowser() && window?.history?.state;

  useEffect(() => {
    if (IS_SSO_ON) {
      navigate('/');
    }
  }, []);

  return (
    <Layout noSideBar={true}>
      <Page title="Company List" className="h-full mt-4">
        <div className="flex items-center justify-center h-full">
          <CompanyList
            data={
              stateData?.userDetails ||
              JSON.parse(isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME?.ALL_COMPANIES))
            }
          />
        </div>
      </Page>
    </Layout>
  );
};

export default CompanyListing;
